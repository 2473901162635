import { Code } from '@atomica.co/types';
import React from 'react';
import { IconCodeEnum } from '../enums/icon-enum';

import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';

export const toIcon = (iconCode: Code): React.ReactElement => {
  switch (iconCode) {
    case IconCodeEnum.FACE:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M10.25 13C10.25 13.69 9.69 14.25 9 14.25C8.31 14.25 7.75 13.69 7.75 13C7.75 12.31 8.31 11.75 9 11.75C9.69 11.75 10.25 12.31 10.25 13ZM15 11.75C14.31 11.75 13.75 12.31 13.75 13C13.75 13.69 14.31 14.25 15 14.25C15.69 14.25 16.25 13.69 16.25 13C16.25 12.31 15.69 11.75 15 11.75ZM22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM10.66 4.12C12.06 6.44 14.6 8 17.5 8C17.96 8 18.41 7.95 18.84 7.88C17.44 5.56 14.9 4 12 4C11.54 4 11.09 4.05 10.66 4.12ZM4.42 9.47C6.13 8.5 7.45 6.92 8.08 5.03C6.37 6 5.05 7.58 4.42 9.47ZM20 12C20 11.22 19.88 10.47 19.67 9.76C18.97 9.91 18.25 10 17.5 10C14.37 10 11.58 8.56 9.74 6.31C8.69 8.87 6.6 10.88 4 11.86C4.01 11.9 4 11.95 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12Z' />
        </svg>
      );
    case IconCodeEnum.GROUP:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM9 13.75C6.66 13.75 2 14.92 2 17.25V18C2 18.55 2.45 19 3 19H15C15.55 19 16 18.55 16 18V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H21C21.55 19 22 18.55 22 18V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z' />
        </svg>
      );
    case IconCodeEnum.QUESTIONNAIRE:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M22.0002 7.99973C22.0002 7.44973 21.5502 6.99973 21.0002 6.99973H14.0002C13.4502 6.99973 13.0002 7.44973 13.0002 7.99973C13.0002 8.54973 13.4502 8.99973 14.0002 8.99973H21.0002C21.5502 8.99973 22.0002 8.54973 22.0002 7.99973ZM13.0002 15.9997C13.0002 16.5497 13.4502 16.9997 14.0002 16.9997H21.0002C21.5502 16.9997 22.0002 16.5497 22.0002 15.9997C22.0002 15.4497 21.5502 14.9997 21.0002 14.9997H14.0002C13.4502 14.9997 13.0002 15.4497 13.0002 15.9997ZM10.4702 4.62973C10.8602 5.01973 10.8602 5.64973 10.4702 6.03973L6.24022 10.2897C5.85022 10.6797 5.22022 10.6797 4.82022 10.2897L2.70022 8.15973C2.51325 7.97275 2.4082 7.71915 2.4082 7.45473C2.4082 7.3238 2.43399 7.19415 2.4841 7.07318C2.5342 6.95222 2.60764 6.84231 2.70022 6.74973C2.79281 6.65714 2.90272 6.5837 3.02368 6.5336C3.14464 6.48349 3.27429 6.45771 3.40522 6.45771C3.66965 6.45771 3.92325 6.56275 4.11022 6.74973L5.53022 8.16973L9.07022 4.62973C9.45022 4.24973 10.0902 4.24973 10.4702 4.62973ZM10.4802 12.6397C10.8702 13.0297 10.8702 13.6597 10.4802 14.0497L6.25022 18.2997C5.86022 18.6897 5.23022 18.6897 4.83022 18.2997L2.70022 16.1597C2.60764 16.0671 2.5342 15.9572 2.4841 15.8363C2.43399 15.7153 2.4082 15.5857 2.4082 15.4547C2.4082 15.3238 2.43399 15.1941 2.4841 15.0732C2.5342 14.9522 2.60764 14.8423 2.70022 14.7497C2.79281 14.6571 2.90272 14.5837 3.02368 14.5336C3.14464 14.4835 3.27429 14.4577 3.40522 14.4577C3.53615 14.4577 3.6658 14.4835 3.78677 14.5336C3.90773 14.5837 4.01764 14.6571 4.11022 14.7497L5.53022 16.1697L9.07022 12.6297C9.45022 12.2497 10.0902 12.2497 10.4802 12.6397Z' />
        </svg>
      );
    case IconCodeEnum.CONTRACT:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.3833 9.52329L15.8208 8.96134L16.8238 7.95734C17.6694 7.10998 18.5144 5.54747 19.3604 3.2698C19.5982 2.62984 19.1145 1.96027 18.4331 2.00184C5.54617 2.78798 3.07067 14.549 2.01415 20.7988C1.91127 21.4074 2.38262 21.9603 2.99981 21.9603C3.47599 21.9603 3.88108 21.6219 4.0051 21.1622C4.76812 18.3337 6.37309 16.7666 8.82078 16.4603C12.5633 15.9925 15.4305 13.3361 16.6031 10.5389C16.7496 10.1894 16.6514 9.79112 16.3833 9.52329ZM5.15478 15.7793C5.33078 15.1963 5.52778 14.6203 5.76078 13.9963C7.81678 8.47034 11.2408 5.04534 16.8338 4.17834C16.3208 5.32234 15.8358 6.11634 15.4058 6.54634L13.6991 8.25499C13.309 8.64569 13.3093 9.27865 13.6999 9.66894L14.4488 10.4173C13.3188 12.5023 11.0858 14.1613 8.57278 14.4753C7.25578 14.6403 6.11478 15.0823 5.15478 15.7793Z'
          />
          <path d='M21.7807 17.3747C21.4356 16.9437 20.8065 16.874 20.3754 17.219L20.3666 17.2259L20.3304 17.2542C20.2973 17.2798 20.2467 17.3186 20.1805 17.368C20.0479 17.4669 19.8533 17.608 19.6115 17.772C19.1251 18.102 18.4614 18.5153 17.7354 18.8649C17.0003 19.2189 16.2548 19.4813 15.5946 19.5554C14.9427 19.6285 14.4747 19.5103 14.1404 19.2316C13.2906 18.5233 12.26 18.2934 11.2928 18.2905C10.3297 18.2876 9.35784 18.508 8.51957 18.7789C7.67455 19.0519 6.92026 19.3912 6.38057 19.6596C6.10937 19.7945 5.88905 19.9131 5.73474 19.9991C5.65751 20.0421 5.59661 20.0771 5.55392 20.102L5.5037 20.1315L5.48923 20.1401L5.48473 20.1429L5.48208 20.1445C5.00969 20.4306 4.85869 21.0455 5.14481 21.5179C5.43085 21.9901 6.04604 22.1408 6.51838 21.855L6.52565 21.8507L6.56074 21.8301C6.59306 21.8112 6.64281 21.7826 6.70816 21.7462C6.839 21.6733 7.03154 21.5696 7.27115 21.4504C7.75309 21.2107 8.41225 20.9154 9.13454 20.682C9.86356 20.4464 10.6129 20.2885 11.2868 20.2905C11.9566 20.2925 12.4789 20.4504 12.8599 20.768C13.7511 21.5107 14.8333 21.6533 15.8175 21.5429C16.7934 21.4335 17.7697 21.0682 18.6031 20.6669C19.4458 20.2611 20.1975 19.7913 20.7344 19.4271C21.0042 19.244 21.2233 19.0852 21.3763 18.9711C21.4529 18.9139 21.5132 18.8678 21.5553 18.8352C21.5763 18.8189 21.5928 18.806 21.6045 18.7968L21.6185 18.7857L21.6228 18.7823L21.6252 18.7804C22.0563 18.4352 22.1259 17.8059 21.7807 17.3747Z' />
        </svg>
      );
    case IconCodeEnum.INVOICE:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.8364 9.98546H10.7392C10.3576 9.98546 9.99733 10.0548 9.66046 10.1948L9.65849 10.1957C9.33595 10.3339 9.05059 10.519 8.804 10.7514C8.55633 10.9849 8.35864 11.2601 8.21102 11.5755L8.20978 11.5782C8.06918 11.8905 8 12.2293 8 12.5917C8 12.9463 8.06948 13.2841 8.20888 13.6032L8.21102 13.6079C8.35864 13.9233 8.55633 14.1985 8.804 14.432C9.05059 14.6644 9.33595 14.8496 9.65849 14.9877L9.66046 14.9886C9.99733 15.1287 10.3576 15.198 10.7392 15.198H10.8137V16.8808H9.32487C8.83574 16.8808 8.43922 17.2773 8.43922 17.7664C8.43922 18.2556 8.83574 18.6521 9.32487 18.6521H10.8367C10.9272 19.0377 11.2733 19.3248 11.6864 19.3248H11.7006C12.121 19.3248 12.4729 19.032 12.5636 18.6392H12.6745C13.0459 18.6392 13.3961 18.5746 13.7233 18.4437L13.7278 18.4419C14.0503 18.3037 14.3357 18.1185 14.5823 17.8861C14.838 17.6451 15.0365 17.3698 15.1759 17.0607C15.3253 16.741 15.4 16.402 15.4 16.0458C15.4 15.6822 15.3258 15.3392 15.1764 15.019C15.0377 14.7025 14.8441 14.4265 14.596 14.1926C14.3487 13.9595 14.0582 13.7744 13.7268 13.6364C13.3988 13.4961 13.0473 13.4267 12.6745 13.4267H12.5863V11.7568H13.3889C13.878 11.7568 14.2745 11.3602 14.2745 10.8711C14.2745 10.382 13.878 9.98546 13.3889 9.98546H12.5636C12.4728 9.59251 12.1202 9.2998 11.7 9.2998C11.2798 9.2998 10.9272 9.59251 10.8364 9.98546ZM13.4649 15.71C13.4213 15.6073 13.3614 15.5253 13.2856 15.4603L13.2783 15.4541L13.2717 15.4473C13.203 15.3761 13.1147 15.3181 13.002 15.2757L12.9945 15.2728L12.9873 15.2694C12.8884 15.2228 12.7761 15.198 12.6471 15.198H12.5863V16.8808H12.6821C12.9285 16.8652 13.1285 16.7791 13.2922 16.6248C13.4633 16.4635 13.5451 16.2744 13.5451 16.0458C13.5451 15.9295 13.52 15.8219 13.4698 15.7206L13.4649 15.71ZM10.0987 13.1752C9.93291 13.004 9.8549 12.8123 9.8549 12.5917C9.8549 12.3527 9.93803 12.1598 10.1078 11.9998C10.2781 11.8393 10.4886 11.7568 10.7529 11.7568H10.8137V13.4267H10.7147C10.4616 13.4189 10.2606 13.334 10.0987 13.1752Z'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.8198 1.51067L20.3349 6.45385C20.7581 6.83323 21 7.37478 21 7.94318V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3C3 1.89543 3.89543 1 5 1H13.4849C13.9775 1 14.4529 1.18185 14.8198 1.51067ZM6 3C5.44772 3 5 3.44772 5 4V20C5 20.5523 5.44772 21 6 21L18 21C18.5523 21 19 20.5523 19 20V9H15C13.8954 9 13 8.10457 13 7V3L6 3ZM15 4.358L17.9477 7H16C15.4477 7 15 6.55228 15 6V4.358Z'
          />
        </svg>
      );
    case IconCodeEnum.WISH:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M11.9998 17.2698L16.1498 19.7798C16.9098 20.2398 17.8398 19.5598 17.6398 18.6998L16.5398 13.9798L20.2098 10.7998C20.8798 10.2198 20.5198 9.11977 19.6398 9.04977L14.8098 8.63977L12.9198 4.17977C12.5798 3.36977 11.4198 3.36977 11.0798 4.17977L9.18983 8.62977L4.35983 9.03977C3.47983 9.10977 3.11983 10.2098 3.78983 10.7898L7.45983 13.9698L6.35983 18.6898C6.15983 19.5498 7.08983 20.2298 7.84983 19.7698L11.9998 17.2698V17.2698Z' />
        </svg>
      );
    case IconCodeEnum.EVENT:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M19 3H18V2C18 1.45 17.55 1 17 1C16.45 1 16 1.45 16 2V3H8V2C8 1.45 7.55 1 7 1C6.45 1 6 1.45 6 2V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 19H6C5.45 19 5 18.55 5 18V7H19V18C19 18.55 18.55 19 18 19Z' />
          <path d='M15.7843 12.6283C15.874 12.5583 15.9405 12.4606 15.9746 12.349C16.0086 12.2373 16.0085 12.1173 15.9741 12.0058C15.9398 11.8943 15.873 11.7968 15.7831 11.727C15.6933 11.6572 15.5848 11.6187 15.473 11.6168L13.3009 11.5308C13.2902 11.53 13.2799 11.526 13.2714 11.5193C13.2628 11.5126 13.2563 11.5034 13.2526 11.4929L12.502 9.36862C12.4643 9.26055 12.3958 9.16723 12.3058 9.10129C12.2158 9.03536 12.1086 9 11.9988 9C11.889 9 11.7818 9.03536 11.6918 9.10129C11.6018 9.16723 11.5333 9.26055 11.4956 9.36862L10.7474 11.5005C10.7437 11.511 10.7372 11.5202 10.7286 11.5269C10.7201 11.5336 10.7098 11.5376 10.6991 11.5384L8.52704 11.6244C8.4152 11.6263 8.30674 11.6648 8.21686 11.7346C8.12698 11.8043 8.06019 11.9018 8.02586 12.0134C7.99153 12.1249 7.99138 12.2449 8.02542 12.3566C8.05947 12.4682 8.126 12.5659 8.2157 12.6359L9.9196 14.0394C9.92813 14.0465 9.9345 14.056 9.93793 14.0667C9.94137 14.0775 9.9417 14.0891 9.93891 14.1001L9.35244 16.2775C9.32205 16.3883 9.32499 16.5063 9.36087 16.6154C9.39675 16.7245 9.46382 16.8193 9.55296 16.887C9.6421 16.9547 9.74898 16.992 9.85906 16.9938C9.96914 16.9956 10.0771 16.9618 10.1682 16.897L11.9686 15.6326C11.9775 15.6262 11.988 15.6228 11.9988 15.6228C12.0096 15.6228 12.0201 15.6262 12.029 15.6326L13.8294 16.897C13.9193 16.964 14.0268 17 14.1371 17C14.2474 17 14.355 16.964 14.4448 16.897C14.534 16.83 14.6011 16.7356 14.637 16.6269C14.6729 16.5182 14.6757 16.4006 14.6451 16.2901L14.0539 14.1052C14.0507 14.0942 14.0509 14.0825 14.0543 14.0716C14.0578 14.0608 14.0644 14.0513 14.0732 14.0445L15.7843 12.6283Z' />
        </svg>
      );
    case IconCodeEnum.EXPORT:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z' />
        </svg>
      );

    case IconCodeEnum.MASTER:
      return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16ZM2 6H16V2H2V6ZM7.325 11H10.675V8H7.325V11ZM7.325 16H10.675V13H7.325V16ZM2 11H5.325V8H2V11ZM12.675 11H16V8H12.675V11ZM2 16H5.325V13H2V16ZM12.675 16H16V13H12.675V16Z'
            fill='#666563'
          />
        </svg>
      );
    case IconCodeEnum.EVENT_LIST:
      return <EventAvailableOutlinedIcon />;
    case IconCodeEnum.SHOT:
      return <CropFreeOutlinedIcon />;
    case IconCodeEnum.HOME:
      return <HomeOutlinedIcon />;
    case IconCodeEnum.SPACE_RESERVE:
      return <MeetingRoomOutlinedIcon />;
    case IconCodeEnum.MY_PAGE:
      return <AccountCircleIcon />;
    case IconCodeEnum.MEMBERS:
      return <GroupRoundedIcon />;
    case IconCodeEnum.DROP_IN:
      return <AccessTimeOutlinedIcon />;

    default:
      throw new Error(`${iconCode} is out of target.`);
  }
};
