import {
  CardData,
  CardDeckRef,
  DirectionEnum,
  PositionEnum,
  ReactUseGestureEventHandlers,
  useSafeCallback,
  useSafeState,
  useUnmountRef,
  YSwipableDeck
} from '@atomica.co/components';
import { BaseId } from '@atomica.co/irori';
import { Height, Id, UserId } from '@atomica.co/types';
import { builder, hasLength } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FetchResourceUsages200CardsItem } from '../../../../__generated/model';
import ReservationCard from './ReservationCardV2';

interface P {
  baseId: BaseId;
  userId: UserId;
  lineLiffId: Id | undefined;
  cards: FetchResourceUsages200CardsItem[];
}

const ReservationCards = React.forwardRef<CardDeckRef, P>((props, ref) => {
  const { baseId, userId, lineLiffId, cards } = props;
  const unmountRef = useUnmountRef();
  const [cardDataList, setCardDataList] = useSafeState<CardData[]>(unmountRef, []);
  const [cardHeight, setCardHeight] = useSafeState<Height>(unmountRef, 0);

  const initialize = useSafeCallback((): void => {
    const cardDataList = cards.map((card, index) => {
      const node = (bind: () => ReactUseGestureEventHandlers, fronting: boolean): React.ReactNode => (
        <ReservationCard
          fronting={fronting}
          baseId={baseId}
          userId={userId}
          lineLiffId={lineLiffId}
          card={card}
          setCardHeight={setCardHeight}
          bind={bind}
        />
      );

      return builder<CardData>()
        .id(card.cardId)
        .visible(true)
        .propsIndex(index)
        .position(PositionEnum.CENTER)
        .node(node)
        .build();
    });
    setCardDataList(cardDataList);
  }, [baseId, cards, setCardDataList, setCardHeight, userId]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (typeof ref === 'function') return;
    ref?.current?.moveCard(PositionEnum.CENTER);
  }, [ref]);

  return (
    <Container>
      {hasLength(cardDataList) && (
        <YSwipableDeck
          ref={ref}
          cardDataList={cardDataList}
          cardHeight={cardHeight}
          swipeDirection={DirectionEnum.UP}
        />
      )}
    </Container>
  );
});

ReservationCards.displayName = 'ReservationCards';
export default ReservationCards;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
