/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  CreateDropIn200,
  CreateDropInBody,
  CreateSpaceReservationPayment200,
  CreateSpaceReservationPaymentBody,
  ExtendDropIn200,
  ExtendDropInBody,
  FetchDropIn200,
  FetchResourceUsages200,
  SearchDropInItems200
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';
import type { BodyType } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsSpace = () => {
/**
 * ドロップインプランを取得する
 * @summary ドロップインプランを取得する
 */
const searchDropInItems = (
    baseId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchDropInItems200>(
      {url: `/user/base/${baseId}/drop-in`, method: 'GET'
    },
      options);
    }
  /**
 * ドロップイン情報を新規作成する
 * @summary ドロップイン情報を新規作成する
 */
const createDropIn = (
    baseId: string,
    createDropInBody: BodyType<CreateDropInBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<CreateDropIn200>(
      {url: `/user/base/${baseId}/drop-in`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createDropInBody
    },
      options);
    }
  /**
 * ドロップイン情報を更新する
 * @summary ドロップイン情報を更新する
 */
const extendDropIn = (
    baseId: string,
    dropInId: string,
    extendDropInBody: BodyType<ExtendDropInBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<ExtendDropIn200>(
      {url: `/user/base/${baseId}/drop-in/${dropInId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: extendDropInBody
    },
      options);
    }
  /**
 * ドロップインデータを取得する
 * @summary ドロップインデータを取得する
 */
const fetchDropIn = (
    baseId: string,
    dropInId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchDropIn200>(
      {url: `/user/base/${baseId}/drop-in/${dropInId}`, method: 'GET'
    },
      options);
    }
  /**
 * Stripeの決済ページのURLを生成する
 * @summary Stripeの決済ページのURLを生成する
 */
const createSpaceReservationPayment = (
    baseId: string,
    spaceReservationId: string,
    createSpaceReservationPaymentBody: BodyType<CreateSpaceReservationPaymentBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<CreateSpaceReservationPayment200>(
      {url: `/user/base/${baseId}/space-reservation/${spaceReservationId}/payment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createSpaceReservationPaymentBody
    },
      options);
    }
  /**
 * 本日の予定情報を取得する
 * @summary 本日の予定情報を取得する
 */
const fetchResourceUsages = (
    baseId: string,
    userId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchResourceUsages200>(
      {url: `/user/base/${baseId}/user/${userId}/resource-usage`, method: 'GET'
    },
      options);
    }
  return {searchDropInItems,createDropIn,extendDropIn,fetchDropIn,createSpaceReservationPayment,fetchResourceUsages}};
export type SearchDropInItemsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['searchDropInItems']>>>
export type CreateDropInResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['createDropIn']>>>
export type ExtendDropInResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['extendDropIn']>>>
export type FetchDropInResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchDropIn']>>>
export type CreateSpaceReservationPaymentResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['createSpaceReservationPayment']>>>
export type FetchResourceUsagesResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchResourceUsages']>>>
