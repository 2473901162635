import {
  Breadcrumb,
  BreadcrumbTrailV2,
  ButtonV2,
  ColgroupV2,
  Component,
  IconButtonV2,
  InputDateWithLabelV2,
  InputWithLabelV2,
  LabelV2,
  RadioBoxV2,
  ScreenLoaderV2,
  SelectBoxV2,
  TableV2,
  TbodyV2,
  TdV2,
  TextFieldV2,
  TheadV2,
  themeV2,
  ThV2,
  TrV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BILLING_ID_V2,
  BillingDetailCategory,
  BillingDetailId,
  BillingDetailV2,
  BillingIdV2,
  BillingLogV2,
  BillingStatus,
  BillingV2,
  CONTRACT_ID_V2,
  ContractIdV2,
  ContractorType,
  ContractV2,
  DELETE_BILLING_DETAIL_V2_FOR_ADMIN,
  DeleteBillingDetailV2ForAdminRequest,
  DeleteBillingDetailV2ForAdminResponse,
  FETCH_BILLING_NO_V2_FOR_ADMIN,
  FETCH_BILLING_V2_FOR_ADMIN,
  FETCH_CONTRACT_V2_FOR_ADMIN,
  FETCH_TAXES_V2_FOR_ADMIN,
  FETCH_UNITS_V2_FOR_ADMIN,
  FetchBillingNoV2ForAdminRequest,
  FetchBillingNoV2ForAdminResponse,
  FetchBillingV2ForAdminRequest,
  FetchBillingV2ForAdminResponse,
  FetchContractV2ForAdminRequest,
  FetchContractV2ForAdminResponse,
  FetchTaxesV2ForAdminRequest,
  FetchTaxesV2ForAdminResponse,
  FetchUnitsV2ForAdminRequest,
  FetchUnitsV2ForAdminResponse,
  HonorificType,
  PaymentMethod,
  PaymentOption,
  PriceUtils,
  SAVE_BILLING_DETAIL_V2_FOR_ADMIN,
  SAVE_BILLING_LOG_V2_FOR_ADMIN,
  SAVE_BILLINGS_V2_FOR_ADMIN,
  SaveBillingDetailV2ForAdminRequest,
  SaveBillingDetailV2ForAdminResponse,
  SaveBillingLogV2ForAdminRequest,
  SaveBillingLogV2ForAdminResponse,
  SaveBillingsV2ForAdminRequest,
  SaveBillingsV2ForAdminResponse,
  TaxV2,
  UnitId,
  UnitV2,
  User
} from '@atomica.co/irori';
import {
  Account,
  Address,
  Count,
  Id,
  Index,
  Key,
  Message,
  Name,
  NoStr,
  Option,
  Post,
  Price,
  Quantity,
  Remarks
} from '@atomica.co/types';
import {
  builder,
  embedIdInPath,
  EMPTY,
  EMPTY_HALF_WIDTH,
  hasLength,
  isGreaterThanZero,
  MINUS_ONE,
  ONE,
  Optional,
  paddingZero,
  PREFECTURE_NAMES,
  toEndOfDay,
  toFirstDayOfMonth,
  toLastDayOfMonth,
  uuid,
  ZERO
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Add, Clear, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { HONORIFIC_TYPE_OPTIONS } from '../../constants/billing-v2-const';
import { HEADER_HEIGHT } from '../../constants/common-const';
import { CONTRACT_NO_DIGITS, MAX_ENTITIY_NAME_LENGTH } from '../../constants/contract-v2-const';
import {
  BillingSummaryHeader,
  DetailForBillingRegisterScreen,
  toBillingDetailsFromExistingBillingDetails,
  toBillingSummary
} from '../../converters/billing-v2-converter';
import { DisplayTaxes, toDisplayTaxes, toTaxLabels, toTaxOptions } from '../../converters/tax-converter';
import { toUnitLabels, toUnitOptions } from '../../converters/unit-converter';
import { BillingDetailInputKeyEnum } from '../../enums/billing-v2-enum';
import { Labels } from '../../models/common-model';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCachedBillingList, { CachedBillingInfo } from '../../redux/hooks/useCachedBillingList';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';
import { BILLING_PAYMENT_METHOD_LABEL, HONORIFIC_TYPE_LABEL } from '../../texts/billing-v2-text';
import { PAYMENT_COUNT_LABELS } from '../../texts/contract-v2-text';
import { createBillingLogsFromBilling, createBillingLogsFromBillingDetail } from '../../utils/billing-log-v2-util';
import { billingDetailValidater, billingValidater } from '../../utils/billing-v2-util';
import { isBasicCharge } from '../../utils/item-util';
import { getTaxKey, reverseTaxKey } from '../../utils/tax-util';
import BillingSummaryScreen from './billing-summary/BillingSummary';
import SearchContractModal from './SeachContractModal';

const REGISTER_BILLING_HEADER_HEIGHT = 40;
const REGISTER_BILLING_CONSOLE_FOOTER_HEIGHT = 64;
const REGISTER_BILLING_START_TOP = HEADER_HEIGHT + REGISTER_BILLING_HEADER_HEIGHT;

interface P {
  isDrawerOpen: boolean;
  base: BaseDto;
  user: User;
}

const getValidBillingDetails = (details: DetailForBillingRegisterScreen[]): DetailForBillingRegisterScreen[] => {
  if (!details || !hasLength(details)) return details;
  return details.filter(detail => {
    if (!detail.isDeletable) return true;
    return !!detail.itemName && detail.unitPrice !== ZERO && isGreaterThanZero(detail.quantity) && detail.taxDiv;
  });
};

const RegisterBillingScreen: React.FC<P> = React.memo(props => {
  const { isDrawerOpen, base, user } = props;
  const { path, params, openPath, queryParams } = usePath();
  const { commonRequest } = useCommonRequest();
  const { cachedBillingInfo, saveCachedBillingInfo } = useCachedBillingList();
  const billingList = useRef<CachedBillingInfo>(cachedBillingInfo);
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [showLoader, setShowLoader] = useSafeState<boolean>(unmountRef, false);
  const [noItemMessage, setNoItemMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [emptyErrorMessage, setEmptyErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [invalidErrorMessage, setInvalidErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [units, setUnits] = useSafeState<UnitV2[]>(unmountRef, []);
  const [taxes, setTaxes] = useSafeState<TaxV2[]>(unmountRef, []);
  const [displayTaxes, setDisplayTaxes] = useSafeState<DisplayTaxes>(unmountRef);
  const [previousBilling, setPreviousBilling] = useSafeState<BillingV2>(unmountRef);
  const [previousBillingDetails, setPreviousBillingDetails] = useSafeState<BillingDetailV2[]>(unmountRef, []);
  const [billing, setBilling] = useSafeState<BillingV2>(unmountRef);
  const [billingDetails, setBillingDetails] = useSafeState<DetailForBillingRegisterScreen[]>(unmountRef, []);
  const [billingNo, setBillingNo] = useSafeState<NoStr>(unmountRef, EMPTY);
  const [billingDate, setBillingDate] = useSafeState<Date>(unmountRef, toFirstDayOfMonth(new Date(), ONE));
  const [billingName, setBillingName] = useSafeState<Name>(unmountRef, EMPTY);
  const [billingStatus, setBillingStatus] = useSafeState<BillingStatus>(unmountRef, BillingStatus.UNCONFIRMED);
  const [contractorInfo, setContractorInfo] = useSafeState<Name>(unmountRef, EMPTY);
  const [contractorName, setContractorName] = useSafeState<Name>(unmountRef, EMPTY);
  const [paymentDueDate, setPaymentDueDate] = useSafeState<Date>(unmountRef, toLastDayOfMonth(new Date(), ONE));
  const [honorificType, setHonorificType] = useSafeState<HonorificType>(unmountRef, HonorificType.ONCHU);
  const [billingPost, setBillingPost] = useSafeState<Post>(unmountRef, EMPTY);
  const [billingAddress, setBillingAddress] = useSafeState<Address>(unmountRef, EMPTY);
  const [bankAccount, setBankAccount] = useSafeState<Account>(unmountRef, EMPTY);
  const [payableAccount, setPayableAccount] = useSafeState<Account>(unmountRef, EMPTY);
  const [paymentMethod, setPaymentMethod] = useSafeState<PaymentMethod>(unmountRef, PaymentMethod.BANK_TRANSFER);
  const [paymentOption, setPaymentOption] = useSafeState<PaymentOption>(unmountRef, PaymentOption.SINGLE);
  const [paymentCount, setPaymentCount] = useSafeState<Count>(unmountRef, ONE);
  const [remarks, setRemarks] = useSafeState<Remarks>(unmountRef, EMPTY);
  const [isShownSelectContractButton, setIsShownSelectContractButton] = useSafeState<boolean>(unmountRef, true);
  const [isOpenSelectContractModal, setIsOpenSelectContractModal] = useSafeState<boolean>(unmountRef, false);
  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef);

  const isEdit = useMemo<boolean>(() => {
    return path === Path.EDIT_BILLING_V2;
  }, [path]);

  const billingId = useMemo<BillingIdV2>(() => {
    return isEdit ? params[BILLING_ID_V2] : uuid();
  }, [params, isEdit]);

  const cutoffDate = useMemo<Date | undefined>(() => {
    return toEndOfDay(toLastDayOfMonth(billingDate, MINUS_ONE));
  }, [billingDate]);

  const taxLabels = useMemo<Labels>(() => toTaxLabels(displayTaxes), [displayTaxes]);
  const taxOptions = useMemo<Option[]>(() => toTaxOptions(displayTaxes), [displayTaxes]);
  const unitLabels = useMemo<Labels>(() => toUnitLabels(units), [units]);
  const unitOptions = useMemo<Option[]>(() => toUnitOptions(units), [units]);

  const loaded = useMemo<boolean>(() => (isEdit ? !!billing : !!billingNo), [billing, billingNo, isEdit]);

  const handleSelectedContract = useSafeCallback(
    (contract: ContractV2): void => {
      setContract(contract);
      if (!contract) return;

      const post =
        contract.contractorPost || contract.billingPost
          ? `〒${contract.billingPost ?? contract.contractorPost}\n`
          : EMPTY;
      const prefecture =
        contract.contractorPrefecture || contract.billingPrefecture
          ? PREFECTURE_NAMES[contract.contractorPrefecture || contract.billingPrefecture]
          : EMPTY;
      const city = contract.contractorCity || contract.billingCity || EMPTY;
      const address = contract.contractorAddress || contract.billingAddress || EMPTY;

      setContractorName(
        contract.contractorType === ContractorType.ENTITY ? (contract.entityName ?? EMPTY) : contract.contractorName
      );
      setHonorificType(contract.contractorType === ContractorType.ENTITY ? HonorificType.ONCHU : HonorificType.SAMA);
      setContractorInfo(post + prefecture + city + address);
      setPaymentMethod(contract.paymentMethod);
      setPaymentCount(contract.paymentCount);
      setBankAccount(contract.bankAccount ?? EMPTY);
      setBillingAddress(`${contract.billingCity} ${contract.billingAddress}`);
      setBillingPost(contract.billingPost);
    },
    [
      setBankAccount,
      setBillingAddress,
      setBillingPost,
      setContract,
      setContractorInfo,
      setContractorName,
      setHonorificType,
      setPaymentCount,
      setPaymentMethod
    ]
  );

  const createBillingNo = useSafeCallback(async (): Promise<void> => {
    if (isEdit) return;
    const request = builder<FetchBillingNoV2ForAdminRequest>().baseId(base.baseId).build();
    const response = await commonRequest<FetchBillingNoV2ForAdminRequest, FetchBillingNoV2ForAdminResponse>(
      FETCH_BILLING_NO_V2_FOR_ADMIN,
      request
    );
    const suffix = base.baseCode.slice(ZERO, ONE).toUpperCase();
    const seqence = paddingZero(response.billingSeq + ONE, CONTRACT_NO_DIGITS).slice(-CONTRACT_NO_DIGITS);
    setBillingNo(`${suffix}${seqence}`);
  }, [base, commonRequest, path, setBillingNo, isEdit]);

  const fetchBilling = useSafeCallback(async (): Promise<Optional<BillingV2>> => {
    const request = builder<FetchBillingV2ForAdminRequest>().baseId(base.baseId).billingId(billingId).build();
    const response = await commonRequest<FetchBillingV2ForAdminRequest, FetchBillingV2ForAdminResponse>(
      FETCH_BILLING_V2_FOR_ADMIN,
      request
    );
    setBilling(response.billing);
    return Optional.of(response?.billing);
  }, [base, billingId, commonRequest, setBilling]);

  const fetchContract = useSafeCallback(
    async (contractId: ContractIdV2): Promise<void> => {
      const request = builder<FetchContractV2ForAdminRequest>().baseId(base.baseId).contractId(contractId).build();
      const response = await commonRequest<FetchContractV2ForAdminRequest, FetchContractV2ForAdminResponse>(
        FETCH_CONTRACT_V2_FOR_ADMIN,
        request
      );
      if (response?.contract) {
        setContract(response.contract);
        setIsShownSelectContractButton(false);
        handleSelectedContract(response.contract);
      }
    },
    [base.baseId, commonRequest, handleSelectedContract, setContract, setIsShownSelectContractButton]
  );

  const fetchTaxes = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchTaxesV2ForAdminRequest>().baseId(base.baseId).build();
    const response = await commonRequest<FetchTaxesV2ForAdminRequest, FetchTaxesV2ForAdminResponse>(
      FETCH_TAXES_V2_FOR_ADMIN,
      request
    );
    setTaxes(response.taxes);
    setDisplayTaxes(toDisplayTaxes(response.taxes));
  }, [base, commonRequest, setDisplayTaxes, setTaxes]);

  const fetchUnits = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchUnitsV2ForAdminRequest>().baseId(base.baseId).build();
    const response = await commonRequest<FetchUnitsV2ForAdminRequest, FetchUnitsV2ForAdminResponse>(
      FETCH_UNITS_V2_FOR_ADMIN,
      request
    );
    setUnits(response.units);
  }, [base, commonRequest, setUnits]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    await Promise.all([createBillingNo(), fetchTaxes(), fetchUnits(), fetchBilling()]).then(async ([, , , billing]) => {
      const contractIdInQuery = queryParams[CONTRACT_ID_V2];
      if (contractIdInQuery) {
        return await fetchContract(contractIdInQuery);
      } else {
        const contractId = billing.map(e => e.contract?.contractId).getOrElse(undefined);
        return contractId !== undefined && (await fetchContract(contractId));
      }
    });
  }, [createBillingNo, fetchTaxes, fetchUnits, fetchBilling, queryParams, fetchContract]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!billing) return;
    setPreviousBilling(billing);
    setBankAccount(billing.bankAccount ?? EMPTY);
    setBillingAddress(billing.billingAddress ?? EMPTY);
    setBillingNo(billing.billingNo);
    setBillingPost(billing.billingPost);
    setBillingStatus(billing.billingStatus);
    setBillingName(billing.billingName);
    setContractorInfo(billing.contractorInfo);
    setRemarks(billing.remarks ?? EMPTY);
    setBillingDate(billing.billingDate);
    setPaymentDueDate(billing.paymentDueDate ?? toLastDayOfMonth(new Date(), ONE));
    setPaymentMethod(billing.paymentMethod);
    setPaymentCount(billing.paymentCount ?? ONE);
    setPaymentOption(
      billing.paymentMethod === PaymentMethod.CREDIT_CARD && billing.paymentCount && billing.paymentCount > ONE
        ? PaymentOption.INSTALLMENT
        : PaymentOption.SINGLE
    );
    setPayableAccount(billing.payableAccount ?? EMPTY);
    setContractorName(billing.contractorName);
    setHonorificType(billing.honorificType);
    if (!hasLength(billing.billingDetails)) return;
    setPreviousBillingDetails(billing.billingDetails || []);
    setBillingDetails(toBillingDetailsFromExistingBillingDetails(billing.billingDetails!));
    setContract(billing.contract);
    setIsShownSelectContractButton(!billing.contract);
  }, [
    billing,
    setBankAccount,
    setBillingAddress,
    setBillingDate,
    setBillingDetails,
    setBillingNo,
    setBillingPost,
    setBillingStatus,
    setBillingName,
    setContractorInfo,
    setContractorName,
    setPaymentDueDate,
    setPaymentMethod,
    setPayableAccount,
    setPaymentCount,
    setPaymentOption,
    setPreviousBilling,
    setPreviousBillingDetails,
    setRemarks,
    setHonorificType,
    setContract,
    setIsShownSelectContractButton
  ]);

  const backToBillingListOrBillingDetails = useSafeCallback((): void => {
    saveCachedBillingInfo(billingList.current);
  }, [billingList, saveCachedBillingInfo]);

  const breadcrumbs = useMemo(
    (): Breadcrumb[] => [
      {
        label: '戻る',
        path: isEdit
          ? embedIdInPath(Path.BILLING_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, billingId])
          : embedIdInPath(Path.BILLING_LIST_V2, PATH_IDS, [base.baseCode]),
        handlePathChanged: backToBillingListOrBillingDetails
      }
    ],
    [base, billingId, backToBillingListOrBillingDetails, isEdit]
  );

  const handleCancelClicked = useSafeCallback((): void => {
    saveCachedBillingInfo(billingList.current);
    isEdit
      ? openPath(embedIdInPath(Path.BILLING_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, billingId]))
      : openPath(embedIdInPath(Path.BILLING_LIST_V2, PATH_IDS, [base.baseCode]));
  }, [saveCachedBillingInfo, path, openPath, base, billingId, isEdit]);

  const onChangeDetail = useSafeCallback(
    (detailId: Id, key: BillingDetailInputKeyEnum, val: string | number | boolean): void => {
      setBillingDetails(details => {
        const targetIndex = details.findIndex(
          (detail: DetailForBillingRegisterScreen) =>
            detail.billingDetailId === detailId || detail.itemCode === detailId
        );
        details[targetIndex] = { ...details[targetIndex], [key]: val };
        return details.concat();
      });
    },
    [setBillingDetails]
  );

  const handleItemNameChanged = useSafeCallback(
    (id: Id, val: Name): void => {
      onChangeDetail(id, BillingDetailInputKeyEnum.ITEM_NAME, val);
    },
    [onChangeDetail]
  );
  const handleUnitPriceChanged = useSafeCallback(
    (id: Id, val: Price): void => {
      onChangeDetail(id, BillingDetailInputKeyEnum.UNIT_PRICE, val);
    },
    [onChangeDetail]
  );
  const handleQuantityChanged = useSafeCallback(
    (id: Id, val: Quantity): void => {
      onChangeDetail(id, BillingDetailInputKeyEnum.QUANTITY, val);
    },
    [onChangeDetail]
  );
  const handleUnitChanged = useSafeCallback(
    (id: Id, val: UnitId): void => {
      onChangeDetail(id, BillingDetailInputKeyEnum.UNIT, val);
    },
    [onChangeDetail]
  );
  const handleTaxChanged = useSafeCallback(
    (id: Id, val: Key): void => {
      const { taxId, taxDiv, taxRate } = reverseTaxKey(val);
      if (!taxId || !taxDiv || taxRate == null) return;
      onChangeDetail(id, BillingDetailInputKeyEnum.TAX, taxId);
      onChangeDetail(id, BillingDetailInputKeyEnum.TAX_DIV, taxDiv);
      onChangeDetail(id, BillingDetailInputKeyEnum.TAX_RATE, taxRate);
    },
    [onChangeDetail]
  );
  const handleDisabledChanged = useSafeCallback(
    (id: Id, val: boolean): void => {
      onChangeDetail(id, BillingDetailInputKeyEnum.DISABLED, !val);
    },
    [onChangeDetail]
  );

  const getInitialDetail = (): DetailForBillingRegisterScreen => {
    return builder<DetailForBillingRegisterScreen>()
      .billingDetailId(uuid())
      .category(BillingDetailCategory.MANUAL)
      .disabled(false)
      .isDeletable(true)
      .itemName(EMPTY)
      .quantity(ZERO)
      .unitPrice(ZERO)
      .build();
  };

  const addDetailRow = useSafeCallback((): void => {
    const contractDetailToAdd = getInitialDetail();
    setBillingDetails(billingDetails => {
      return [...billingDetails, contractDetailToAdd];
    });
  }, [setBillingDetails]);

  const deleteDetailRow = useSafeCallback(
    (billingDetailId: BillingDetailId): void => {
      setBillingDetails(billingDetails => {
        return billingDetails.filter(detail => detail.billingDetailId !== billingDetailId);
      });
    },
    [setBillingDetails]
  );

  const forwardToBillingDetail = useSafeCallback(
    (billingId: BillingIdV2): void => {
      isEdit
        ? saveCachedBillingInfo(billingList.current)
        : saveCachedBillingInfo({
            billings: billingList.current.billings,
            selectedBillingIds: [billingId],
            offset: billingList.current.offset,
            searchingWord: billingList.current.searchingWord,
            sortKey: billingList.current.sortKey,
            sort: billingList.current.sort,
            totalRecordCount: billingList.current.totalRecordCount
              ? billingList.current.totalRecordCount + ONE
              : billingList.current.billings.length + ONE
          });
      openPath(embedIdInPath(Path.BILLING_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, billingId]));
    },
    [base, path, openPath, saveCachedBillingInfo, isEdit]
  );

  const currentBillingDetails = useMemo((): DetailForBillingRegisterScreen[] => {
    return getValidBillingDetails(billingDetails);
  }, [billingDetails]);

  // billing summary
  const billingSummary = useMemo((): BillingSummaryHeader => {
    return toBillingSummary(currentBillingDetails);
  }, [currentBillingDetails]);

  const notifySaveFailed = useSafeCallback((): void => {
    openSnackbar('請求の保存に失敗しました', 'error');
    setShowLoader(false);
  }, [openSnackbar, setShowLoader]);

  const handleBillingSaved = useSafeCallback(async (): Promise<void> => {
    if (!user) return;

    const billingToSave = builder<BillingV2>()
      .billingId(billingId || uuid())
      .bankAccount(bankAccount)
      .billingAddress(billingAddress)
      .billingPost(billingPost)
      .billingNo(billingNo)
      .billingStatus(billingStatus)
      .billingName(billingName)
      .contractorInfo(contractorInfo)
      .cutoffDate(cutoffDate!)
      .remarks(remarks)
      .billingDate(billingDate)
      .paymentDueDate(toEndOfDay(paymentDueDate)!)
      .paymentMethod(paymentMethod)
      .payableAccount(payableAccount)
      .paymentCount(paymentCount)
      .taxIncludedTotalPrice(billingSummary.taxIncludedTotalPrice)
      .taxExcludedTotalPrice(billingSummary.taxExcludedTotalPrice)
      .contractorName(contractorName)
      .honorificType(honorificType)
      .isRecurring(billing?.isRecurring ?? false)
      .base(base)
      .contract(contract)
      .createdUser(user)
      .updatedUser(user)
      .build();

    const validBillingDetails = currentBillingDetails.filter(detail => {
      switch (detail.category) {
        case BillingDetailCategory.SPACE_USAGE:
        case BillingDetailCategory.ACCESS:
          return PriceUtils.applyAmountPrice(detail.unitPrice, detail.quantity) > 0;

        case BillingDetailCategory.DISCOUNT:
          return PriceUtils.applyAmountPrice(detail.unitPrice, detail.quantity) < 0;

        default:
          return true;
      }
    });

    const billingDetailsToSave = validBillingDetails.map((detail: DetailForBillingRegisterScreen, idx: Index) =>
      builder<BillingDetailV2>()
        .billingDetailId(detail.billingDetailId)
        .billing(billingToSave)
        .category(detail.category)
        .description(EMPTY)
        .displayOrder(idx + ONE)
        .isDeletable(detail.isDeletable)
        .isDisabled(detail.disabled)
        .item(detail.item!)
        .itemName(detail.itemName)
        .tax(taxes.find(tax => tax.taxId === detail.taxId)!)
        .taxDiv(detail.taxDiv!)
        .unit(units.find(unit => unit.unitId === detail.unitId)!)
        .unitPrice(detail.unitPrice)
        .unitQuantity(detail.quantity)
        .createdUser(user!)
        .updatedUser(user!)
        .build()
    );

    let billingDetailEmptyErrorMessage: string[] = [];
    let billingDetailInvalidErrorMessage: string[] = [];

    const { emptyErrorMessage: billingEmptyErrorMessage, invalidErrorMessage: billingInvalidErrorMessage } =
      billingValidater(billingToSave);
    const { emptyErrorMessage, invalidErrorMessage } = billingDetailValidater(billingDetails);
    billingDetailEmptyErrorMessage = emptyErrorMessage;
    billingDetailInvalidErrorMessage = invalidErrorMessage;
    setEmptyErrorMessage(EMPTY);
    setInvalidErrorMessage(EMPTY);
    setNoItemMessage(EMPTY);

    const existsError: boolean =
      hasLength(billingEmptyErrorMessage) ||
      hasLength(billingDetailEmptyErrorMessage) ||
      hasLength(billingInvalidErrorMessage) ||
      hasLength(billingDetailInvalidErrorMessage);
    if (existsError || !hasLength(billingDetails)) {
      if (existsError) {
        setEmptyErrorMessage(billingEmptyErrorMessage.concat(billingDetailEmptyErrorMessage).join(', '));
        setInvalidErrorMessage(billingInvalidErrorMessage.concat(billingDetailInvalidErrorMessage).join(', '));
      }
      if (!hasLength(billingDetails)) setNoItemMessage('品目がありません');
      return;
    }

    setShowLoader(true);
    const billingRequest = builder<SaveBillingsV2ForAdminRequest>()
      .baseId(base.baseId)
      .billings([billingToSave])
      .build();
    const saveBillingResponse = await commonRequest<SaveBillingsV2ForAdminRequest, SaveBillingsV2ForAdminResponse>(
      SAVE_BILLINGS_V2_FOR_ADMIN,
      billingRequest
    );
    if (!saveBillingResponse?.billingIds?.length) return notifySaveFailed();

    const billingDetailRequest = builder<SaveBillingDetailV2ForAdminRequest>()
      .baseId(base.baseId)
      .billingDetails(billingDetailsToSave)
      .build();
    const saveBillingDetailResponse = await commonRequest<
      SaveBillingDetailV2ForAdminRequest,
      SaveBillingDetailV2ForAdminResponse
    >(SAVE_BILLING_DETAIL_V2_FOR_ADMIN, billingDetailRequest);

    if (!saveBillingDetailResponse?.billingDetailIds?.length) return notifySaveFailed();

    const billingDetailsToDelete = previousBillingDetails.filter(
      prev => !billingDetailsToSave.some(saved => saved.billingDetailId === prev.billingDetailId)
    );
    if (hasLength(billingDetailsToDelete)) {
      const billingDetailsDeleteRequest = builder<DeleteBillingDetailV2ForAdminRequest>()
        .baseId(base.baseId)
        .billingDetailIds(billingDetailsToDelete.map(detail => detail.billingDetailId))
        .build();
      const deletedBillingDetails = await commonRequest<
        DeleteBillingDetailV2ForAdminRequest,
        DeleteBillingDetailV2ForAdminResponse
      >(DELETE_BILLING_DETAIL_V2_FOR_ADMIN, billingDetailsDeleteRequest);
      if (!hasLength(deletedBillingDetails.billingDetailIds)) throw new Error();
    }

    const billingLogsFromBilling = createBillingLogsFromBilling(previousBilling, billingToSave, user);

    let billingLogsFromBillingDetail: BillingLogV2[] = [];
    if (previousBilling) {
      billingLogsFromBillingDetail = createBillingLogsFromBillingDetail(
        billingToSave,
        previousBillingDetails,
        billingDetailsToSave,
        user,
        units,
        taxes
      );
    }

    const billingLogToSave = builder<SaveBillingLogV2ForAdminRequest>()
      .baseId(base.baseId)
      .billingLogs(billingLogsFromBilling.concat(billingLogsFromBillingDetail))
      .build();
    const saveBillingLogResponse = await commonRequest<
      SaveBillingLogV2ForAdminRequest,
      SaveBillingLogV2ForAdminResponse
    >(SAVE_BILLING_LOG_V2_FOR_ADMIN, billingLogToSave);

    if (!saveBillingLogResponse?.billingLogIds?.length) return notifySaveFailed();

    forwardToBillingDetail(billingId);
    setShowLoader(false);
  }, [
    bankAccount,
    billingAddress,
    billingDate,
    billingId,
    billingNo,
    billingPost,
    billingStatus,
    billingName,
    billingSummary,
    commonRequest,
    contractorInfo,
    cutoffDate,
    remarks,
    paymentDueDate,
    paymentMethod,
    payableAccount,
    paymentCount,
    previousBilling,
    previousBillingDetails,
    honorificType,
    taxes,
    contractorName,
    currentBillingDetails,
    units,
    user,
    base,
    billingDetails,
    setEmptyErrorMessage,
    setInvalidErrorMessage,
    setNoItemMessage,
    setShowLoader,
    forwardToBillingDetail
  ]);

  return (
    <Component className='register-billing-screen' loading={!loaded} style={styleForComponent}>
      <Container>
        <Header isDrawerOpen={isDrawerOpen}>
          <BreadcrumbTrailWrapper>
            <BreadcrumbTrailV2 breadcrumbs={breadcrumbs} />
          </BreadcrumbTrailWrapper>
        </Header>
        <ContentFrame>
          <DetailGrid>
            <Title>{hasLength(billingList.current.selectedBillingIds) ? '請求の編集' : '請求の新規作成'}</Title>

            <InputArea>
              <ContractWrapper>
                <ContractHeader>
                  <ContractTitle>契約情報</ContractTitle>
                  {isShownSelectContractButton && (
                    <ContractButtonWrapper>
                      <ButtonV2
                        type='primary'
                        onClick={() => setIsOpenSelectContractModal(true)}
                        label='契約を選択する'
                      />
                      <ButtonV2
                        disabled={!contract}
                        onClick={() => {
                          setContract(undefined);
                          setContractorName(EMPTY);
                          setContractorInfo(EMPTY);
                        }}
                        label='選択を解除する'
                      />
                    </ContractButtonWrapper>
                  )}
                </ContractHeader>
                {!!contract && (
                  <Row>
                    <Cell colspan={2}>
                      <InputWithLabelV2 text='契約番号' value={contract.contractNo} readonly={true} />
                    </Cell>
                    <Cell colspan={6}>
                      <InputWithLabelV2
                        text='契約者情報'
                        value={`${
                          contract.contractorType === ContractorType.ENTITY
                            ? `${contract.entityName}${EMPTY_HALF_WIDTH}`
                            : EMPTY
                        } ${contract.contractorName}`}
                        readonly={true}
                      />
                    </Cell>
                  </Row>
                )}
              </ContractWrapper>
              <BillingWrapper>
                <Row>
                  <Cell colspan={4}>
                    <InputWithLabelV2
                      text='請求番号'
                      required={true}
                      value={billingNo}
                      onChange={setBillingNo}
                      readonly={true}
                    />
                  </Cell>
                  <Cell colspan={3}>
                    <InputWithLabelV2
                      text='取引先'
                      required={true}
                      maxLength={MAX_ENTITIY_NAME_LENGTH}
                      value={contractorName}
                      onChange={setContractorName}
                    />
                  </Cell>
                  <Cell colspan={1}>
                    <SelectBoxV2
                      labels={HONORIFIC_TYPE_LABEL}
                      options={HONORIFIC_TYPE_OPTIONS}
                      value={honorificType}
                      onChange={setHonorificType}
                    />
                  </Cell>
                </Row>
                <Row>
                  <Cell colspan={4}>
                    <InputDateWithLabelV2 text='請求日' required={true} value={billingDate} onChange={setBillingDate} />
                  </Cell>
                  <Cell colspan={4}>
                    <InputDateWithLabelV2
                      text='支払い期日'
                      required={true}
                      value={paymentDueDate}
                      onChange={setPaymentDueDate}
                    />
                  </Cell>
                </Row>
                <Row>
                  <Cell>
                    <InputWithLabelV2
                      text='取引先情報'
                      multiline={true}
                      value={contractorInfo}
                      onChange={setContractorInfo}
                    />
                  </Cell>
                </Row>
                <Row>
                  <Cell>
                    <InputWithLabelV2 text='件名' value={billingName} onChange={setBillingName} />
                  </Cell>
                </Row>
                <Row>
                  <Cell>
                    <LabelV2 text='明細' />
                    {hasLength(billingDetails) && (
                      <TableV2 showBorder={true} shape='circle'>
                        <ColgroupV2 />
                        <ColgroupV2 width={112} />
                        <ColgroupV2 width={100} />
                        <ColgroupV2 width={100} />
                        <ColgroupV2 width={136} />
                        <ColgroupV2 width={80} />
                        <ColgroupV2 width={56} />
                        <TheadV2>
                          <TrV2>
                            <ThV2 horizonPadding={8}>品目</ThV2>
                            <ThV2 horizonPadding={8}>単価（円）</ThV2>
                            <ThV2 horizonPadding={8}>数量・単位</ThV2>
                            <ThV2 horizonPadding={8}>{EMPTY}</ThV2>
                            <ThV2 horizonPadding={8}>課税</ThV2>
                            <ThV2 horizonPadding={8}>金額（円）</ThV2>
                            <ThV2 horizonPadding={8}>{EMPTY}</ThV2>
                          </TrV2>
                        </TheadV2>
                        <TbodyV2>
                          {billingDetails.map((detail: DetailForBillingRegisterScreen, rowIdx: Index) => (
                            <TrV2 key={`detail-tr${rowIdx}`} showOverlay={detail.disabled}>
                              <TdV2 horizonPadding={8}>
                                <TextFieldV2
                                  align='left'
                                  value={detail.itemName}
                                  onChange={value => handleItemNameChanged(detail.billingDetailId, value)}
                                  disabled={detail.disabled}
                                  readonly={detail.readonly}
                                />
                              </TdV2>
                              <TdV2 horizonPadding={8}>
                                <TextFieldV2
                                  align='right'
                                  inputType='price'
                                  value={detail.unitPrice}
                                  onChange={value => handleUnitPriceChanged(detail.billingDetailId, value)}
                                  disabled={detail.disabled}
                                  readonly={detail.readonly}
                                />
                              </TdV2>
                              <TdV2 horizonPadding={8}>
                                <TextFieldV2
                                  align='right'
                                  inputType='quantity'
                                  value={detail.quantity}
                                  onChange={value => handleQuantityChanged(detail.billingDetailId, value)}
                                  disabled={detail.disabled}
                                  readonly={detail.readonly}
                                />
                              </TdV2>
                              <TdV2 horizonPadding={8}>
                                <SelectBoxV2
                                  labels={unitLabels}
                                  nullable={true}
                                  options={unitOptions}
                                  value={detail.unitId || EMPTY}
                                  onChange={unitId => handleUnitChanged(detail.billingDetailId, unitId)}
                                  readonly={detail.disabled || isBasicCharge(detail.itemCode) || detail.readonly}
                                />
                              </TdV2>
                              <TdV2 horizonPadding={8}>
                                <SelectBoxV2
                                  labels={taxLabels}
                                  options={taxOptions}
                                  value={getTaxKey(detail.taxId, detail.taxDiv, detail.taxRate)}
                                  onChange={key => handleTaxChanged(detail.billingDetailId, key)}
                                  readonly={detail.disabled || detail.readonly}
                                />
                              </TdV2>
                              <TdV2 horizonPadding={8} align='right'>
                                {detail.unitPrice && detail.quantity
                                  ? PriceUtils.applyAmountPrice(detail.unitPrice, detail.quantity).toLocaleString()
                                  : '-'}
                              </TdV2>
                              <TdV2 horizonPadding={8}>
                                {!isBasicCharge(detail.itemCode) && (
                                  <>
                                    {!detail.isDeletable && (
                                      <IconButtonV2
                                        size='medium'
                                        icon={
                                          detail.disabled ? (
                                            <VisibilityOff fontSize='small' />
                                          ) : (
                                            <Visibility fontSize='small' />
                                          )
                                        }
                                        onClick={() => handleDisabledChanged(detail.billingDetailId, detail.disabled)}
                                      />
                                    )}

                                    {detail.isDeletable && (
                                      <IconButtonV2
                                        size='medium'
                                        icon={<Clear />}
                                        onClick={() => deleteDetailRow(detail.billingDetailId)}
                                      />
                                    )}
                                  </>
                                )}
                              </TdV2>
                            </TrV2>
                          ))}
                        </TbodyV2>
                      </TableV2>
                    )}
                    <ButtonV2 startIcon={<Add />} label='品目を追加' onClick={addDetailRow} size='large' />
                  </Cell>
                </Row>
                {!isEdit && (
                  <Row direction='column' data-testid='payment-method'>
                    <LabelV2 text='支払い方法' />
                    <RadioWrapper>
                      <RadioBoxV2
                        options={[PaymentMethod.CREDIT_CARD, PaymentMethod.BANK_TRANSFER, PaymentMethod.INVOICE]}
                        labels={BILLING_PAYMENT_METHOD_LABEL}
                        onChange={setPaymentMethod}
                        value={paymentMethod}
                        gap={40}
                      />
                    </RadioWrapper>
                    {paymentMethod === PaymentMethod.CREDIT_CARD && (
                      <Row direction='column'>
                        <LabelV2 required text='支払い回数' />
                        <CustomRow direction='row'>
                          <RadioWrapper>
                            <RadioBoxV2
                              options={[PaymentOption.SINGLE, PaymentOption.INSTALLMENT]}
                              labels={PAYMENT_COUNT_LABELS}
                              onChange={setPaymentOption}
                              value={paymentOption}
                              direction='column'
                            />
                          </RadioWrapper>
                          <CustomCell>
                            <TextFieldV2
                              align='right'
                              value={paymentCount}
                              onChange={value => setPaymentCount(value)}
                              readonly={paymentOption === PaymentOption.SINGLE ? true : false}
                            />
                          </CustomCell>
                          回
                        </CustomRow>
                      </Row>
                    )}
                    {paymentMethod === PaymentMethod.INVOICE && (
                      <>
                        <Row>
                          <Cell>
                            <LabelV2 text='請求先住所' />
                          </Cell>
                        </Row>
                        <Row direction='column'>
                          <Cell colspan={2}>
                            <TextFieldV2
                              placeholder='郵便番号'
                              value={billingPost}
                              onChange={value => setBillingPost(value)}
                            />
                          </Cell>
                          <Cell colspan={6}>
                            <TextFieldV2
                              placeholder='住所'
                              value={billingAddress}
                              onChange={value => setBillingAddress(value)}
                            />
                          </Cell>
                        </Row>
                        <Row>
                          <Cell>
                            <InputWithLabelV2 text='振込先' value={payableAccount} onChange={setPayableAccount} />
                          </Cell>
                        </Row>
                      </>
                    )}
                    {paymentMethod === PaymentMethod.BANK_TRANSFER && (
                      <Row>
                        <Cell>
                          <InputWithLabelV2 text='引き落とし口座' value={bankAccount} onChange={setBankAccount} />
                        </Cell>
                      </Row>
                    )}
                  </Row>
                )}
                <Row>
                  <Cell>
                    <InputWithLabelV2 text='備考' multiline={true} value={remarks} onChange={setRemarks} />
                  </Cell>
                </Row>
              </BillingWrapper>
            </InputArea>
          </DetailGrid>
          <BillingGrid>
            <BillingContent>
              <Title>請求予定額</Title>
              <DescriptionLabel>利用状況に応じて請求金額は変動します。</DescriptionLabel>
              {hasLength(currentBillingDetails) && (
                <BillingListWrapper>
                  <BillingSummaryScreen title='基本料金' summary={billingSummary} />
                </BillingListWrapper>
              )}
            </BillingContent>
          </BillingGrid>
        </ContentFrame>

        <Footer hasError={!!emptyErrorMessage || !!noItemMessage || !!invalidErrorMessage}>
          {emptyErrorMessage && <ErrorMessage>{emptyErrorMessage}を入力してください</ErrorMessage>}
          {noItemMessage && <ErrorMessage>{noItemMessage}</ErrorMessage>}
          {invalidErrorMessage && <ErrorMessage>{invalidErrorMessage}の入力は不正です</ErrorMessage>}
          <ButtonWrapper>
            <ButtonV2 label='キャンセル' onClick={handleCancelClicked} />
            <ButtonV2 type='primary' label='保存' onClick={handleBillingSaved} />
          </ButtonWrapper>
        </Footer>
      </Container>

      <ScreenLoaderV2 loading={showLoader} />
      <SearchContractModal
        baseId={base?.baseId}
        isOpen={isOpenSelectContractModal}
        onClose={() => setIsOpenSelectContractModal(false)}
        onRowClick={handleSelectedContract}
      />
    </Component>
  );
});

RegisterBillingScreen.displayName = 'RegisterBillingScreen';
export default RegisterBillingScreen;

const styleForComponent: CSSProperties = {
  height: '100%'
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedDiv = styled.div`
  position: fixed;
  right: 0;
  transition: ${themeV2.transitions.create(['width', 'right'], {
    easing: themeV2.transitions.easing.easeOut,
    duration: themeV2.transitions.duration.enteringScreen
  })};
  width: 100%;
`;

const Header = styled(FixedDiv)<{ isDrawerOpen: boolean }>`
  background-color: ${themeV2.mixins.v2.color.background.lightGray};
  top: ${HEADER_HEIGHT}px;
  height: ${REGISTER_BILLING_HEADER_HEIGHT}px;
`;
const BreadcrumbTrailWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  align-items: center;
`;
const ContentFrame = styled(FixedDiv)`
  height: calc(100% - ${REGISTER_BILLING_CONSOLE_FOOTER_HEIGHT / 2 + REGISTER_BILLING_HEADER_HEIGHT}px);
  position: fixed;
  top: ${REGISTER_BILLING_START_TOP}px;
  padding-left: ${themeV2.mixins.v2.spacing * 3}px;
  display: grid;
  grid-template-areas: 'detail detail detail detail detail detail detail detail billing billing billing billing';
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: ${themeV2.mixins.v2.spacing * 3}px;

  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const GridArea = styled.div`
  border-radius: 0;
  height: calc(100vh - 152px);
  overflow: scroll;
  ${themeV2.mixins.v2.scrollbarInvisible};

  @supports (height: 1dvh) {
    height: calc(100dvh - 152px);
  }
`;
const DetailGrid = styled(GridArea)`
  grid-area: detail;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 10}px ${
    themeV2.mixins.v2.spacing
  }px`};
`;
const BillingGrid = styled(GridArea)`
  grid-area: billing;
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const BillingWrapper = styled.div`
  border-radius: 6px;
  padding: ${themeV2.mixins.v2.spacing * 3}px 0 ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
  background-color: #faf9f7; //FIXME
`;
const ContractWrapper = styled.div`
  border-radius: 6px;
  padding: ${themeV2.mixins.v2.spacing * 3}px 0 ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #faf9f7; //FIXME
`;

const Row = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  align-items: ${({ direction = 'row' }) => (direction === 'row' ? 'end' : 'start')};
  width: 100%;
  gap: ${({ direction = 'row' }) =>
    direction === 'row' ? `${themeV2.mixins.v2.spacing * 3}px` : `${themeV2.mixins.v2.spacing}px`};
`;

const CustomRow = styled(Row)`
  align-items: flex-end;
`;

const Cell = styled.div<{ colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 }>`
  width: ${({ colspan = 8 }) => `calc(calc(calc(100% / 8) * ${colspan}) - ${themeV2.mixins.v2.spacing * 3}px )`};
`;

const CustomCell = styled.div`
  width: 10%;
  display: flex;
`;

const Content = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 6px;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;

const RadioWrapper = styled.div`
  margin-left: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${themeV2.mixins.v2.spacing * 2}px;
  gap: 20px;
`;
const BillingContent = styled(Content)`
  min-height: 100%;
  border-radius: 0;
  padding-bottom: ${themeV2.mixins.v2.spacing * 15}px;
`;

const BillingListWrapper = styled.div``;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  padding: ${`${themeV2.mixins.v2.spacing}px 0`};
`;

const DescriptionLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.small};
  // to fix
  color: #666666;
  padding: ${themeV2.mixins.v2.spacing / 4}px 0;
`;

const ErrorMessage = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.red};
  text-align: center;
  margin: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Footer = styled(FixedDiv)<{ hasError: boolean }>`
  bottom: 0;
  background-color: ${themeV2.mixins.v2.color.background.white};
  height: ${({ hasError }) =>
    hasError ? REGISTER_BILLING_CONSOLE_FOOTER_HEIGHT + 36 : REGISTER_BILLING_CONSOLE_FOOTER_HEIGHT}px;
  box-shadow: ${themeV2.mixins.v2.shadow.elevation5};
`;

const ContractHeader = styled.div`
  display: flex;
  align-items: center;
`;
const ContractButtonWrapper = styled.div`
  margin-left: auto;
`;

const ContractTitle = styled.div`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;
