/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  CreateAccessToEntranceForAdmin200,
  CreateAccessToEntranceForAdminBody,
  DeleteAccessToEntranceForAdmin200,
  DeleteAccessToEntranceForAdminParams,
  FetchAccessesByDatesForAdmin200,
  FetchAccessesByDatesForAdminParams,
  FetchAccessesV2ForAdmin200,
  FetchAccessesV2ForAdminParams,
  FetchDropInsByDatesForAdmin200,
  FetchDropInsByDatesForAdminParams,
  FetchResourceForAdmin200,
  FetchSpaceReservationForAdmin200,
  FetchSpaceReservationsByDatesForAdmin200,
  FetchSpaceReservationsByDatesForAdminParams,
  FetchSpaceReservationsByUserForAdmin200,
  FetchSpaceReservationsByUserForAdminParams,
  SaveSpaceReservationForAdmin200,
  SaveSpaceReservationForAdminBody,
  SearchReservableSpacesForAdmin200,
  SearchReservableSpacesForAdminParams,
  SearchSpaceReservationsForAdmin200,
  SearchSpaceReservationsForAdminParams,
  SearchSpacesForAdmin200,
  SearchSpacesForAdminParams,
  UpdateAccessToEntranceForAdmin200,
  UpdateAccessToEntranceForAdminBody,
  UpdateResourceForAdmin200,
  UpdateResourceForAdminBody,
  UpdateResourceReservationPaymentStatusForAdmin200,
  UpdateResourceReservationPaymentStatusForAdminBody,
  UpdateSpaceReservationStatusForAdmin200,
  UpdateSpaceReservationStatusForAdminBody
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';
import type { BodyType } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsSpace = () => {
/**
 * リソース予約情報を検索する
 * @summary リソース予約情報を検索する
 */
const searchSpaceReservationsForAdmin = (
    baseId: string,
    params: SearchSpaceReservationsForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchSpaceReservationsForAdmin200>(
      {url: `/admin/base/${baseId}/space-reservation`, method: 'GET',
        params
    },
      options);
    }
  /**
 * リソース予約情報を取得する
 * @summary リソース予約情報を取得する
 */
const fetchSpaceReservationForAdmin = (
    baseId: string,
    spaceReservationId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchSpaceReservationForAdmin200>(
      {url: `/admin/base/${baseId}/space-reservation/${spaceReservationId}`, method: 'GET'
    },
      options);
    }
  /**
 * リソース予約情報を保存する
 * @summary リソース予約情報を保存する
 */
const saveSpaceReservationForAdmin = (
    baseId: string,
    spaceReservationId: string,
    saveSpaceReservationForAdminBody: BodyType<SaveSpaceReservationForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveSpaceReservationForAdmin200>(
      {url: `/admin/base/${baseId}/space-reservation/${spaceReservationId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveSpaceReservationForAdminBody
    },
      options);
    }
  /**
 * 指定した期間内の予約可能なリソース情報を検索する
 * @summary 指定した期間内の予約可能なリソース情報を検索する
 */
const searchReservableSpacesForAdmin = (
    baseId: string,
    params: SearchReservableSpacesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchReservableSpacesForAdmin200>(
      {url: `/admin/base/${baseId}/space/reservable`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 指定した期間内の入退室記録を取得する
 * @summary 指定した期間内の入退室記録を取得する
 */
const fetchAccessesByDatesForAdmin = (
    baseId: string,
    params: FetchAccessesByDatesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchAccessesByDatesForAdmin200>(
      {url: `/admin/base/${baseId}/access/date`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 入退室記録を取得する
 * @summary 入退室記録を取得する
 */
const fetchAccessesV2ForAdmin = (
    baseId: string,
    params: FetchAccessesV2ForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchAccessesV2ForAdmin200>(
      {url: `/admin/base/${baseId}/access`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 指定したユーザーが参加したリソース予約情報を取得する
 * @summary 指定したユーザーが参加したリソース予約情報を取得する
 */
const fetchSpaceReservationsByUserForAdmin = (
    baseId: string,
    params: FetchSpaceReservationsByUserForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchSpaceReservationsByUserForAdmin200>(
      {url: `/admin/base/${baseId}/space-reservation/user`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 入退室履歴の登録する
 * @summary 入退室履歴の登録する
 */
const createAccessToEntranceForAdmin = (
    baseId: string,
    createAccessToEntranceForAdminBody: BodyType<CreateAccessToEntranceForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<CreateAccessToEntranceForAdmin200>(
      {url: `/admin/base/${baseId}/access-to-entrance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createAccessToEntranceForAdminBody
    },
      options);
    }
  /**
 * 入退室履歴を削除する
 * @summary 入退室履歴を削除する
 */
const deleteAccessToEntranceForAdmin = (
    baseId: string,
    params: DeleteAccessToEntranceForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<DeleteAccessToEntranceForAdmin200>(
      {url: `/admin/base/${baseId}/access-to-entrance/bulk-delete`, method: 'DELETE',
        params
    },
      options);
    }
  /**
 * 入退室履歴の更新する
 * @summary 入退室履歴の更新する
 */
const updateAccessToEntranceForAdmin = (
    baseId: string,
    updateAccessToEntranceForAdminBody: BodyType<UpdateAccessToEntranceForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<UpdateAccessToEntranceForAdmin200>(
      {url: `/admin/base/${baseId}/access-to-entrance/bulk-delete`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateAccessToEntranceForAdminBody
    },
      options);
    }
  /**
 * リソース情報を取得する
 * @summary リソース情報を取得する
 */
const fetchResourceForAdmin = (
    baseId: string,
    resourceId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchResourceForAdmin200>(
      {url: `/admin/base/${baseId}/resource/${resourceId}`, method: 'GET'
    },
      options);
    }
  /**
 * リソースマスタ情報を更新する
 * @summary リソースマスタ情報を更新する
 */
const updateResourceForAdmin = (
    baseId: string,
    resourceId: string,
    updateResourceForAdminBody: BodyType<UpdateResourceForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<UpdateResourceForAdmin200>(
      {url: `/admin/base/${baseId}/resource/${resourceId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateResourceForAdminBody
    },
      options);
    }
  /**
 * 指定した期間内のリソース予約情報を取得する
 * @summary 指定した期間内のリソース予約情報を取得する
 */
const fetchSpaceReservationsByDatesForAdmin = (
    baseId: string,
    params: FetchSpaceReservationsByDatesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchSpaceReservationsByDatesForAdmin200>(
      {url: `/admin/base/${baseId}/space-reservation/date`, method: 'GET',
        params
    },
      options);
    }
  /**
 * リソース情報を検索する
 * @summary リソース情報を検索する
 */
const searchSpacesForAdmin = (
    baseId: string,
    params: SearchSpacesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchSpacesForAdmin200>(
      {url: `/admin/base/${baseId}/space`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 支払いステータスを更新する
 * @summary 支払いステータスを更新する
 */
const updateResourceReservationPaymentStatusForAdmin = (
    baseId: string,
    resourceId: string,
    updateResourceReservationPaymentStatusForAdminBody: BodyType<UpdateResourceReservationPaymentStatusForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<UpdateResourceReservationPaymentStatusForAdmin200>(
      {url: `/admin/base/${baseId}/resource/${resourceId}/reservation-payment-status`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateResourceReservationPaymentStatusForAdminBody
    },
      options);
    }
  /**
 * 予約ステータスを更新する
 * @summary 予約ステータスを更新する
 */
const updateSpaceReservationStatusForAdmin = (
    baseId: string,
    updateSpaceReservationStatusForAdminBody: BodyType<UpdateSpaceReservationStatusForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<UpdateSpaceReservationStatusForAdmin200>(
      {url: `/admin/base/${baseId}/space-reservation/bulk-update-status`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateSpaceReservationStatusForAdminBody
    },
      options);
    }
  /**
 * 指定した期間内のドロップイン情報を取得する
 * @summary 指定した期間内のドロップイン情報を取得する
 */
const fetchDropInsByDatesForAdmin = (
    baseId: string,
    params: FetchDropInsByDatesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchDropInsByDatesForAdmin200>(
      {url: `/admin/base/${baseId}/drop-in/date`, method: 'GET',
        params
    },
      options);
    }
  return {searchSpaceReservationsForAdmin,fetchSpaceReservationForAdmin,saveSpaceReservationForAdmin,searchReservableSpacesForAdmin,fetchAccessesByDatesForAdmin,fetchAccessesV2ForAdmin,fetchSpaceReservationsByUserForAdmin,createAccessToEntranceForAdmin,deleteAccessToEntranceForAdmin,updateAccessToEntranceForAdmin,fetchResourceForAdmin,updateResourceForAdmin,fetchSpaceReservationsByDatesForAdmin,searchSpacesForAdmin,updateResourceReservationPaymentStatusForAdmin,updateSpaceReservationStatusForAdmin,fetchDropInsByDatesForAdmin}};
export type SearchSpaceReservationsForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['searchSpaceReservationsForAdmin']>>>
export type FetchSpaceReservationForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchSpaceReservationForAdmin']>>>
export type SaveSpaceReservationForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['saveSpaceReservationForAdmin']>>>
export type SearchReservableSpacesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['searchReservableSpacesForAdmin']>>>
export type FetchAccessesByDatesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchAccessesByDatesForAdmin']>>>
export type FetchAccessesV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchAccessesV2ForAdmin']>>>
export type FetchSpaceReservationsByUserForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchSpaceReservationsByUserForAdmin']>>>
export type CreateAccessToEntranceForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['createAccessToEntranceForAdmin']>>>
export type DeleteAccessToEntranceForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['deleteAccessToEntranceForAdmin']>>>
export type UpdateAccessToEntranceForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['updateAccessToEntranceForAdmin']>>>
export type FetchResourceForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchResourceForAdmin']>>>
export type UpdateResourceForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['updateResourceForAdmin']>>>
export type FetchSpaceReservationsByDatesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchSpaceReservationsByDatesForAdmin']>>>
export type SearchSpacesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['searchSpacesForAdmin']>>>
export type UpdateResourceReservationPaymentStatusForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['updateResourceReservationPaymentStatusForAdmin']>>>
export type UpdateSpaceReservationStatusForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['updateSpaceReservationStatusForAdmin']>>>
export type FetchDropInsByDatesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['fetchDropInsByDatesForAdmin']>>>
