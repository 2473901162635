import { ButtonV2, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  AccommodationPlan,
  BaseId,
  BaseResourceCategory,
  BaseResourceCategoryRelationship,
  CHECK_SPACE_RESERVABLE,
  CheckSpaceReservableRequest,
  CheckSpaceReservableResponse,
  ChildReservationMap,
  SpaceReservation,
  SpaceReservationId
} from '@atomica.co/irori';
import { Count } from '@atomica.co/types';
import { EMPTY, ZERO, builder, isZero, toBeginningOfDay } from '@atomica.co/utils';
import { addDays, differenceInCalendarDays, isBefore, subDays } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useCachedDataForSaveSpaceReservationAutomatically from '../../../redux/hooks/useCachedDataForSaveSpaceReservationAutomatically';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import SpaceConditionForm from '../space-condition-form/SpaceConditionForm';

interface P {
  baseId: BaseId;
  spacePlan: AccommodationPlan | undefined;
  baseResourceCategory: BaseResourceCategory;
  baseResourceCategoryRelationship: BaseResourceCategoryRelationship;
  parentSpaceReservations: SpaceReservation[];
  childReservationMap?: ChildReservationMap | undefined;
  setIsSpacePlanSearching: (isSearching: boolean) => void;
  setReservableSpacePlans: (reservableSpacePlans: AccommodationPlan[]) => void;
  onClick: () => void;
}

const SpaceConditionInput: React.FC<P> = React.memo(props => {
  const {
    baseId,
    spacePlan,
    baseResourceCategory,
    baseResourceCategoryRelationship,
    parentSpaceReservations = [],
    childReservationMap = {},
    setIsSpacePlanSearching,
    setReservableSpacePlans,
    onClick
  } = props;

  const { saveCachedDataForSaveSpaceReservationAutomatically } = useCachedDataForSaveSpaceReservationAutomatically();

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();

  const [selectedParentSpaceReservationId, setSelectedParentSpaceReservationId] = useSafeState<SpaceReservationId>(
    unmountRef,
    EMPTY
  );

  const [selectedStartDate, setSelectedStartDate] = useSafeState<Date | null>(unmountRef, null);
  const [selectedEndDate, setSelectedEndDate] = useSafeState<Date | null>(unmountRef, null);
  const [roomCount, setRoomCount] = useSafeState<Count>(unmountRef, ZERO);

  const checkSpaceReservable = useSafeCallback(async (): Promise<void> => {
    if (!selectedStartDate || !selectedEndDate) return;
    const startDate = toBeginningOfDay(selectedStartDate);
    const endDate = toBeginningOfDay(selectedEndDate);
    if (!startDate || !endDate || startDate === endDate) return;
    setIsSpacePlanSearching(true);
    const request = builder<CheckSpaceReservableRequest>()
      .baseId(baseId)
      .resourceCategoryId(baseResourceCategory.baseResourceCategoryId)
      .startAt(startDate)
      .endAt(endDate)
      .roomCount(roomCount)
      .parentSpaceReservationId(selectedParentSpaceReservationId)
      .build();

    const response = await commonRequest<CheckSpaceReservableRequest, CheckSpaceReservableResponse>(
      CHECK_SPACE_RESERVABLE,
      request
    );

    setReservableSpacePlans(response.reservableSpacePlans);
    setIsSpacePlanSearching(false);
  }, [
    baseResourceCategory.baseResourceCategoryId,
    commonRequest,
    roomCount,
    selectedEndDate,
    selectedParentSpaceReservationId,
    selectedStartDate,
    setIsSpacePlanSearching,
    setReservableSpacePlans
  ]);

  const handleSearchButtonClicked = useSafeCallback(async (): Promise<void> => {
    onClick();
    await checkSpaceReservable();
  }, [checkSpaceReservable, onClick]);

  const handleSelectedEndDate = useSafeCallback(
    (endDate: Date | null): void => {
      if (endDate) {
        setSelectedStartDate(startDate =>
          startDate && differenceInCalendarDays(endDate, startDate) > 0 ? startDate : subDays(endDate, 1)
        );
      }
      setSelectedEndDate(endDate);
    },
    [setSelectedStartDate, setSelectedEndDate]
  );

  const handleSelectedStartDate = useSafeCallback(
    (startDate: Date | null): void => {
      if (startDate) {
        setSelectedEndDate(endDate =>
          endDate && differenceInCalendarDays(endDate, startDate) > 0 ? endDate : addDays(startDate, 1)
        );
      }
      setSelectedStartDate(startDate);
    },
    [setSelectedStartDate]
  );

  useEffect(() => {
    if (!spacePlan) return;
    saveCachedDataForSaveSpaceReservationAutomatically({
      baseResourceCategory,
      spacePlan,
      startAt: selectedStartDate,
      endAt: selectedEndDate,
      roomCount,
      selectedParentSpaceReservationId
    });
  }, [
    baseResourceCategory,
    parentSpaceReservations,
    roomCount,
    saveCachedDataForSaveSpaceReservationAutomatically,
    selectedEndDate,
    selectedParentSpaceReservationId,
    selectedStartDate,
    spacePlan
  ]);

  return (
    <Container>
      <Title>利用条件を入力（1/2）</Title>
      <SpaceConditionForm
        type='search'
        parentSpaceReservations={parentSpaceReservations}
        childReservationMap={childReservationMap}
        baseResourceCategoryLabel={baseResourceCategory.categoryLabel}
        baseResourceCategoryRelationship={baseResourceCategoryRelationship}
        values={{ selectedParentSpaceReservationId, selectedStartDate, selectedEndDate, roomCount }}
        onChanges={{
          setSelectedParentSpaceReservationId,
          setSelectedStartDate: handleSelectedStartDate,
          setSelectedEndDate: handleSelectedEndDate,
          setRoomCount
        }}
      />
      <ButtonV2
        disabled={
          isZero(roomCount) ||
          (!baseResourceCategoryRelationship && !selectedStartDate) ||
          (!baseResourceCategoryRelationship && !selectedEndDate) ||
          (!!selectedStartDate && !!selectedEndDate && isBefore(selectedEndDate, selectedStartDate))
        }
        isFullWidth
        size='large'
        type='tertiary'
        label='空室状況を検索'
        onClick={handleSearchButtonClicked}
      />
    </Container>
  );
});

SpaceConditionInput.displayName = 'SpaceConditionInput';
export default SpaceConditionInput;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;
