import { SpaceManagement } from '@atomica.co/irori';
import { Code, URL } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';

export const toBlobURL = (buffer: Buffer | undefined): URL => {
  if (!buffer) return EMPTY;
  const arrayBuffer = Object.values(buffer);
  const blob = new Blob([Buffer.from(arrayBuffer)], { type: 'image/png' });
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(blob);
};

export const toQrCode = (spaceManagement: SpaceManagement, qrcode: Code | Buffer): Code => {
  switch (spaceManagement) {
    case SpaceManagement.BITKEY:
      return qrcode as Code;
    case SpaceManagement.DENSO:
      return toBlobURL(qrcode as Buffer);
    default:
      throw new Error(`${spaceManagement} is out of target.`);
  }
};
