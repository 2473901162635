import {
  Button,
  Component,
  DateBox,
  PageHeaderV2,
  RadioBox,
  theme,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Access,
  BaseDto,
  BaseId,
  ContractUsageV2,
  ContractV2,
  EventParticipant,
  FETCH_ACCESSES_BY_DATES_FOR_ADMIN,
  FETCH_CONTRACTS_BY_DATES_FOR_ADMIN,
  FETCH_CONTRACT_USAGES_BY_DATES_FOR_ADMIN,
  FETCH_SHOT_USAGES_BY_DATES_FOR_ADMIN,
  FETCH_SPACE_RESERVATIONS_BY_DATES_FOR_ADMIN,
  FETCH_USERS_BY_DATES_FOR_ADMIN,
  FetchAccessesByDatesForAdminRequest,
  FetchAccessesByDatesForAdminResponse,
  FetchContractUsagesByDatesForAdminRequest,
  FetchContractUsagesByDatesForAdminResponse,
  FetchContractsByDatesForAdminRequest,
  FetchContractsByDatesForAdminResponse,
  FetchShotUsagesByDatesForAdminRequest,
  FetchShotUsagesByDatesForAdminResponse,
  FetchSpaceReservationsByDatesForAdminRequest,
  FetchSpaceReservationsByDatesForAdminResponse,
  FetchUsersByDatesForAdminRequest,
  FetchUsersByDatesForAdminResponse,
  Shot,
  SpaceReservation,
  User,
  WithBaseId
} from '@atomica.co/irori';
import { hasLength, toBeginningOfDay, toEndOfDay } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useRef } from 'react';
import { CSVLink } from 'react-csv';
import type Link from 'react-csv/components/Link';
import { Headers } from 'react-csv/lib/core';
import styled from 'styled-components';
import { getBsSpace } from '../../__generated/admin/bs-space/bs-space';
import { FetchDropInsByDatesForAdminParams } from '../../__generated/model';
import {
  DropInData,
  UserCSV,
  convertToAccessesCSV,
  convertToContractUsagesCSV,
  convertToContractsCSV,
  convertToDropInData,
  convertToShotCSV,
  convertToSpaceReservationsCSV,
  convertToUserCSV
} from '../../converters/export-converter';
import { ExportTarget } from '../../enums/export-enum';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import {
  ACCESS_HEADERS,
  CONTRACT_HEADERS,
  CONTRACT_USAGE_HEADERS,
  DROP_IN_HEADERS,
  EXPORT_LABELS,
  SHOT_HEADERS,
  SPACE_RESERVATION_PAYMENTS_HEADERS,
  SPACE_RESERVATION_WITH_IS_CONTRACT_USER_HEADERS,
  USERS_HEADERS
} from '../../texts/export-text';

type EXPORT_TARGETS =
  | SpaceReservation
  | Shot
  | ContractV2
  | ContractUsageV2
  | Access
  | EventParticipant
  | UserCSV
  | DropInData;

interface P {
  base: BaseDto;
  user: User;
}

const ExportScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const csvInstance = useRef<Link & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [disabledExportButton, setDisabledExportButton] = useSafeState<boolean>(unmountRef, true);
  const [exportTarget, setExportTarget] = useSafeState<ExportTarget>(unmountRef, ExportTarget.DROP_IN);
  const [startDate, setStartDate] = useSafeState<Date | undefined>(unmountRef, toBeginningOfDay(new Date()));
  const [endDate, setEndDate] = useSafeState<Date | undefined>(unmountRef, toEndOfDay(new Date()));
  const [csvHeaders, setCsvHeaders] = useSafeState<Headers>(unmountRef, []);
  const [csvContent, setCsvContent] = useSafeState<
    | Shot[]
    | ContractV2[]
    | ContractUsageV2[]
    | Access[]
    | EventParticipant[]
    | SpaceReservation[]
    | UserCSV[]
    | DropInData[]
  >(unmountRef, []);

  useEffect(() => {
    setDisabledExportButton(!startDate || !endDate);
  }, [setDisabledExportButton, startDate, endDate]);

  const request = useMemo<{ baseId: BaseId; start: Date; end: Date } | undefined>(() => {
    if (!startDate || !endDate) return undefined;
    return {
      baseId: base.baseId,
      start: startDate,
      end: endDate
    };
  }, [base.baseId, endDate, startDate]);

  const exportDropIns = useSafeCallback(
    async (request: WithBaseId<FetchDropInsByDatesForAdminParams>): Promise<void> => {
      const { baseId, start, end } = request;
      const { data } = await getBsSpace().fetchDropInsByDatesForAdmin(baseId, { start, end });
      const content = convertToDropInData(data);
      setCsvHeaders(DROP_IN_HEADERS);
      setCsvContent(content);
    },
    [setCsvContent, setCsvHeaders]
  );

  const exportShotUsages = useSafeCallback(
    async (request: FetchShotUsagesByDatesForAdminRequest): Promise<void> => {
      const response = await commonRequest<
        FetchShotUsagesByDatesForAdminRequest,
        FetchShotUsagesByDatesForAdminResponse
      >(FETCH_SHOT_USAGES_BY_DATES_FOR_ADMIN, request);

      setCsvHeaders(SHOT_HEADERS);
      setCsvContent(convertToShotCSV(response.shots, base));
    },
    [base, commonRequest, setCsvContent, setCsvHeaders]
  );

  const exportConstacts = useSafeCallback(
    async (request: FetchContractsByDatesForAdminRequest): Promise<void> => {
      const response = await commonRequest<FetchContractsByDatesForAdminRequest, FetchContractsByDatesForAdminResponse>(
        FETCH_CONTRACTS_BY_DATES_FOR_ADMIN,
        request
      );
      setCsvHeaders(CONTRACT_HEADERS);
      setCsvContent(convertToContractsCSV(response.contracts, base));
    },
    [base, commonRequest, setCsvContent, setCsvHeaders]
  );

  const exportConstactUsages = useSafeCallback(
    async (request: FetchContractUsagesByDatesForAdminRequest): Promise<void> => {
      const response = await commonRequest<
        FetchContractUsagesByDatesForAdminRequest,
        FetchContractUsagesByDatesForAdminResponse
      >(FETCH_CONTRACT_USAGES_BY_DATES_FOR_ADMIN, request);
      setCsvHeaders(CONTRACT_USAGE_HEADERS);
      setCsvContent(convertToContractUsagesCSV(response.usages, base));
    },
    [base, commonRequest, setCsvContent, setCsvHeaders]
  );

  const exportAccesses = useSafeCallback(
    async (request: FetchAccessesByDatesForAdminRequest): Promise<void> => {
      const response = await commonRequest<FetchAccessesByDatesForAdminRequest, FetchAccessesByDatesForAdminResponse>(
        FETCH_ACCESSES_BY_DATES_FOR_ADMIN,
        request
      );
      setCsvHeaders(ACCESS_HEADERS);
      setCsvContent(convertToAccessesCSV(response.accesses, base));
    },
    [base, commonRequest, setCsvHeaders, setCsvContent]
  );

  const exportSpaceReservations = useSafeCallback(
    async (request: FetchSpaceReservationsByDatesForAdminRequest): Promise<void> => {
      const response = await commonRequest<
        FetchSpaceReservationsByDatesForAdminRequest,
        FetchSpaceReservationsByDatesForAdminResponse
      >(FETCH_SPACE_RESERVATIONS_BY_DATES_FOR_ADMIN, request);
      request.isPaymentIncluded
        ? setCsvHeaders(SPACE_RESERVATION_PAYMENTS_HEADERS)
        : setCsvHeaders(SPACE_RESERVATION_WITH_IS_CONTRACT_USER_HEADERS);
      setCsvContent(convertToSpaceReservationsCSV(response.spaceReservations, request.isPaymentIncluded));
    },
    [commonRequest, setCsvContent, setCsvHeaders]
  );

  const exportUsers = useSafeCallback(
    async (request: FetchUsersByDatesForAdminRequest): Promise<void> => {
      const response = await commonRequest<FetchUsersByDatesForAdminRequest, FetchUsersByDatesForAdminResponse>(
        FETCH_USERS_BY_DATES_FOR_ADMIN,
        request
      );
      setCsvHeaders(USERS_HEADERS);
      setCsvContent(convertToUserCSV(response.users, base));
    },
    [base, commonRequest, setCsvContent, setCsvHeaders]
  );

  const exportCSV = useSafeCallback(async (): Promise<void> => {
    if (!request) return;
    setDisabledExportButton(true);

    const exportCSVFunctions: Record<ExportTarget, () => Promise<void>> = {
      [ExportTarget.DROP_IN]: () => exportDropIns(request),
      [ExportTarget.SHOT_USAGE]: () => exportShotUsages(request),
      [ExportTarget.CONTRACT]: () => exportConstacts(request),
      [ExportTarget.CONTRACT_USAGE]: () => exportConstactUsages(request),
      [ExportTarget.ACCESS]: () => exportAccesses(request),
      [ExportTarget.SPACE_RESERVATION]: () => exportSpaceReservations({ ...request, isPaymentIncluded: false }),
      [ExportTarget.USER]: () => exportUsers(request),
      [ExportTarget.SPACE_RESERVATION_PAYMENT]: () => exportSpaceReservations({ ...request, isPaymentIncluded: true })
    };

    const exportCSV = exportCSVFunctions[exportTarget];

    if (!exportCSV || typeof exportCSV !== 'function') {
      throw new Error(`${exportTarget} is out of target.`);
    }

    await exportCSV();

    csvInstance.current?.link.click();

    setCsvContent([]);
    setDisabledExportButton(false);
  }, [
    request,
    setDisabledExportButton,
    exportTarget,
    setCsvContent,
    exportDropIns,
    exportShotUsages,
    exportConstacts,
    exportConstactUsages,
    exportAccesses,
    exportSpaceReservations,
    exportUsers
  ]);

  const handleStartDateChanged = useSafeCallback(
    (date: Date) => {
      if (!date) return;
      setStartDate(toBeginningOfDay(date));
    },
    [setStartDate]
  );

  const handleEndDateChanged = useSafeCallback(
    (date: Date) => {
      if (!date) return;
      setEndDate(toEndOfDay(date)!);
    },
    [setEndDate]
  );

  return (
    <Component className='export-screen'>
      <Container data-testid='export-screen'>
        <Content>
          <PageHeaderWrapper>
            <PageHeaderV2 title='データ出力' />
          </PageHeaderWrapper>

          <RadioWrapper>
            <RadioBox
              title='対象'
              options={Object.values(ExportTarget)}
              labels={EXPORT_LABELS}
              value={exportTarget}
              onChange={setExportTarget}
            />
          </RadioWrapper>

          <DateWrapper>
            <PartialDateWrapper>
              <DateBox label='開始日' value={startDate ?? null} onChange={handleStartDateChanged} />
            </PartialDateWrapper>

            <PartialDateWrapper>
              <DateBox label='終了日' value={endDate ?? null} onChange={handleEndDateChanged} />
            </PartialDateWrapper>
          </DateWrapper>

          <ButtonWrapper>
            <Button type='primary' disabled={disabledExportButton} onClick={exportCSV}>
              <Label>CSV出力</Label>
            </Button>

            {hasLength<EXPORT_TARGETS>(csvContent) && (
              <CSVLink ref={csvInstance} headers={csvHeaders} data={csvContent} />
            )}
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

ExportScreen.displayName = 'ExportScreen';
export default ExportScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const PageHeaderWrapper = styled.div`
  margin: ${themeV2.mixins.v2.spacing * 2}px;
`;

const RadioWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const DateWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const PartialDateWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing / 2}px);
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const Label = styled(Typography)`
  width: 160px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
