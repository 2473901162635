import {
  ButtonV2,
  CircularLoader,
  QRCode,
  ReactUseGestureEventHandlers,
  SnackbarStatus,
  customMedia,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BASE_CODE,
  BaseId,
  KeyOperation,
  KeyOperationResult,
  KeyOperationStatus,
  OPERATE_SPACE_KEY,
  OperateSpaceKeyRequest,
  OperateSpaceKeyResponse,
  SHARED_SPACE_RESERVATION_END_AT,
  SHARED_SPACE_RESERVATION_ID,
  SHARED_SPACE_RESERVATION_START_AT,
  SpaceCategory,
  SpaceManagement,
  constructExternalLink
} from '@atomica.co/irori';
import { Height, Id, Label, Message, Milliseconds, URL, UserId } from '@atomica.co/types';
import { builder, copyText, embedIdInPath, toFormattedDateTimeDurationStr } from '@atomica.co/utils';
import { Card, CardMedia } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { animated } from '@react-spring/web';
import { isAfter, subMinutes } from 'date-fns';
import format from 'date-fns/format';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { FetchResourceUsages200CardsItem } from '../../../../__generated/model';
import IconShare from '../../../../assets/icon/icon_share.svg';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../../constants/common-const';
import { ERROR, SUCCESS } from '../../../../constants/snackbar-const';
import { toBlobURL } from '../../../../converters/account-converter';
import env from '../../../../env/env';
import { useSnackbarV2 } from '../../../../provider/SnackbarProviderV2';
import usePath from '../../../../redux/hooks/usePath';
import CommonRequest from '../../../../requests/common-request';
import { Path } from '../../../../router/Routes';
import { FAILED_TO_OPEN_KEY, INVITATION_LINK_COPIED, OPEN_KEY_SUCCESSFULLY } from '../../../../texts/snackbar-text';
import { dueTimeToStr } from '../../../../utils/space-reservation-util';

const SHOW_SNACK_BAR_TIME_MS = 10000;

interface P {
  fronting: boolean;
  baseId: BaseId;
  userId: UserId;
  lineLiffId: Id | undefined;
  card: FetchResourceUsages200CardsItem;
  setCardHeight: (height: Height) => void;
  bind: (id: Id) => ReactUseGestureEventHandlers;
}

interface SnackbarParameter {
  message: Message;
  status: SnackbarStatus;
  autoHideDuration?: Milliseconds;
}

const SpaceReservationCardV2: React.FC<P> = React.memo(props => {
  const { baseId, lineLiffId, userId, card, setCardHeight, bind } = props;
  const {
    cardId,
    spaceReservationId,
    spaceId,
    spaceName,
    photoUrl,
    spaceCategory,
    startAt,
    endAt,
    qrCodeText,
    spaceManagement,
    permittedEntryMinutes,
    checkoutAt,
    isCapacityOver,
    spacePlanNames
  } = card;
  const { openBasePathWithQueryParams, params } = usePath();
  const { openSnackbar } = useSnackbarV2();
  const unmountRef = useUnmountRef();
  const [opening, setOpening] = useSafeState<boolean>(unmountRef, false);
  const [showComponentForUnlocking, setShowComponentForUnlocking] = useSafeState<boolean>(unmountRef, false);
  const [isExpired, setIsExpired] = useSafeState<boolean>(unmountRef, false);
  const [reservedSnackbarParameter, setReservedSnackbarParameter] = useSafeState<SnackbarParameter>(unmountRef);

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const adjustedStartAt = startAt ? subMinutes(new Date(startAt), permittedEntryMinutes ?? 0) : undefined;
      const now = new Date();

      const isExpired = (endAt && isAfter(now, endAt)) || !!checkoutAt;
      setIsExpired(isExpired);

      const showOpenButtonToUpdate =
        spaceManagement === SpaceManagement.BITKEY && (!adjustedStartAt || adjustedStartAt <= now) && !isExpired;
      setShowComponentForUnlocking(showOpenButtonToUpdate);
    }, 1000);

    return () => clearInterval(interval);
  }, [checkoutAt, endAt, permittedEntryMinutes, setIsExpired, setShowComponentForUnlocking, spaceManagement, startAt]);

  const qrCode = useMemo<ReactNode>(() => {
    if (!qrCodeText) return <></>;
    switch (spaceManagement) {
      case SpaceManagement.DENSO: {
        return <QrCode src={toBlobURL(qrCodeText as unknown as Buffer)} />;
      }
      case SpaceManagement.BITKEY:
        return <QRCode size={120} color='white' url={qrCodeText} />;
      default:
        return <></>;
    }
  }, [spaceManagement, qrCodeText]);

  const usageTimeLabel = useMemo<Label | undefined>(() => {
    if (spaceCategory === SpaceCategory.ENTRANCE) return;
    if (!startAt && !endAt) return '指定なし';
    return !!startAt && !!endAt ? toFormattedDateTimeDurationStr(startAt, endAt, 'HH:mm', '~') : dueTimeToStr(endAt);
  }, [spaceCategory, startAt, endAt]);

  const reservationURLToShare = useMemo<URL | undefined>(() => {
    const isConference = spaceCategory === SpaceCategory.CONFERENCE;

    if (!isConference || isCapacityOver || !spaceReservationId || !startAt || !endAt) return undefined;

    const formattedStartAt = format(startAt, 'yyyy/MM/dd HH:mm:ss');
    const formattedEndAt = format(endAt, 'yyyy/MM/dd HH:mm:ss');

    const queryParamOfSpaceReservationId = `${SHARED_SPACE_RESERVATION_ID}=${spaceReservationId}`;
    const queryParamOfStartAt = `${SHARED_SPACE_RESERVATION_START_AT}=${formattedStartAt}`;
    const queryParamOfEndAt = `${SHARED_SPACE_RESERVATION_END_AT}=${formattedEndAt}`;

    const pathToOpen = `${embedIdInPath(
      Path.ACCOUNT_SHARE,
      [BASE_CODE],
      [params[BASE_CODE]]
    )}?${queryParamOfSpaceReservationId}&${queryParamOfStartAt}&${queryParamOfEndAt}`;

    return constructExternalLink(env, pathToOpen, lineLiffId);
  }, [isCapacityOver, lineLiffId, params, endAt, startAt, spaceCategory, spaceReservationId]);

  const handleShareClicked = useSafeCallback(async (): Promise<void> => {
    if (navigator.share) {
      await navigator.share({ url: reservationURLToShare });
      return;
    }

    copyText(reservationURLToShare!);
    openSnackbar(INVITATION_LINK_COPIED, SUCCESS, SHOW_SNACK_BAR_TIME_MS);
  }, [reservationURLToShare, openSnackbar]);

  const reserveSnackbar = useSafeCallback(
    (message: Message, status: SnackbarStatus, autoHideDuration: Milliseconds) =>
      setReservedSnackbarParameter(
        builder<SnackbarParameter>().message(message).status(status).autoHideDuration(autoHideDuration).build()
      ),
    [setReservedSnackbarParameter]
  );

  const openDropInScreen = useSafeCallback((): void => {
    openBasePathWithQueryParams(Path.DROP_IN, { dropInId: spaceReservationId });
  }, [openBasePathWithQueryParams, spaceReservationId]);

  useEffect(() => {
    if (reservedSnackbarParameter) {
      const { message, status, autoHideDuration } = reservedSnackbarParameter;
      openSnackbar(message, status, autoHideDuration);
      setReservedSnackbarParameter(undefined);
    }
  }, [reservedSnackbarParameter, setReservedSnackbarParameter, openSnackbar]);

  const openSpaceKey = useSafeCallback(async (): Promise<void> => {
    setOpening(true);

    const request = builder<OperateSpaceKeyRequest>()
      .baseId(baseId)
      .spaceId(spaceId)
      .operation(KeyOperation.UNLOCK)
      .userId(userId)
      .build();
    const response = await CommonRequest.call<OperateSpaceKeyRequest, OperateSpaceKeyResponse>(
      OPERATE_SPACE_KEY,
      request
    );

    if (!response || response.result === KeyOperationResult.FAIL) {
      reserveSnackbar(FAILED_TO_OPEN_KEY, ERROR, SHOW_SNACK_BAR_TIME_MS);
    } else if (response.status === KeyOperationStatus.OPEN || KeyOperationStatus.UNKNOWN) {
      reserveSnackbar(OPEN_KEY_SUCCESSFULLY, SUCCESS, SHOW_SNACK_BAR_TIME_MS);
    }

    setOpening(false);
  }, [setOpening, baseId, spaceId, userId, reserveSnackbar]);

  useEffect(() => {
    if (!cardRef.current) return;
    setCardHeight(cardRef.current.clientHeight);
  }, [cardRef, setCardHeight]);

  return (
    <Container {...bind(cardId)} ref={cardRef}>
      <CardWrapper>
        <CustomCard data-testid='qr-card'>
          <CustomCardMedia image={photoUrl} />
          <CardContent>
            <InfoWrapper>
              <CardRow>
                <InfoLabel>利用スペース</InfoLabel>
                <InfoText>{spaceName}</InfoText>
              </CardRow>
              {usageTimeLabel && (
                <CardRow>
                  <InfoTimeLabel>日時</InfoTimeLabel>
                  <div>
                    <InfoTimeText>
                      {usageTimeLabel}
                      {spaceCategory === SpaceCategory.FREE_SPACE && (
                        <StyledButton type='secondary' label='延長する' onClick={openDropInScreen} />
                      )}
                    </InfoTimeText>
                    <InfoText>{spacePlanNames.join(',')}</InfoText>
                  </div>
                </CardRow>
              )}
            </InfoWrapper>
          </CardContent>
          {isExpired && (
            <ComponentArea>
              <Border />
              <InfoText>利用時間は終了しました。</InfoText>
            </ComponentArea>
          )}
          {!isExpired && (
            <ComponentArea>
              {showComponentForUnlocking && <QrCodeArea>{qrCode}</QrCodeArea>}
              <ButtonsWrapper>
                {showComponentForUnlocking && (
                  <>
                    {opening ? (
                      <ButtonArea>
                        <CircularLoader />
                      </ButtonArea>
                    ) : (
                      <ButtonV2
                        startIcon={<StyledLockOpenIcon />}
                        type='primary'
                        size='large'
                        label='解錠する'
                        onClick={openSpaceKey}
                      />
                    )}
                  </>
                )}

                {reservationURLToShare && (
                  <ButtonArea onClick={handleShareClicked}>
                    <CustomIconShare src={IconShare} />
                    <ButtonLabel>招待する</ButtonLabel>
                  </ButtonArea>
                )}
              </ButtonsWrapper>
            </ComponentArea>
          )}
        </CustomCard>
      </CardWrapper>
    </Container>
  );
});

SpaceReservationCardV2.displayName = 'SpaceReservationCardV2';
export default SpaceReservationCardV2;

const Container = styled(animated.div)`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  height: auto;
`;

const CardWrapper = styled.div`
  width: 80%;
  position: relative;
  color: white;
  margin: 0 auto;
`;

const CustomCard = styled(Card)`
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  user-select: none;
`;

const CustomCardMedia = styled(CardMedia).attrs(() => ({ component: 'img' }))`
  height: 18dvh;
  background: ${themeV2.mixins.v2.color.background.lightGray};
  -webkit-user-drag: none;
  ${customMedia.greaterThan('tiny')`
    height: 160px;
  `}
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  margin: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;

const CardRow = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const BaseLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  min-width: 60px;
`;

const InfoLabel = styled(BaseLabel)``;

const InfoTimeLabel = styled(BaseLabel)`
  height: 32px;
  display: flex;
  align-items: center;
`;

const BaseText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const InfoText = styled(BaseText)``;

const InfoTimeText = styled(BaseText)`
  display: flex;
  align-items: center;
`;

const ComponentArea = styled.div`
  padding: 0 ${themeV3.mixins.v3.spacing * 3}px ${themeV3.mixins.v3.spacing * 3}px;
`;

const Border = styled.hr`
  border: none;
  border-top: 1px dashed ${themeV3.mixins.v3.color.border.gray};
`;

const QrCodeArea = styled.div`
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const QrCode = styled.img`
  width: 120px;
  height: 120px;
  -webkit-user-drag: none;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 24px;
  display: flex;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  margin-inline: auto;
`;

const ButtonArea = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  justify-content: center;
  align-items: center;
  padding-block: ${themeV2.mixins.v2.spacing}px;
`;

const StyledLockOpenIcon = styled(LockOpenIcon)`
  width: 24px;
  height: 24px;
  -webkit-user-drag: none;
`;

const CustomIconShare = styled.img`
  width: 24px;
  height: 24px;
  color: ${themeV2.mixins.v2.color.font.gray};
  margin-bottom: ${themeV2.mixins.v2.spacing / 2}px;
  -webkit-user-drag: none;
`;

const ButtonLabel = styled.div`
  color: ${themeV3.mixins.v3.color.object.gray};
  ${themeV3.mixins.v3.typography.label.large};
`;

const StyledButton = styled(ButtonV2)`
  && {
    margin-left: 8px;
  }
`;
