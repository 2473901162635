import {
  ButtonV2,
  CardDeckRef,
  Component,
  Tips,
  customMedia,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, User } from '@atomica.co/irori';
import { Width } from '@atomica.co/types';
import { hasLength } from '@atomica.co/utils';
import React, { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FetchResourceUsages200CardsItem } from '../../../__generated/model';
import { getBsSpace } from '../../../__generated/user/bs-space/bs-space';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { isInLineClient } from '../../../line';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import ReservationCards from './reservation-card/ReservationCards';

const TIPS_MESSAGE =
  '本日の予定はありません。\n設備の予約をするか\n設備の予約の招待を受け取ると\n本日の予定が表示されます。';

interface P {
  base: BaseDto;
  user: User;
}

const AccountHome: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath } = usePath();
  const ref = useRef<CardDeckRef>(null);
  const unmountRef = useUnmountRef();
  const [cards, setCards] = useSafeState<FetchResourceUsages200CardsItem[]>(unmountRef, []);
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);

  const fetchResourceUsages = useSafeCallback(async (): Promise<void> => {
    const { data } = await getBsSpace().fetchResourceUsages(base.baseId, user.userId);
    setCards(data.cards);
    setLoaded(true);
  }, [base.baseId, setCards, setLoaded, user.userId]);

  useEffect(() => {
    fetchResourceUsages();
  }, [fetchResourceUsages]);

  const openAccountMySchedulesScreen = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_MY_SCHEDULES);
  }, [openBasePath]);

  return (
    <Component loading={!loaded} style={styleForComponent} className='account-home' title='ホーム'>
      <Container data-testid='account-home'>
        <Content>
          {!hasLength(cards) && (
            <>
              <NoReservationWrapper>
                <Tips message={TIPS_MESSAGE} />
              </NoReservationWrapper>
              <CenteredWrapper>
                <StyledButton
                  width={80}
                  size='large'
                  type='secondary'
                  label='すべての予定を確認'
                  onClick={openAccountMySchedulesScreen}
                />
              </CenteredWrapper>
            </>
          )}
          {hasLength(cards) && (
            <>
              <LabelWrapper>
                <Label>本日の予定</Label>
                <StyledButton
                  width={40}
                  size='small'
                  type='tertiary'
                  label='すべての予定を確認'
                  onClick={openAccountMySchedulesScreen}
                />
              </LabelWrapper>
              <ReservationsWrapper>
                <ReservationCards
                  ref={ref}
                  baseId={base.baseId}
                  userId={user.userId}
                  cards={cards}
                  lineLiffId={base.lineLiffId}
                />
              </ReservationsWrapper>
            </>
          )}
        </Content>
      </Container>
    </Component>
  );
});

AccountHome.displayName = 'AccountHome';
export default AccountHome;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100%;
  height: ${isInLineClient() ? '100%' : 'calc(100% - 100px)'};
  max-width: 1124px;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  ${customMedia.lessThan('tiny')`
    padding: ${themeV2.mixins.v2.spacing}px 0 ;
  `};
`;

const Content = styled.div`
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  ${customMedia.lessThan('tiny')`
    gap: ${themeV2.mixins.v2.spacing}px;
  `};
`;

const LabelWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
`;

const Label = styled.div`
  ${themeV2.mixins.v2.typography.title.medium}
`;

const ReservationsWrapper = styled.div`
  width: 100%;
  flex: 1;
  margin: 0 auto;
  top: 120px;
  left: 0;
`;

const NoReservationWrapper = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const StyledButton = styled(ButtonV2)<{ width: Width }>`
  && {
    width: ${({ width }) => `${width}%`};
    white-space: nowrap;
  }
`;
