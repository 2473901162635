import {
  CheckBoxV2,
  CircularLoader,
  Component,
  PageHeaderV2,
  Pagenate,
  TabProperty,
  TabsV3,
  customBreakpoints,
  customMedia,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  EventScheduleIdV2,
  EventScheduleV2,
  SEARCH_EVENT_SCHEDULES_V2,
  SearchEventSchedulesV2Request,
  SearchEventSchedulesV2Response,
  User
} from '@atomica.co/irori';
import { Count, Index, Limit, Offset } from '@atomica.co/types';
import { EMPTY, MINUS_ONE, ONE, ZERO, builder, embedIdInPath, hasLength } from '@atomica.co/utils';
import { Button } from '@material-ui/core';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import { CSSProperties } from '@material-ui/styles';
import React, { useEffect, useMemo, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { AccountEventTabEnum } from '../../../enums/event-v2-enum';
import usePath from '../../../redux/hooks/usePath';
import CommonRequest from '../../../requests/common-request';
import { PATH_IDS, Path } from '../../../router/Routes';
import { ACCOUNT_EVENT_TAB_LABELS } from '../../../texts/event-text';
import AccountEventCard from './AccountEventCard';

const PC_LIMIT = 5;
const MOBILE_LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  base: BaseDto;
  user: User;
}

const AccountEventList: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { path, openPath } = usePath();

  const unmountRef = useUnmountRef();
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<AccountEventTabEnum>(
    unmountRef,
    AccountEventTabEnum.SCHEDULED
  );
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [eventSchedules, setEventSchedules] = useSafeState<EventScheduleV2[]>(unmountRef, []);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [isFilteringInterestedEvent, setIsFilteringInterestedEvent] = useSafeState<boolean>(unmountRef, false);
  const [isFilteringAnsweredEvent, setIsFilteringAnsweredEvent] = useSafeState<boolean>(unmountRef, false);
  const [hasUnansweredPreQuestionnaire, setHasUnansweredPreQuestionnaire] = useSafeState<boolean>(unmountRef, false);
  const [hasUnansweredPostQuestionnaire, setHasUnansweredPostQuestionnaire] = useSafeState<boolean>(unmountRef, false);

  const offset = useRef<Offset>(ZERO);
  const hasMore = useRef<boolean>(true);
  const bottomRef = useRef<HTMLDivElement>(null);

  const findUnansweredQuestionnaire = useSafeCallback(
    (schedule: EventScheduleV2, preOrPost: 'pre' | 'post'): boolean => {
      const participants = schedule.participants;
      const myself = participants?.find(participant => participant.user?.userId === user.userId);
      if (!myself) return false;
      switch (preOrPost) {
        case 'pre':
          return !!schedule.preQuestionnaireURL && !myself.isAnsweredPreQuestionnaire;
        case 'post':
          return !!schedule.postQuestionnaireURL && !myself.isAnsweredPostQuestionnaire;
        default:
          return false;
      }
    },
    [user]
  );

  const hasUnansweredQuestionnaire = useMemo<boolean>(() => {
    switch (selectedTabIdx) {
      case AccountEventTabEnum.ATTENDING:
        return hasUnansweredPreQuestionnaire;
      case AccountEventTabEnum.ATTENDED:
        return hasUnansweredPostQuestionnaire;
      default:
        return false;
    }
  }, [hasUnansweredPreQuestionnaire, hasUnansweredPostQuestionnaire, selectedTabIdx]);

  const isMobile = useMediaQuery({ query: `(max-width: ${customBreakpoints.small})` });
  const limit = useMemo<Limit>(() => (isMobile ? MOBILE_LIMIT : PC_LIMIT), [isMobile]);

  const tabProperties = useMemo<TabProperty[]>(
    () => [
      {
        label: ACCOUNT_EVENT_TAB_LABELS[AccountEventTabEnum.SCHEDULED],
        disabled: false
      },
      {
        label: ACCOUNT_EVENT_TAB_LABELS[AccountEventTabEnum.ATTENDING],
        disabled: !user
      },
      {
        label: ACCOUNT_EVENT_TAB_LABELS[AccountEventTabEnum.ATTENDED],
        disabled: !user
      }
    ],
    [user]
  );

  const createRequest = useSafeCallback(
    (updatedOffset: Offset): SearchEventSchedulesV2Request | undefined => {
      switch (selectedTabIdx) {
        case AccountEventTabEnum.ATTENDING:
          if (!user) return;
          if (!isFilteringAnsweredEvent) {
            return builder<SearchEventSchedulesV2Request>()
              .baseId(base.baseId)
              .limit(limit)
              .offset(updatedOffset)
              .word(EMPTY)
              .userId(user.userId)
              .isHeld(false)
              .isApplied(true)
              .build();
          }
          return builder<SearchEventSchedulesV2Request>()
            .baseId(base.baseId)
            .limit(limit)
            .offset(updatedOffset)
            .word(EMPTY)
            .isHeld(false)
            .userId(user.userId)
            .isApplied(true)
            .isAnsweredPreQuestionnaire(false)
            .build();
        case AccountEventTabEnum.ATTENDED:
          if (!user) return;
          if (!isFilteringAnsweredEvent) {
            return builder<SearchEventSchedulesV2Request>()
              .baseId(base.baseId)
              .limit(limit)
              .offset(updatedOffset)
              .word(EMPTY)
              .userId(user.userId)
              .isHeld(true)
              .isApplied(true)
              .build();
          }
          return builder<SearchEventSchedulesV2Request>()
            .baseId(base.baseId)
            .limit(limit)
            .offset(updatedOffset)
            .word(EMPTY)
            .isHeld(true)
            .userId(user.userId)
            .isApplied(true)
            .isAnsweredPostQuestionnaire(false)
            .build();
        case AccountEventTabEnum.SCHEDULED:
        default: {
          if (!isFilteringInterestedEvent) {
            return builder<SearchEventSchedulesV2Request>()
              .baseId(base.baseId)
              .limit(limit)
              .offset(updatedOffset)
              .word(EMPTY)
              .isHeld(false)
              .build();
          }

          return builder<SearchEventSchedulesV2Request>()
            .baseId(base.baseId)
            .limit(limit)
            .offset(updatedOffset)
            .word(EMPTY)
            .isHeld(false)
            .userId(user.userId)
            .isInterested(true)
            .build();
        }
      }
    },
    [base, isFilteringAnsweredEvent, isFilteringInterestedEvent, offset, selectedTabIdx, user, limit]
  );

  const searchEventSchedules = useSafeCallback(
    async (addOffset: Offset): Promise<EventScheduleV2[]> => {
      if (!hasMore) return [];
      setIsLoaderShown(true);

      const updatedOffset = offset.current + addOffset;
      const request = createRequest(updatedOffset);
      if (!request) {
        setIsLoaderShown(false);
        return [];
      }

      const { eventScheduleV2, totalCount } = await CommonRequest.call<
        SearchEventSchedulesV2Request,
        SearchEventSchedulesV2Response
      >(SEARCH_EVENT_SCHEDULES_V2, request);

      offset.current = updatedOffset;
      hasMore.current = eventScheduleV2.length === limit;

      setTotalCount(totalCount);
      setIsLoaderShown(false);

      return eventScheduleV2;
    },
    [hasMore, createRequest, setIsLoaderShown, setTotalCount, limit]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const schedules = await searchEventSchedules(ZERO);
    setEventSchedules(schedules);
  }, [searchEventSchedules, setEventSchedules]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!user) return;
    setHasUnansweredPreQuestionnaire(eventSchedules.some(schedule => findUnansweredQuestionnaire(schedule, 'pre')));
    setHasUnansweredPostQuestionnaire(eventSchedules.some(schedule => findUnansweredQuestionnaire(schedule, 'post')));
  }, [
    eventSchedules,
    user,
    findUnansweredQuestionnaire,
    setHasUnansweredPreQuestionnaire,
    setHasUnansweredPostQuestionnaire
  ]);

  const handleTabSelected = useSafeCallback(
    (nextSelectedTabIdx: Index): void => {
      if (nextSelectedTabIdx == selectedTabIdx) return;
      setSelectedTabIdx(nextSelectedTabIdx);
      setEventSchedules([]);
      setIsFilteringInterestedEvent(false);
      setIsFilteringAnsweredEvent(false);
      offset.current = ZERO;
      hasMore.current = true;
    },
    [selectedTabIdx, setIsFilteringInterestedEvent, setSelectedTabIdx, setEventSchedules]
  );

  const handleCheckboxChanged = useSafeCallback((): void => {
    setEventSchedules([]);
    setIsFilteringInterestedEvent(!isFilteringInterestedEvent);
    offset.current = ZERO;
    hasMore.current = true;
  }, [isFilteringInterestedEvent, setIsFilteringInterestedEvent, setEventSchedules]);

  const handleFilterButtonClicked = useSafeCallback((): void => {
    setIsFilteringAnsweredEvent(!isFilteringAnsweredEvent);
    offset.current = ZERO;
    hasMore.current = true;
  }, [isFilteringAnsweredEvent, setIsFilteringAnsweredEvent]);

  const openAccountEventDetailScreen = useSafeCallback(
    (eventScheduleId: EventScheduleIdV2): void => {
      openPath(embedIdInPath(Path.ACCOUNT_EVENT_DEATIL, PATH_IDS, [base.baseCode, eventScheduleId]));
    },
    [base, openPath]
  );

  const onScroll = useSafeCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        const scheduleToAdd = await searchEventSchedules(ONE);
        setEventSchedules(prevSchedules => [...prevSchedules, ...scheduleToAdd]);
      }
    },
    [searchEventSchedules, setEventSchedules]
  );

  useEffect(() => {
    if (isLoaderShown || !hasMore.current || !isMobile) return;
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    bottomRef.current && observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, [isLoaderShown, onScroll, isMobile]);

  const handlePageBack = useSafeCallback(async (): Promise<void> => {
    setEventSchedules([]);
    const schedule = await searchEventSchedules(MINUS_ONE);
    setEventSchedules(schedule);
  }, [searchEventSchedules]);

  const handlePageForward = useSafeCallback(async (): Promise<void> => {
    setEventSchedules([]);
    const schedule = await searchEventSchedules(ONE);
    setEventSchedules(schedule);
  }, [searchEventSchedules]);

  useEffect(() => {
    if (user && path === Path.ACCOUNT_EVENTS_NO_GUARDED)
      openPath(embedIdInPath(Path.ACCOUNT_EVENTS, PATH_IDS, [base.baseCode]));
  }, [base, path, user, openPath]);

  return (
    <Component style={styleForComponent} className='account-events' title='knotPLACE -イベントスケジュール-'>
      <Container>
        <ScrollArea>
          <Content>
            <PageHeaderV2 title='イベント' titleSize={isMobile ? 'small' : 'default'} />
            <TabsV3
              tabs={tabProperties}
              selectedTabIdx={selectedTabIdx}
              onChange={handleTabSelected}
              align={isMobile ? 'center' : 'left'}
            />
          </Content>

          <Content>
            {user && selectedTabIdx === AccountEventTabEnum.SCHEDULED && (
              <CheckBoxV2 checked={isFilteringInterestedEvent} onChange={handleCheckboxChanged}>
                <Text>「興味あり」のイベントに絞る</Text>
              </CheckBoxV2>
            )}
            {hasUnansweredQuestionnaire && (
              <UnansweredQuestionnaire>
                <LabelWrapper>
                  <StyledNotificationsNoneRoundedIcon />
                  <Label>未回答のアンケートがあります</Label>
                </LabelWrapper>
                <UnderlineLabelButton onClick={handleFilterButtonClicked}>
                  {isFilteringAnsweredEvent ? '絞り込まない' : '絞り込む'}
                </UnderlineLabelButton>
              </UnansweredQuestionnaire>
            )}
            <CardWrapper>
              {hasLength(eventSchedules) &&
                eventSchedules.map((schedule: EventScheduleV2, index: Index) => (
                  <AccountEventCard
                    base={base}
                    key={`card-${index}`}
                    user={user}
                    eventSchedule={schedule}
                    onClick={() => openAccountEventDetailScreen(schedule.eventScheduleId)}
                  />
                ))}
              {hasLength(eventSchedules) && isMobile && (
                <Bottom ref={bottomRef}>{isLoaderShown && <CircularLoader />}</Bottom>
              )}
              {hasLength(eventSchedules) && !isMobile && !isLoaderShown && (
                <Pagenate
                  noBackground
                  offset={offset.current}
                  limit={limit}
                  count={totalCount}
                  onClickBack={handlePageBack}
                  onClickForward={handlePageForward}
                />
              )}
              {!hasLength(eventSchedules) && isLoaderShown && (
                <CircularLoaderWrapper>
                  <CircularLoader />
                </CircularLoaderWrapper>
              )}
              {!hasLength(eventSchedules) && !isLoaderShown && (
                <MessageWrapper>
                  <NoEventMessage>対象のイベントはありません</NoEventMessage>
                </MessageWrapper>
              )}
            </CardWrapper>
          </Content>
        </ScrollArea>
      </Container>
    </Component>
  );
});

AccountEventList.displayName = 'AccountEventList';
export default AccountEventList;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: `100%`,
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ScrollArea = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 0 auto;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px 0px;
  gap: 16px;

  ${customMedia.greaterThan('small')`
    min-width: 640px;
  `}
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  cursor: pointer;
`;

const UnansweredQuestionnaire = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.status.warning};
  ${customMedia.greaterThan('small')`
    flex-direction: row;
  `}
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 4px;
`;

const Label = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.white};
`;

const StyledNotificationsNoneRoundedIcon = styled(NotificationsNoneRoundedIcon)`
  color: ${themeV2.mixins.v2.color.font.white};
`;

const UnderlineLabelButton = styled(Button)`
  width: max-content;
  padding: 0;
  color: ${themeV2.mixins.v2.color.font.white};
  text-decoration: underline;
`;

const CardWrapper = styled.div`
  width: 100%;
  flex: 1;
`;

const Bottom = styled.div`
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: center;
`;

const CircularLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoEventMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;
