import {
  ButtonV2,
  CardWithLabelV2,
  Component,
  InformationPanel,
  PageHeaderV2,
  ScreenLoaderV2,
  customMedia,
  styleForFullExpansion,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AccommodationPlan,
  BaseDto,
  BaseResourceCategoryRelationship,
  CHECK_SPACE_RESERVABLE,
  CheckSpaceReservableRequest,
  CheckSpaceReservableResponse,
  ChildReservationMap,
  ContractV2,
  FETCH_CONTRACT_V2_BY_USER,
  FETCH_SPACE_RESERVATIONS_BY_USER,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  FetchSpaceReservationsByUserRequest,
  FetchSpaceReservationsByUserResponse,
  RelationshipForFetchChildReservationMap,
  SAVE_SPACE_RESERVATION_AUTOMATICALLY,
  SaveSpaceReservationAutomaticallyRequest,
  SaveSpaceReservationAutomaticallyResponse,
  SpaceReservation,
  SpaceReservationId,
  SpaceReservationStatus,
  SpaceReservationType,
  User
} from '@atomica.co/irori';
import { Count } from '@atomica.co/types';
import { builder, embedIdInPath, hasLength, isUndefined, isZero } from '@atomica.co/utils';
import { addDays, differenceInCalendarDays, format, isDate, subDays } from 'date-fns';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import useCachedDataForSaveSpaceReservationAutomatically, {
  CachedDataForSaveSpaceReservationAutomatically
} from '../../redux/hooks/useCachedDataForSaveSpaceReservationAutomatically';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import ItemService from '../../services/item-service';
import { useCreditCardPayment } from '../../utils/space-reservation-util';
import { toZonedTimeFromUtcInBrowser } from '../../utils/space-utils';
import SpaceConditionForm from './space-condition-form/SpaceConditionForm';
import SpacePriceDetail from './space-price-detail/SpacePriceDetail';

interface P {
  base: BaseDto;
  user: User | undefined;
}

const validateCachedData = (data: CachedDataForSaveSpaceReservationAutomatically): boolean => {
  return (
    data.baseResourceCategory && data.roomCount > 0 && isDate(data.startAt) && isDate(data.endAt) && !!data.spacePlan
  );
};

const AutomaticSpaceReservationConfirmScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath, openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const { cachedDataForSaveSpaceReservationAutomatically: initData } =
    useCachedDataForSaveSpaceReservationAutomatically();

  const unmountRef = useUnmountRef();
  const [selectedParentSpaceReservationId, setSelectedParentSpaceReservationId] = useSafeState<SpaceReservationId>(
    unmountRef,
    initData.selectedParentSpaceReservationId
  );

  const [parentSpaceReservations, setParentSpaceReservations] = useSafeState<SpaceReservation[]>(unmountRef, []);
  const [childReservationMap, setChildReservationMap] = useSafeState<ChildReservationMap | undefined>(unmountRef, {});
  const [selectedStartDate, setSelectedStartDate] = useSafeState<Date | null>(unmountRef, initData.startAt);
  const [selectedEndDate, setSelectedEndDate] = useSafeState<Date | null>(unmountRef, initData.endAt);

  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef);

  const [reservableSpacePlans, setReservableSpacePlans] = useSafeState<AccommodationPlan[]>(unmountRef, []);

  const [isInitialized, setIsinitialized] = useSafeState<boolean>(unmountRef, false);
  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);
  const [isChecking, setIsChecking] = useSafeState<boolean>(unmountRef, false);
  const [isSaveFailed, setIsSaveFailed] = useSafeState<boolean>(unmountRef, false);
  const [isSaveError, setIsSaveError] = useSafeState<boolean>(unmountRef, false);
  const [roomCount, setRoomCount] = useSafeState<Count>(unmountRef, initData.roomCount);

  const topRef = useRef<HTMLDivElement>(null);

  const isCalculable = useMemo<boolean>(() => {
    return !isZero(roomCount) && !!selectedStartDate && !!selectedEndDate && !!initData.spacePlan.accommodationPlanId;
  }, [initData, selectedEndDate, selectedStartDate, roomCount]);

  const baseResourceCategoryRelationship = useMemo<BaseResourceCategoryRelationship | undefined>(() => {
    if (!initData.baseResourceCategory.relationalBaseResourceCategoryRelationships) return undefined;
    return initData.baseResourceCategory.relationalBaseResourceCategoryRelationships[0];
  }, [initData]);

  const isReservable = useMemo<boolean>(() => {
    const isRoomCalculableAndAvailable =
      isCalculable &&
      reservableSpacePlans.some(plan => plan.accommodationPlanId === initData.spacePlan.accommodationPlanId);

    return baseResourceCategoryRelationship
      ? !!selectedParentSpaceReservationId && isRoomCalculableAndAvailable
      : isRoomCalculableAndAvailable;
  }, [
    baseResourceCategoryRelationship,
    initData.spacePlan.accommodationPlanId,
    isCalculable,
    reservableSpacePlans,
    selectedParentSpaceReservationId
  ]);

  const isParentSpaceReservationStatus = useSafeCallback(
    (status: SpaceReservationStatus): boolean => {
      const parentSpaceReservation = parentSpaceReservations.find(
        rsv => rsv.spaceReservationId === selectedParentSpaceReservationId
      );
      return !!parentSpaceReservation && parentSpaceReservation.status === status;
    },
    [parentSpaceReservations, selectedParentSpaceReservationId]
  );

  const isProvisional = useMemo<boolean>(() => {
    return isParentSpaceReservationStatus(SpaceReservationStatus.PROVISIONAL);
  }, [isParentSpaceReservationStatus]);

  const isConfirmed = useMemo<boolean>(() => {
    return isParentSpaceReservationStatus(SpaceReservationStatus.CONFIRMED);
  }, [isParentSpaceReservationStatus]);

  const isEnabledCreditCardPayment = useMemo<boolean>(() => {
    return useCreditCardPayment(base, contract, selectedStartDate, user);
  }, [base, contract, selectedStartDate, user]);

  const openCompleteSpaceReservationScreen = useSafeCallback((): void => {
    if (isConfirmed) {
      openPath(embedIdInPath(Path.RESERVE_SPACE_COMPLETE, PATH_IDS, [base.baseCode, SpaceReservationStatus.CONFIRMED]));
      return;
    }
    openPath(embedIdInPath(Path.RESERVE_SPACE_COMPLETE, PATH_IDS, [base.baseCode, SpaceReservationStatus.PROVISIONAL]));
  }, [base, isConfirmed, openPath]);

  const checkSpaceReservable = useSafeCallback(async (): Promise<void> => {
    if (!selectedStartDate || !selectedEndDate) return;
    setIsChecking(true);

    const request = builder<CheckSpaceReservableRequest>()
      .baseId(base.baseId)
      .resourceCategoryId(initData.baseResourceCategory.baseResourceCategoryId)
      .startAt(selectedStartDate)
      .endAt(selectedEndDate)
      .roomCount(roomCount)
      .parentSpaceReservationId(selectedParentSpaceReservationId)
      .build();

    const response = await commonRequest<CheckSpaceReservableRequest, CheckSpaceReservableResponse>(
      CHECK_SPACE_RESERVABLE,
      request
    );

    setReservableSpacePlans(response?.reservableSpacePlans ?? []);
    setIsChecking(false);
  }, [
    base.baseId,
    commonRequest,
    initData,
    roomCount,
    selectedStartDate,
    selectedEndDate,
    selectedParentSpaceReservationId,
    setIsChecking,
    setReservableSpacePlans
  ]);

  const saveSpaceReservationAutomatically = useSafeCallback(async (): Promise<void> => {
    if (!user || !selectedStartDate || !selectedEndDate) return;
    const checkinAt = toZonedTimeFromUtcInBrowser(
      new Date(`${format(selectedStartDate, 'yyyy/MM/dd')} ${initData.spacePlan.checkIn}`),
      base.timezone
    );
    const checkoutAt = toZonedTimeFromUtcInBrowser(
      new Date(`${format(selectedEndDate, 'yyyy/MM/dd')} ${initData.spacePlan.checkOut}`),
      base.timezone
    );
    if (!checkinAt || !checkoutAt) return;
    setIsSaving(true);
    const request = builder<SaveSpaceReservationAutomaticallyRequest>()
      .baseId(base.baseId)
      .userId(user.userId)
      .spacePlanId(initData.spacePlan.accommodationPlanId)
      .startAt(checkinAt)
      .endAt(checkoutAt)
      .count(roomCount)
      .parentSpaceReservationId(selectedParentSpaceReservationId)
      .baseResourceCategoryId(initData.baseResourceCategory?.baseResourceCategoryId)
      .build();
    const response = await commonRequest<
      SaveSpaceReservationAutomaticallyRequest,
      SaveSpaceReservationAutomaticallyResponse
    >(SAVE_SPACE_RESERVATION_AUTOMATICALLY, request);

    if (!hasLength(response.spaceReservationIds)) {
      isUndefined(response.spaceReservationIds) ? setIsSaveError(true) : setIsSaveFailed(true);
      topRef.current?.scrollIntoView();
      setIsSaving(false);
      return;
    }

    openCompleteSpaceReservationScreen();
  }, [
    base.timezone,
    commonRequest,
    initData,
    roomCount,
    openCompleteSpaceReservationScreen,
    selectedEndDate,
    selectedParentSpaceReservationId,
    selectedStartDate,
    setIsSaveError,
    setIsSaveFailed,
    setIsSaving,
    user
  ]);

  const handleReserveClick = useSafeCallback(async (): Promise<void> => {
    if (isEnabledCreditCardPayment) {
      // TODO: create stripe session
    }

    await saveSpaceReservationAutomatically();
  }, [isEnabledCreditCardPayment, saveSpaceReservationAutomatically]);

  const handleSelectedRoomCount = useSafeCallback(
    (count: Count): void => {
      setRoomCount(count);
      setIsSaveError(false);
      setIsSaveFailed(false);
    },
    [setIsSaveError, setIsSaveFailed, setRoomCount]
  );

  const handleSelectedEndDate = useSafeCallback(
    (endDate: Date | null): void => {
      if (endDate) {
        setSelectedStartDate(startDate =>
          startDate && differenceInCalendarDays(endDate, startDate) > 0 ? startDate : subDays(endDate, 1)
        );
      }
      setSelectedEndDate(endDate);
      setIsSaveError(false);
      setIsSaveFailed(false);
    },
    [setIsSaveError, setIsSaveFailed, setSelectedStartDate, setSelectedEndDate]
  );

  const handleSelectedStartDate = useSafeCallback(
    (startDate: Date | null): void => {
      if (startDate) {
        setSelectedEndDate(endDate =>
          endDate && differenceInCalendarDays(endDate, startDate) > 0 ? endDate : addDays(startDate, 1)
        );
      }
      setSelectedStartDate(startDate);
      setIsSaveError(false);
      setIsSaveFailed(false);
    },
    [setIsSaveError, setIsSaveFailed, setSelectedStartDate]
  );

  const fetchParentSpaceReservations = useSafeCallback(async (): Promise<void> => {
    if (
      !user ||
      !baseResourceCategoryRelationship ||
      !baseResourceCategoryRelationship.baseResourceCategory ||
      !baseResourceCategoryRelationship.relationalBaseResourceCategory
    )
      return;
    const relationship = builder<RelationshipForFetchChildReservationMap>()
      .parentResourceCategoryId(baseResourceCategoryRelationship.baseResourceCategory.baseResourceCategoryId)
      .childResourceCategoryId(baseResourceCategoryRelationship.relationalBaseResourceCategory.baseResourceCategoryId)
      .build();

    const request = builder<FetchSpaceReservationsByUserRequest>()
      .userIds([user.userId])
      .fromDate(new Date())
      .baseId(base.baseId)
      .relationship(relationship)
      .build();
    const response = await commonRequest<FetchSpaceReservationsByUserRequest, FetchSpaceReservationsByUserResponse>(
      FETCH_SPACE_RESERVATIONS_BY_USER,
      request
    );

    if (!response) return;

    setParentSpaceReservations(response.spaceReservations);
    setChildReservationMap(response.childReservationMap);
  }, [
    base.baseId,
    baseResourceCategoryRelationship,
    commonRequest,
    setParentSpaceReservations,
    setChildReservationMap,
    user
  ]);

  const fetchContract = useSafeCallback(async (): Promise<void> => {
    if (!user) return;
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const response = await commonRequest<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    setContract(response.contract);
  }, [base, commonRequest, setContract, user]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    await Promise.all([fetchParentSpaceReservations(), fetchContract(), checkSpaceReservable()]);
    const isValid = validateCachedData(initData);
    if (!isValid) {
      openBasePath(Path.RESERVE_SPACE);
    }
    setIsinitialized(true);
  }, [fetchContract, fetchParentSpaceReservations, initData, openBasePath, setIsinitialized]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component
      className='automatic-space-reservation-confirm-screen'
      loading={!isInitialized}
      style={styleForFullExpansion}
      title={`knotPLACE -予約内容の確認-`}
    >
      <Content ref={topRef}>
        <PageHeaderV2 title='予約内容の確認' />
        {isSaveError && (
          <InformationPanel isBold status='error' title='仮予約に失敗しました'>
            スタッフ・管理者までお問い合わせください。
          </InformationPanel>
        )}
        {isSaveFailed && (
          <InformationPanel isBold status='error' title='空室が見つかりませんでした。'>
            指定したチェックイン・チェックアウト・部屋数では空室が見つかりませんでした。
            条件を変更して再度予約してください。
          </InformationPanel>
        )}
        <CardWrapper>
          <CardWithLabelV2
            label='予約対象'
            text={initData.spacePlan.planName}
            subText={`¥${ItemService.calcTaxIncludedPrice(initData.spacePlan.item).toLocaleString()} / 時間`}
          />
          <SpaceConditionForm
            type='confirm'
            initData={initData}
            parentSpaceReservations={parentSpaceReservations}
            childReservationMap={childReservationMap}
            baseResourceCategoryLabel={initData.baseResourceCategory.categoryLabel}
            baseResourceCategoryRelationship={baseResourceCategoryRelationship!}
            values={{ selectedParentSpaceReservationId, selectedStartDate, selectedEndDate, roomCount }}
            onChanges={{
              setSelectedParentSpaceReservationId,
              setSelectedStartDate: handleSelectedStartDate,
              setSelectedEndDate: handleSelectedEndDate,
              setRoomCount: handleSelectedRoomCount
            }}
            checkSpaceReservable={checkSpaceReservable}
          />
        </CardWrapper>
        {!isChecking && !isReservable && !isSaveFailed && selectedParentSpaceReservationId && (
          <InformationPanel isBold status='error' title='空室が見つかりませんでした。'>
            指定したチェックイン・チェックアウト・部屋数では空室が見つかりませんでした。 別の条件を指定してください。
          </InformationPanel>
        )}
        {isCalculable && (
          <SpacePriceDetail
            isEnabledCreditCardPayment={isEnabledCreditCardPayment}
            reservationType={
              initData.baseResourceCategory.resourceCategoryDef?.reservationType ?? SpaceReservationType.TIME
            }
            name={initData.spacePlan.planName}
            item={initData.spacePlan.item}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            contract={contract}
            roomCount={roomCount}
            isContractPlanDiscountRateTarget={initData?.baseResourceCategory?.isContractPlanDiscountRateTarget ?? false}
            enableFunctionToggleCodes={base.enabledFunctions?.map(e => e.toggleCode) ?? []}
          />
        )}
        {isReservable && isProvisional && (
          <InformationPanel isBold title='この予約は仮予約です。' status='warning'>
            この予約は仮予約です。送信後すぐに予約が確定するものではありません。
          </InformationPanel>
        )}
      </Content>
      <Footer>
        <StyledFooterButton label='キャンセル' onClick={() => openBasePath(Path.RESERVE_SPACE)} />
        <StyledFooterButton
          disabled={!isReservable || isSaveFailed}
          type='primary'
          label={`${isProvisional ? '仮予約' : '予約'}`}
          onClick={handleReserveClick}
        />
      </Footer>
      <ScreenLoaderV2 loading={isSaving} />
    </Component>
  );
});

AutomaticSpaceReservationConfirmScreen.displayName = 'AutomaticSpaceReservationConfirmScreen';
export default AutomaticSpaceReservationConfirmScreen;

const Content = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 30}px;
  margin: 0 auto;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin-top: auto;
  background: ${themeV3.mixins.v3.color.object.white};
  box-shadow: ${themeV2.mixins.v2.shadow.elevation5};
`;

const StyledFooterButton = styled(ButtonV2).attrs(() => ({ size: 'large', isFullWidth: true }))`
  ${customMedia.greaterThan('small')`
      width:200px !important;
  `};
`;
