import {
  ButtonV2,
  Component,
  LabelV2,
  PageHeaderV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, DOMAINS, SpaceId, SpacePlanId, SpacePricingId } from '@atomica.co/irori';
import { Name, Price, Rate } from '@atomica.co/types';
import { embedIdInPath } from '@atomica.co/utils';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FetchDropIn200DropIn } from '../../__generated/model';
import { getBsSpace } from '../../__generated/user/bs-space/bs-space';
import env from '../../env/env';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';
import { FAILED_PAYMENT_FOR_DROP_IN } from '../../texts/snackbar-text';

export type DropInConfirmScreenHistoryState = {
  dropInItem?: {
    spaceId: SpaceId | undefined;
    spaceName: Name | undefined;
    spacePlanId: SpacePlanId | undefined;
    spacePricingId: SpacePricingId | undefined;
    planName: Name | undefined;
    unitPrice: Price | undefined;
    taxRate: Rate | undefined;
  };
  beforeDropIn?: FetchDropIn200DropIn;
};

interface P {
  base: BaseDto;
}

const now = new Date();

const DropInConfirmScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { getHistoryState, setHistoryState, openBasePath } = usePath();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [dropInItem, setDropInItem] = useSafeState<DropInConfirmScreenHistoryState['dropInItem']>(unmountRef);
  const [beforeDropIn, setBeforeDropIn] = useSafeState<DropInConfirmScreenHistoryState['beforeDropIn']>(unmountRef);

  const cancel = useSafeCallback((): void => {
    setHistoryState({});
    openBasePath(Path.DROP_IN);
  }, [openBasePath, setHistoryState]);

  const pay = useSafeCallback(async (): Promise<void> => {
    if (!dropInItem?.spaceName || !dropInItem?.spaceId || !dropInItem?.spacePlanId || !dropInItem?.spacePricingId)
      return;

    const getBasePath = (path: Path) => `https://${DOMAINS[env]}${embedIdInPath(path, PATH_IDS, [base.baseCode])}`;

    const successUrl = getBasePath(Path.DROP_IN_COMPLETE);
    const returnUrl = getBasePath(Path.DROP_IN);

    const params = {
      startAt: now,
      spaceId: dropInItem.spaceId,
      spacePlanId: dropInItem.spacePlanId,
      spacePricingId: dropInItem.spacePricingId,
      successUrl,
      returnUrl
    };

    try {
      const { data } = beforeDropIn
        ? await getBsSpace().extendDropIn(base.baseId, beforeDropIn.dropInId, params)
        : await getBsSpace().createDropIn(base.baseId, params);

      const { sessionUrl } = data;
      setHistoryState({});

      if (!sessionUrl) {
        openSnackbar(FAILED_PAYMENT_FOR_DROP_IN, 'error');
        return;
      }

      window.location.href = sessionUrl;
    } catch (e) {
      openSnackbar(FAILED_PAYMENT_FOR_DROP_IN, 'error');
    }
  }, [base.baseCode, base.baseId, beforeDropIn, dropInItem, openSnackbar, setHistoryState]);

  const usageTime = useSafeCallback(() => {
    const startAt = beforeDropIn ? beforeDropIn.endAt : now;
    return format(startAt, 'MM/dd(E) HH:mm～', { locale: ja });
  }, [beforeDropIn]);

  useEffect(() => {
    const { dropInItem, beforeDropIn } = getHistoryState<DropInConfirmScreenHistoryState>();
    if (!dropInItem || Object.keys(dropInItem).length === 0) {
      return openBasePath(Path.DROP_IN);
    }
    setDropInItem(dropInItem);
    setBeforeDropIn(beforeDropIn);
  }, [getHistoryState, openBasePath, setBeforeDropIn, setDropInItem]);

  return (
    <Component className='drop-in-confirm-screen' loading={!dropInItem}>
      <Container data-testid='drop-in-confirm-screen'>
        <PageHeaderV2 title={beforeDropIn ? '延長内容の確認' : 'ご利用内容の確認'} />
        <Content data-testid='summary'>
          <Row>
            <LabelV2 text='利用スペース' />
            <Card>
              <BodyLarge>{dropInItem?.spaceName}</BodyLarge>
              <TextWrapper>
                <BodyMedium>{dropInItem?.planName}</BodyMedium>
                <BodyMedium>¥{dropInItem?.unitPrice}/時間</BodyMedium>
              </TextWrapper>
            </Card>
          </Row>
          <Row>
            <LabelV2 text='利用日時' />
            <TextWrapper>
              <BodyLarge>{usageTime()}</BodyLarge>
              <BodyMedium>※正確な時間は決済後にホーム画面でご確認ください。</BodyMedium>
              <BodyMedium>※営業時間内で利用可能です。</BodyMedium>
            </TextWrapper>
          </Row>
          <Row>
            <LabelV2 text='支払い方法' />
            <BodyLarge>クレジットカード</BodyLarge>
          </Row>
        </Content>
        <Content data-testid='price'>
          <Title>料金</Title>
          <Row>
            <TextWrapper>
              <BodyLarge>利用料金</BodyLarge>
              <BodyMedium>{dropInItem?.spaceName}</BodyMedium>
            </TextWrapper>
            <PriceWrapper>
              <BodyLarge>{dropInItem?.planName}</BodyLarge>
              <BodyLarge>¥{dropInItem?.unitPrice?.toLocaleString()}</BodyLarge>
            </PriceWrapper>
          </Row>
          <Line />
          <Row>
            <PriceWrapper>
              <BodyLarge>小計</BodyLarge>
              <BodyLarge>¥{dropInItem?.unitPrice?.toLocaleString()}</BodyLarge>
            </PriceWrapper>
            <PriceWrapper>
              <BodyMedium>うち消費税（{dropInItem?.taxRate}%）</BodyMedium>
              <BodyMedium>
                ¥{Math.floor((dropInItem?.unitPrice ?? 0) * ((dropInItem?.taxRate ?? 0) / 100)).toLocaleString()}
              </BodyMedium>
            </PriceWrapper>
          </Row>
          <Card>
            <PriceWrapper>
              <BodyLargeBold>合計</BodyLargeBold>
              <BodyLargeBold>¥{dropInItem?.unitPrice?.toLocaleString()}</BodyLargeBold>
            </PriceWrapper>
          </Card>
        </Content>
      </Container>
      <Footer>
        <ButtonV2 label='キャンセル' size='large' onClick={cancel} />
        <ButtonV2 width={209} label='お支払い' type='primary' size='large' onClick={pay} />
      </Footer>
    </Component>
  );
});

DropInConfirmScreen.displayName = 'DropInConfirmScreen';
export default DropInConfirmScreen;

const Container = styled.div`
  max-width: 640px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  margin-inline: auto;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.object.white};
  border-radius: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing}px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing / 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.container.neutral.row};
  border-radius: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyLarge = styled.span`
  ${themeV3.mixins.v3.typography.body.large};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const BodyMedium = styled.span`
  ${themeV3.mixins.v3.typography.body.medium};
  color: ${themeV3.mixins.v3.color.object.gray};
`;

const Title = styled.span`
  ${themeV3.mixins.v3.typography.title.large};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Line = styled.hr`
  width: 100%;
  color: ${themeV3.mixins.v3.color.border.gray};
  margin: 0;
`;

const BodyLargeBold = styled.span`
  ${themeV3.mixins.v3.typography.body.largeBold};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themeV3.mixins.v3.spacing}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.object.white};
`;
