import { ButtonV2, useSafeCallback } from '@atomica.co/components';
import React from 'react';
import styled from 'styled-components';
import CompletionScreen from '../../components/screen/CompletionScreen';
import { MOBILE_MAX_WIDTH } from '../../constants/common-const';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';

const DropInCompletionScreen = React.memo(() => {
  const { openBasePath } = usePath();
  const moveHome = useSafeCallback(() => openBasePath(Path.ACCOUNT_HOME), [openBasePath]);

  return (
    <CompletionScreen title='ドロップイン決済が完了しました'>
      <LargeButton type='tertiary' label='ホーム画面に戻る' onClick={moveHome} />
    </CompletionScreen>
  );
});

DropInCompletionScreen.displayName = 'DropInCompletionScreen';
export default DropInCompletionScreen;

const LargeButton = styled(ButtonV2).attrs(() => ({ size: 'large', isFullWidth: true }))`
  && {
    max-width: ${MOBILE_MAX_WIDTH}px;
  }
`;
