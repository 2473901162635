/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  CreateCustomerForAdmin200,
  CreateCustomerForAdminBody,
  CreateSaisonInvoiceBuyerForAdmin200,
  CreateSaisonInvoiceBuyerForAdminBody,
  DeleteContractDetailsV2ForAdmin200,
  DeleteContractDetailsV2ForAdminParams,
  DeleteContractForAdmin200,
  DeleteContractUserV2ForAdmin200,
  DeleteContractV2ForAdmin200,
  FetchContractForAdmin200,
  FetchContractLogsV2ForAdmin200,
  FetchContractLogsV2ForAdminParams,
  FetchContractNoV2ForAdmin200,
  FetchContractUsagesByDatesForAdmin200,
  FetchContractUsagesByDatesForAdminParams,
  FetchContractV2ByUserForAdmin200,
  FetchContractV2ForAdmin200,
  FetchContractsByDatesForAdmin200,
  FetchContractsByDatesForAdminParams,
  ImportContractV2FromCsvForAdmin200,
  ImportContractV2FromCsvForAdminBody,
  SaveContractDetailsV2ForAdmin200,
  SaveContractDetailsV2ForAdminBody,
  SaveContractForAdmin200,
  SaveContractForAdminBody,
  SaveContractLogV2ForAdmin200,
  SaveContractLogV2ForAdminBody,
  SaveContractV2ForAdmin200,
  SaveContractV2ForAdminBody,
  SearchContractPlansV2ForAdmin200,
  SearchContractPlansV2ForAdminParams,
  SearchContractUsersV2ForAdmin200,
  SearchContractUsersV2ForAdminParams,
  SearchContractsForAdmin200,
  SearchContractsForAdminParams,
  SearchContractsV2ForAdmin200,
  SearchContractsV2ForAdminParams,
  SearchCustomerForAdmin200,
  SearchCustomerForAdminParams,
  SendInvitationToContractMembersForAdmin200,
  SendInvitationToContractMembersForAdminBody,
  SendInvitationToContractUsersV2ForAdmin200,
  SendInvitationToContractUsersV2ForAdminBody
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';
import type { BodyType } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsContract = () => {
/**
 * 契約V2情報を保存する
 * @summary 契約V2情報を保存する
 */
const saveContractV2ForAdmin = (
    baseId: string,
    saveContractV2ForAdminBody: BodyType<SaveContractV2ForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveContractV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveContractV2ForAdminBody
    },
      options);
    }
  /**
 * 契約V2情報を検索する
 * @summary 契約V2情報を検索する
 */
const searchContractsV2ForAdmin = (
    baseId: string,
    params: SearchContractsV2ForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchContractsV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 契約V2情報を削除する
 * @summary 契約V2情報を削除する
 */
const deleteContractV2ForAdmin = (
    baseId: string,
    contractV2Id: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<DeleteContractV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2/${contractV2Id}`, method: 'DELETE'
    },
      options);
    }
  /**
 * 契約V2情報を取得する
 * @summary 契約V2情報を取得する
 */
const fetchContractV2ForAdmin = (
    baseId: string,
    contractV2Id: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2/${contractV2Id}`, method: 'GET'
    },
      options);
    }
  /**
 * ユーザーに紐づく契約V2情報を取得する
 * @summary ユーザーに紐づく契約V2情報を取得する
 */
const fetchContractV2ByUserForAdmin = (
    baseId: string,
    userId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractV2ByUserForAdmin200>(
      {url: `/admin/base/${baseId}/user/${userId}/contract-v2`, method: 'GET'
    },
      options);
    }
  /**
 * 契約V2の契約者に招待メールを送信する
 * @summary 契約V2の契約者に招待メールを送信する
 */
const sendInvitationToContractUsersV2ForAdmin = (
    baseId: string,
    contractV2Id: string,
    sendInvitationToContractUsersV2ForAdminBody: BodyType<SendInvitationToContractUsersV2ForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SendInvitationToContractUsersV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2/${contractV2Id}/user-v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sendInvitationToContractUsersV2ForAdminBody
    },
      options);
    }
  /**
 * 契約者情報を検索する
 * @summary 契約者情報を検索する
 */
const searchContractUsersV2ForAdmin = (
    baseId: string,
    contractV2Id: string,
    params: SearchContractUsersV2ForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchContractUsersV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2/${contractV2Id}/user-v2`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 契約した顧客情報をDBに保存する
 * @summary 契約した顧客情報をDBに保存する
 */
const createCustomerForAdmin = (
    baseId: string,
    createCustomerForAdminBody: BodyType<CreateCustomerForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<CreateCustomerForAdmin200>(
      {url: `/admin/base/${baseId}/customer`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCustomerForAdminBody
    },
      options);
    }
  /**
 * 契約した顧客情報を検索する
 * @summary 契約した顧客情報を検索する
 */
const searchCustomerForAdmin = (
    baseId: string,
    params?: SearchCustomerForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchCustomerForAdmin200>(
      {url: `/admin/base/${baseId}/customer`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 契約した顧客情報をセゾンインボイスと連携する
 * @summary 契約した顧客情報をセゾンインボイスと連携する
 */
const createSaisonInvoiceBuyerForAdmin = (
    baseId: string,
    contractV2Id: string,
    createSaisonInvoiceBuyerForAdminBody: BodyType<CreateSaisonInvoiceBuyerForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<CreateSaisonInvoiceBuyerForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2/${contractV2Id}/saison-buyer`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createSaisonInvoiceBuyerForAdminBody
    },
      options);
    }
  /**
 * 契約詳細情報を削除する
 * @summary 契約詳細情報を削除する
 */
const deleteContractDetailsV2ForAdmin = (
    baseId: string,
    params: DeleteContractDetailsV2ForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<DeleteContractDetailsV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-detail-v2`, method: 'DELETE',
        params
    },
      options);
    }
  /**
 * 契約V1情報を削除する
 * @summary 契約V1情報を削除する
 */
const deleteContractForAdmin = (
    baseId: string,
    contractId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<DeleteContractForAdmin200>(
      {url: `/admin/base/${baseId}/contract/${contractId}`, method: 'DELETE'
    },
      options);
    }
  /**
 * 契約V1情報を取得する
 * @summary 契約V1情報を取得する
 */
const fetchContractForAdmin = (
    baseId: string,
    contractId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractForAdmin200>(
      {url: `/admin/base/${baseId}/contract/${contractId}`, method: 'GET'
    },
      options);
    }
  /**
 * 契約者情報を削除する
 * @summary 契約者情報を削除する
 */
const deleteContractUserV2ForAdmin = (
    baseId: string,
    contractUserV2Id: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<DeleteContractUserV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-user-v2/${contractUserV2Id}`, method: 'DELETE'
    },
      options);
    }
  /**
 * 契約ログ情報を取得する
 * @summary 契約ログ情報を取得する
 */
const fetchContractLogsV2ForAdmin = (
    baseId: string,
    contractV2Id: string,
    params: FetchContractLogsV2ForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractLogsV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2/${contractV2Id}/log`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 契約番号を取得する
 * @summary 契約番号を取得する
 */
const fetchContractNoV2ForAdmin = (
    baseId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractNoV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-no-v2`, method: 'GET'
    },
      options);
    }
  /**
 * 指定した期間内の契約V2情報を取得する
 * @summary 指定した期間内の契約V2情報を取得する
 */
const fetchContractsByDatesForAdmin = (
    baseId: string,
    params: FetchContractsByDatesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractsByDatesForAdmin200>(
      {url: `/admin/base/${baseId}/contract/date`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 指定した期間内の契約利用情報を取得する
 * @summary 指定した期間内の契約利用情報を取得する
 */
const fetchContractUsagesByDatesForAdmin = (
    baseId: string,
    params: FetchContractUsagesByDatesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchContractUsagesByDatesForAdmin200>(
      {url: `/admin/base/${baseId}/contract-usage-v2/date`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 契約詳細情報を保存する
 * @summary 契約詳細情報を保存する
 */
const saveContractDetailsV2ForAdmin = (
    baseId: string,
    contractV2Id: string,
    saveContractDetailsV2ForAdminBody: BodyType<SaveContractDetailsV2ForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveContractDetailsV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2/${contractV2Id}/detail-v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveContractDetailsV2ForAdminBody
    },
      options);
    }
  /**
 * 契約V1情報を保存する
 * @summary 契約V1情報を保存する
 */
const saveContractForAdmin = (
    baseId: string,
    saveContractForAdminBody: BodyType<SaveContractForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveContractForAdmin200>(
      {url: `/admin/base/${baseId}/contract`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveContractForAdminBody
    },
      options);
    }
  /**
 * 契約V1情報を検索する
 * @summary 契約V1情報を検索する
 */
const searchContractsForAdmin = (
    baseId: string,
    params: SearchContractsForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchContractsForAdmin200>(
      {url: `/admin/base/${baseId}/contract`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 契約ログ情報を保存する
 * @summary 契約ログ情報を保存する
 */
const saveContractLogV2ForAdmin = (
    baseId: string,
    saveContractLogV2ForAdminBody: BodyType<SaveContractLogV2ForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveContractLogV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-log-v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveContractLogV2ForAdminBody
    },
      options);
    }
  /**
 * 契約プラン情報を検索する
 * @summary 契約プラン情報を検索する
 */
const searchContractPlansV2ForAdmin = (
    baseId: string,
    params: SearchContractPlansV2ForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchContractPlansV2ForAdmin200>(
      {url: `/admin/base/${baseId}/contract-plan-v2`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 契約V1の契約者に招待メールを送信する
 * @summary 契約V1の契約者に招待メールを送信する
 */
const sendInvitationToContractMembersForAdmin = (
    baseId: string,
    contractId: string,
    sendInvitationToContractMembersForAdminBody: BodyType<SendInvitationToContractMembersForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SendInvitationToContractMembersForAdmin200>(
      {url: `/admin/base/${baseId}/contract/${contractId}/member`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sendInvitationToContractMembersForAdminBody
    },
      options);
    }
  /**
 * 契約V2情報を保存するCSVから契約情報・契約明細・契約メンバーをインポートする
 * @summary CSVから契約情報・契約明細・契約メンバーをインポートする
 */
const importContractV2FromCsvForAdmin = (
    baseId: string,
    importContractV2FromCsvForAdminBody: BodyType<ImportContractV2FromCsvForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {const formData = new FormData();
if(importContractV2FromCsvForAdminBody.contractsCsv !== undefined) {
 formData.append('contractsCsv', importContractV2FromCsvForAdminBody.contractsCsv)
 }
if(importContractV2FromCsvForAdminBody.contractDetailsCsv !== undefined) {
 formData.append('contractDetailsCsv', importContractV2FromCsvForAdminBody.contractDetailsCsv)
 }
if(importContractV2FromCsvForAdminBody.contractUsersCsv !== undefined) {
 formData.append('contractUsersCsv', importContractV2FromCsvForAdminBody.contractUsersCsv)
 }

      return privateAxiosInstance<ImportContractV2FromCsvForAdmin200>(
      {url: `/admin/base/${baseId}/contract-v2/import`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  return {saveContractV2ForAdmin,searchContractsV2ForAdmin,deleteContractV2ForAdmin,fetchContractV2ForAdmin,fetchContractV2ByUserForAdmin,sendInvitationToContractUsersV2ForAdmin,searchContractUsersV2ForAdmin,createCustomerForAdmin,searchCustomerForAdmin,createSaisonInvoiceBuyerForAdmin,deleteContractDetailsV2ForAdmin,deleteContractForAdmin,fetchContractForAdmin,deleteContractUserV2ForAdmin,fetchContractLogsV2ForAdmin,fetchContractNoV2ForAdmin,fetchContractsByDatesForAdmin,fetchContractUsagesByDatesForAdmin,saveContractDetailsV2ForAdmin,saveContractForAdmin,searchContractsForAdmin,saveContractLogV2ForAdmin,searchContractPlansV2ForAdmin,sendInvitationToContractMembersForAdmin,importContractV2FromCsvForAdmin}};
export type SaveContractV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['saveContractV2ForAdmin']>>>
export type SearchContractsV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['searchContractsV2ForAdmin']>>>
export type DeleteContractV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['deleteContractV2ForAdmin']>>>
export type FetchContractV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractV2ForAdmin']>>>
export type FetchContractV2ByUserForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractV2ByUserForAdmin']>>>
export type SendInvitationToContractUsersV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['sendInvitationToContractUsersV2ForAdmin']>>>
export type SearchContractUsersV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['searchContractUsersV2ForAdmin']>>>
export type CreateCustomerForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['createCustomerForAdmin']>>>
export type SearchCustomerForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['searchCustomerForAdmin']>>>
export type CreateSaisonInvoiceBuyerForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['createSaisonInvoiceBuyerForAdmin']>>>
export type DeleteContractDetailsV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['deleteContractDetailsV2ForAdmin']>>>
export type DeleteContractForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['deleteContractForAdmin']>>>
export type FetchContractForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractForAdmin']>>>
export type DeleteContractUserV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['deleteContractUserV2ForAdmin']>>>
export type FetchContractLogsV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractLogsV2ForAdmin']>>>
export type FetchContractNoV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractNoV2ForAdmin']>>>
export type FetchContractsByDatesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractsByDatesForAdmin']>>>
export type FetchContractUsagesByDatesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['fetchContractUsagesByDatesForAdmin']>>>
export type SaveContractDetailsV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['saveContractDetailsV2ForAdmin']>>>
export type SaveContractForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['saveContractForAdmin']>>>
export type SearchContractsForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['searchContractsForAdmin']>>>
export type SaveContractLogV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['saveContractLogV2ForAdmin']>>>
export type SearchContractPlansV2ForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['searchContractPlansV2ForAdmin']>>>
export type SendInvitationToContractMembersForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['sendInvitationToContractMembersForAdmin']>>>
export type ImportContractV2FromCsvForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsContract>['importContractV2FromCsvForAdmin']>>>
