import {
  BackButtonV2,
  ButtonV2,
  FileDropZone,
  LabelV2,
  LinkButton,
  PageHeaderV2,
  StatusV2,
  styleForFullExpansion,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto } from '@atomica.co/irori';
import { embedIdInPath } from '@atomica.co/utils';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getBsContract } from '../../__generated/admin/bs-contract/bs-contract';
import Screen from '../../components/screen/Screen';
import { ERROR, SUCCESS } from '../../constants/snackbar-const';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';

interface P {
  base: BaseDto;
}

type FileKind = 'contract' | 'contractDetails' | 'contractUsers';
type Process = 'success' | 'failure' | 'unprocessed';

const initializeFiles = {
  contract: null,
  contractDetails: null,
  contractUsers: null
};

const FILE_SIZE_10MB = 10 * 1024 * 1024;

const ImportContractScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const unmountRef = useUnmountRef();
  const { params, openPath } = usePath();
  const { openSnackbar } = useSnackbarV2();

  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, false);
  const [process, setProcess] = useSafeState<Process>(unmountRef, 'unprocessed');

  const [files, setFiles] = useSafeState<Record<FileKind, File | null>>(unmountRef, initializeFiles);
  const [zipData, setZipData] = useSafeState<Blob | null>(unmountRef, null);

  const handleButtonClicked = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.CONTRACT_LIST_V2, PATH_IDS, [base.baseCode]));
  }, [openPath, base, params]);

  const handleFileChanged = useSafeCallback(
    async (file: File, type: FileKind): Promise<boolean> => {
      console.log('file:', file);
      console.log('type:', type);
      setFiles(prev => ({
        ...prev,
        [type]: file
      }));
      return true;
    },
    [setFiles]
  );
  const removedAttachFiles = useSafeCallback(() => {
    setFiles(initializeFiles);
  }, [setFiles]);

  const downloadErrorZipFile = useSafeCallback(() => {
    if (!zipData) return;
    const url = window.URL.createObjectURL(zipData);
    const link = document.createElement('a');
    link.href = url;
    // TODO: ファイル名調整
    link.download = '契約インポートErrors.zip.txt';
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }, [zipData]);

  const openContractListScreen = useSafeCallback(() => {
    window.open(embedIdInPath(Path.CONTRACT_LIST_V2, PATH_IDS, [base.baseCode]), '_blank');
  }, [base]);

  const onImportData = useSafeCallback(async () => {
    setIsLoading(true);

    await getBsContract()
      .importContractV2FromCsvForAdmin(base.baseId, {
        contractDetailsCsv: files.contractDetails ? new Blob([files.contractDetails]) : undefined,
        contractsCsv: files.contract ? new Blob([files.contract]) : undefined,
        contractUsersCsv: files.contractUsers ? new Blob([files.contractUsers]) : undefined
      })
      .then(({ data }) => {
        removedAttachFiles();

        // TODO: バックエンド繋ぎ込み時に修正
        // const result: boolean = false;

        if (data.result === false) {
          // TODO: バックエンド繋ぎ込み時に修正
          const fileContent = '契約インポートErrors.zipのダウンロードテスト';
          const blob = new Blob([fileContent], { type: 'text/plain' });
          openSnackbar(
            'ファイルの取り込みに失敗しました。エラー詳細をダウンロードして内容を確認の上、ファイルを修正してアップロードしてください。',
            ERROR,
            10000
          );
          setProcess('failure');
          setZipData(blob);
          return;
        }
        openSnackbar('契約の取り込みに成功しました!', SUCCESS, 10000);
        setZipData(null);
        setProcess('success');
      })
      .catch(() => {
        // TODO: バックエンド繋ぎ込み時に修正
        openSnackbar('ファイルの取り込みに失敗しました。時間を置いて再度リトライをお願いいたします。', ERROR, 10000);
        setProcess('failure');
        setZipData(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setIsLoading, files, base.baseId, removedAttachFiles, openSnackbar, setZipData, setProcess]);

  const ResultComponent = useMemo(() => {
    switch (process) {
      case 'success': {
        return (
          <ResultArea>
            <StatusWrapper>
              <LabelV2 text='処理ステータス' />
              <StatusV2 status='success' label='成功' />
            </StatusWrapper>
            <LinkWrapper>
              <label>契約の取り込みに成功しました。</label>
              <LinkButton label='契約一覧' hasIcon onClick={openContractListScreen} />
            </LinkWrapper>
          </ResultArea>
        );
      }
      case 'failure': {
        return (
          <ResultArea>
            <StatusWrapper>
              <LabelV2 text='処理ステータス' />
              <StatusV2 status='error' label='失敗' />
            </StatusWrapper>
            {zipData && (
              <DownloadWrapper>
                <label>
                  ファイルの取り込みに失敗しました。エラー詳細をダウンロードして内容を確認の上、ファイルを修正してアップロードしてください。
                </label>
                <ButtonV2
                  type='tertiary'
                  size='small'
                  label='エラー詳細をダウンロード'
                  startIcon={<GetAppRoundedIcon />}
                  onClick={downloadErrorZipFile}
                />
              </DownloadWrapper>
            )}
          </ResultArea>
        );
      }
    }
  }, [downloadErrorZipFile, process, zipData, openContractListScreen]);

  return (
    <Screen style={styleForFullExpansion} className='contract-import-screen' loading={isLoading} loadingType='circular'>
      <Container data-testid='contract-import-screen'>
        <BackButtonV2 label='戻る' onClick={handleButtonClicked} />
        <PageHeaderV2 title='契約の新規作成（CSVで一括作成）' />
        <Section>
          <Head>1. CSVファイルの作成</Head>
          <Description>
            <span>
              アップロードするCSVファイルのテンプレートをダウンロードして、編集してください。CSVの編集方法は、
            </span>
            <LinkButton label='ヘルプ' onClick={() => (location.href = 'https://www.google.co.jp/')} />
            <span>を参照してください。</span>
          </Description>
          <ButtonV2
            type='tertiary'
            startIcon={<GetAppRoundedIcon />}
            label='テンプレートをダウンロード'
            onClick={() => {}}
          />
        </Section>
        <Section>
          <Head>2. CSVファイルのアップロード</Head>
          <Description>作成したCSVをアップロードして取り込みます。</Description>
          <FileDropZone
            fileType='csv'
            header='契約情報'
            onChange={(file: File) => handleFileChanged(file, 'contract')}
            maxSizeInBytes={FILE_SIZE_10MB}
          />
          <FileDropZone
            fileType='csv'
            header='契約明細'
            onChange={(file: File) => handleFileChanged(file, 'contractDetails')}
            maxSizeInBytes={FILE_SIZE_10MB}
          />
          <FileDropZone
            fileType='csv'
            header='契約ユーザー'
            onChange={(file: File) => handleFileChanged(file, 'contractUsers')}
            maxSizeInBytes={FILE_SIZE_10MB}
          />
          <ButtonV2
            type='primary'
            startIcon={<NoteAddOutlinedIcon />}
            label='ファイルを取り込む'
            onClick={onImportData}
            loading={isLoading}
            disabled={Object.values(files).every(value => value === null) || isLoading}
          />
        </Section>
        <Section>
          <Head>3. 処理結果の確認</Head>
          {process === 'unprocessed' ? (
            <Description>
              <Center>ステップ2で、ファイルをアップロードして取り込んでください。</Center>
            </Description>
          ) : (
            ResultComponent
          )}
        </Section>
      </Container>
    </Screen>
  );
});

ImportContractScreen.displayName = 'ImportContractScreen';
export default ImportContractScreen;

const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
`;

const Section = styled.section`
  background-color: ${themeV3.mixins.v3.color.object.white};
  padding: ${themeV3.mixins.v3.spacing * 2}px ${themeV3.mixins.v3.spacing * 3}px ${themeV3.mixins.v3.spacing * 3}px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
`;

const Head = styled.div`
  ${themeV3.mixins.v3.typography.title.xLarge};
  padding: 0 ${themeV3.mixins.v3.spacing}px ${themeV3.mixins.v3.spacing}px;
  border-bottom: 1px solid ${themeV3.mixins.v3.color.border.gray};
`;

const Description = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  display: flex;
`;

const Center = styled.div`
  text-align: center;
`;

const ResultArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LinkWrapper = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  display: flex;
  gap: 8px;
`;

const DownloadWrapper = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
`;
