import { ButtonV2, Component, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Code, Id } from '@atomica.co/types';
import { EMPTY, isEmpty } from '@atomica.co/utils';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { StepKey } from '../StepVerificationScreen';

interface P extends RouteComponentProps {
  onPrevious: Dispatch<SetStateAction<StepKey>>;
}
function hankaku2Zenkaku(str) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
}

const REGEX_FOR_NUMBER = /[0-9]/;

const handleInputKeyDown = (
  e: React.KeyboardEvent<HTMLElement>,
  setCode: React.Dispatch<React.SetStateAction<Code>>,
  setIsFocused: (focused: boolean) => void,
  setPrevCode?: React.Dispatch<React.SetStateAction<Code>>
): void => {
  const { key } = e;
  console.log('key:', key);
  // 文字があれば、backspaceを受け付ける
  // 数字だけうけつける
  // 全角は半角に変換する
  // 上３つが通れば、次の入力欄にいく

  // 本田さん実装

  // 全角半角問わず数値が入力される or バックスペース以外は入力拒否
  if (!REGEX_FOR_NUMBER.test(key) && key !== 'Backspace') {
    console.log('数値 or バックスペース以外だから拒否');
    e.preventDefault();
    return;
  }

  if (key !== 'Backspace') return;

  setCode(prevCode => {
    if (isEmpty(prevCode)) {
      setIsFocused(true);
      if (setPrevCode) {
        setPrevCode(EMPTY);
      }
    }
    return EMPTY;
  });
};

const InputAuthenticationCode: React.FC<P> = React.memo(props => {
  const { onPrevious } = props;

  const unmountRef = useUnmountRef();
  const [firstCode, setFirstCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [secondCode, setSecondCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [thirdCode, setThirdCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [fourthCode, setFourthCode] = useSafeState<Code>(unmountRef, EMPTY);

  const [isFirstCodeFocused, setIsFirstCodeFocused] = useSafeState<boolean>(unmountRef, false);
  const [isSecondCodeFocused, setIsSecondCodeFocused] = useSafeState<boolean>(unmountRef, false);
  const [isThirdCodeFocused, setIsThirdCodeFocused] = useSafeState<boolean>(unmountRef, false);
  const [isFourthCodeFocused, setIsFourthCodeFocused] = useSafeState<boolean>(unmountRef, false);

  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, false);
  /** 後で消す */
  console.log(isLoading);
  const [isError, setIsError] = useSafeState<boolean>(unmountRef, false);

  const firstCodeRef = useRef<HTMLInputElement>(null);
  const secondCodeRef = useRef<HTMLInputElement>(null);
  const thirdCodeRef = useRef<HTMLInputElement>(null);
  const fourthCodeRef = useRef<HTMLInputElement>(null);

  const handleCodeChanged = useSafeCallback(
    (
      _code: Code,
      setCode: (code: Code) => void,
      setIsCurrentFocused: (focused: boolean) => void,
      setIsNextFocused?: (focused: boolean) => void
    ): void => {
      const code = hankaku2Zenkaku(_code);
      if (!REGEX_FOR_NUMBER.test(code) || code.length > 1) {
        setCode(EMPTY);
        return;
      }
      setCode(code);
      setIsError(false);
      if (isEmpty(code)) return;
      setIsCurrentFocused(false);
      if (setIsNextFocused) setIsNextFocused(true);
    },
    [isError]
  );

  useEffect(() => {
    if (isEmpty(firstCode) || isEmpty(secondCode) || isEmpty(thirdCode) || isEmpty(fourthCode)) return;
    setIsLoading(true);
    // 認証コードの確認
    // 認証コードが間違っている場合
    setIsError(true);
    // 自動遷移
  }, [firstCode, fourthCode, secondCode, thirdCode, setIsLoading, setIsError]);

  useEffect(() => {
    if (!firstCodeRef.current) return;
    if (isFirstCodeFocused) firstCodeRef.current.focus();
  }, [isFirstCodeFocused]);

  useEffect(() => {
    if (!secondCodeRef.current) return;
    if (isSecondCodeFocused) secondCodeRef.current.focus();
  }, [isSecondCodeFocused]);

  useEffect(() => {
    if (!thirdCodeRef.current) return;
    if (isThirdCodeFocused) thirdCodeRef.current.focus();
  }, [isThirdCodeFocused]);

  useEffect(() => {
    if (!fourthCodeRef.current) return;
    if (isFourthCodeFocused) fourthCodeRef.current.focus();
  }, [isFourthCodeFocused]);

  const constructCodeInput = ({
    inputRef,
    code,
    setCurrentCode,
    setPrevCode,
    isFocused,
    setPrevIsFocused,
    setCurrentIsFocused,
    setNextIsFocused,
    testid
  }: {
    inputRef: React.RefObject<HTMLInputElement>;
    code: Code;
    setCurrentCode: React.Dispatch<React.SetStateAction<Code>>;
    setPrevCode: React.Dispatch<React.SetStateAction<Code>>;
    isFocused: boolean;
    setPrevIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
    setNextIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
    testid: Id;
  }) => (
    <Input
      ref={inputRef}
      data-testid={testid}
      type='text'
      inputMode='numeric'
      value={code}
      focused={isFocused}
      onFocus={() => setCurrentIsFocused(true)}
      onBlur={() => setCurrentIsFocused(false)}
      onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
        handleInputKeyDown(e, setCurrentCode, setPrevIsFocused, setPrevCode)
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleCodeChanged(e.target.value, setCurrentCode, setCurrentIsFocused, setNextIsFocused)
      }
    />
  );

  return (
    <Component style={{ width: '100%' }} className='invalid-invitation' loading={isLoading}>
      <Context data-testid='step-verification-authentication-code'>
        {isError && (
          <ErrorMessageCard>
            <WarningRoundedIcon />
            認証コードが間違っています。
          </ErrorMessageCard>
        )}
        <TextFieldWrapper>
          {constructCodeInput({
            inputRef: firstCodeRef,
            code: firstCode,
            isFocused: isFirstCodeFocused,
            setCurrentCode: setFirstCode,
            setPrevCode: setFirstCode,
            setCurrentIsFocused: setIsFirstCodeFocused,
            setPrevIsFocused: setIsFirstCodeFocused,
            setNextIsFocused: setIsSecondCodeFocused,
            testid: 'verification-code-1'
          })}
          {constructCodeInput({
            inputRef: secondCodeRef,
            code: secondCode,
            isFocused: isSecondCodeFocused,
            setCurrentCode: setSecondCode,
            setPrevCode: setFirstCode,
            setCurrentIsFocused: setIsSecondCodeFocused,
            setPrevIsFocused: setIsFirstCodeFocused,
            setNextIsFocused: setIsThirdCodeFocused,
            testid: 'verification-code-2'
          })}
          {constructCodeInput({
            inputRef: thirdCodeRef,
            code: thirdCode,
            isFocused: isThirdCodeFocused,
            setCurrentCode: setThirdCode,
            setPrevCode: setSecondCode,
            setCurrentIsFocused: setIsThirdCodeFocused,
            setPrevIsFocused: setIsSecondCodeFocused,
            setNextIsFocused: setIsFourthCodeFocused,
            testid: 'verification-code-3'
          })}
          {constructCodeInput({
            inputRef: fourthCodeRef,
            code: fourthCode,
            isFocused: isFourthCodeFocused,
            setCurrentCode: setFourthCode,
            setPrevCode: setThirdCode,
            setCurrentIsFocused: setIsFourthCodeFocused,
            setPrevIsFocused: setIsThirdCodeFocused,
            setNextIsFocused: setIsFourthCodeFocused,
            testid: 'verification-code-4'
          })}
        </TextFieldWrapper>
        <ButtonWrapper>
          <ButtonV2 size='medium' type='tertiary' label='認証コードを再送信' onClick={() => {}} />
          <ButtonV2 size='medium' type='default' label='メールアドレスを再入力' onClick={() => onPrevious('SEND')} />
        </ButtonWrapper>
      </Context>
    </Component>
  );
});

InputAuthenticationCode.displayName = 'InputAuthenticationCode';
export default InputAuthenticationCode;

const Context = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  align-items: center;
`;
const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const Input = styled.input<{ focused: boolean }>`
  ${themeV2.mixins.v2.typography.title.large};
  width: 48px;
  height: 48px;
  text-align: center;
  outline: none;
  border-radius: 8px;
  ${({ focused }) =>
    `border: 2px solid ${
      focused ? themeV2.mixins.v2.color.border.pink : themeV2.mixins.v2.color.border.gray
    } !important;`}

  &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

const ErrorMessageCard = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  padding: ${themeV2.mixins.v2.spacing * 1.5}px;
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
  border-radius: 8px;
  color: ${themeV2.mixins.v2.color.status.error};
  background: ${themeV2.mixins.v2.color.background.pinkPale};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
