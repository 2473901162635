import {
  CheckBoxV2,
  FormGroupRadio,
  InformationPanel,
  TextFieldV2,
  UNSELECTED,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Questionnaire,
  QuestionnaireAnswerDetail,
  QuestionnaireItem,
  QuestionnaireItemId,
  QuestionnaireItemOption,
  QuestionnaireItemOptionId,
  QuestionnaireItemType
} from '@atomica.co/irori';
import { Index, Option, Text, URL } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, NOT_FOUND_INDEX, builder, hasLength, isEmpty, uuid } from '@atomica.co/utils';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { RefObject, createRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Labels } from '../../../models/common-model';
import useQuestionnaire from '../../../redux/hooks/useQuestionnaire';
import { partialBuilder } from '../../../utils/common-util';

interface P {
  questionnaire: Questionnaire;
  thumbnail: File | undefined;
  readOnly?: boolean;
  isAnswered?: boolean;
}

const findOtherItemOptionId = (item: QuestionnaireItem): QuestionnaireItemOptionId | undefined =>
  item.QuestionnaireItemOptions?.find(option => option.isOtherItemOption)?.questionnaireItemOptionId;

const findSelectedOption = (
  item: QuestionnaireItem,
  id: QuestionnaireItemOptionId
): QuestionnaireItemOption | undefined =>
  item.QuestionnaireItemOptions?.find(option => option.questionnaireItemOptionId === id);

const filterSelectedDetails = (
  details: QuestionnaireAnswerDetail[] | undefined,
  questionnaireItemId: QuestionnaireItemId
): QuestionnaireAnswerDetail[] =>
  details?.filter(detail => detail && detail.questionnaireItem?.questionnaireItemId === questionnaireItemId) || [];

const toRadioProps = (item: QuestionnaireItem): [Labels, Option[]] => {
  const labels: Labels =
    item.QuestionnaireItemOptions?.reduce((prev, curr) => {
      prev[curr.questionnaireItemOptionId] = curr.name;
      return prev;
    }, {}) || {};
  const options = item.QuestionnaireItemOptions?.map(option => option.questionnaireItemOptionId) || [];
  return [labels, options];
};

const QuestionnaireForm: React.FC<P> = React.memo(props => {
  const { questionnaire, thumbnail, readOnly, isAnswered } = props;

  const { invalidItemIds, cachedAnswer, saveCachedAnswer } = useQuestionnaire();

  const { questionnaireAnswerDetails = [] } = cachedAnswer;

  const unmountRef = useUnmountRef();
  const [thumbnailURL, setThumbnailURL] = useSafeState<URL>(unmountRef, questionnaire.thumbnailURL || EMPTY);

  const refs = useRef<RefObject<HTMLDivElement>[]>([]);

  questionnaire.questionnaireItems?.forEach((_, index) => {
    refs.current[index] = createRef<HTMLDivElement>();
  });

  const getSelectedOptionAndIndex = useSafeCallback(
    (id: QuestionnaireItemOptionId): [QuestionnaireAnswerDetail | undefined, Index] => {
      if (isEmpty(id)) return [undefined, NOT_FOUND_INDEX];
      const callback = (detail: QuestionnaireAnswerDetail): boolean =>
        detail.questionnaireItemOption?.questionnaireItemOptionId === id;
      return [questionnaireAnswerDetails.find(callback), questionnaireAnswerDetails.findIndex(callback)];
    },
    [questionnaireAnswerDetails]
  );

  const showThumbnail = useSafeCallback(
    (thumbnail: File): void => {
      const reader = new FileReader();
      reader.onload = event => setThumbnailURL(event.target?.result as URL);
      reader.readAsDataURL(thumbnail);
    },
    [setThumbnailURL]
  );

  const setNewAnswer = useSafeCallback(
    (item: QuestionnaireItem, questionnaireItemOption?: QuestionnaireItemOption, textAnswer?: Text): void => {
      const detail = builder<QuestionnaireAnswerDetail>()
        .questionnaireAnswerDetailId(uuid())
        .questionnaireItem(item)
        .textAnswer(textAnswer ?? EMPTY)
        .build();

      questionnaireAnswerDetails.push(
        questionnaireItemOption ? partialBuilder(detail, { questionnaireItemOption }) : detail
      );
    },
    [questionnaireAnswerDetails]
  );

  const updateAnswer = useSafeCallback((): void => {
    saveCachedAnswer(
      partialBuilder(cachedAnswer, {
        questionnaireAnswerDetails: questionnaireAnswerDetails.concat()
      })
    );
  }, [cachedAnswer, saveCachedAnswer, questionnaireAnswerDetails]);

  const handleOptionChanged = useSafeCallback(
    (
      index: Index,
      questionnaireItemOption: QuestionnaireItemOption | undefined,
      questionnaireItem: QuestionnaireItem
    ): void => {
      if (!questionnaireItemOption) {
        questionnaireAnswerDetails.splice(index, 1);
      } else {
        if (index === NOT_FOUND_INDEX) {
          setNewAnswer(questionnaireItem, questionnaireItemOption);
        } else {
          questionnaireAnswerDetails.splice(
            index,
            1,
            partialBuilder(questionnaireAnswerDetails[index], { questionnaireItemOption })
          );
        }
      }
      updateAnswer();
    },
    [questionnaireAnswerDetails, setNewAnswer, updateAnswer]
  );

  const handleOptionDeleted = useSafeCallback(
    (index: Index): void => {
      questionnaireAnswerDetails.splice(index, 1);
      updateAnswer();
    },
    [questionnaireAnswerDetails, updateAnswer]
  );

  const handleTextChanged = useSafeCallback(
    (index: Index, textAnswer: Text, questionnaireItem: QuestionnaireItem): void => {
      if (index === NOT_FOUND_INDEX) {
        setNewAnswer(questionnaireItem, undefined, textAnswer);
      } else {
        questionnaireAnswerDetails.splice(index, 1, partialBuilder(questionnaireAnswerDetails[index], { textAnswer }));
      }
      updateAnswer();
    },
    [questionnaireAnswerDetails, setNewAnswer, updateAnswer]
  );

  const getTextFieldComponent = useSafeCallback(
    (item: QuestionnaireItem): JSX.Element => {
      if (item.type === QuestionnaireItemType.TEXT) return <></>;
      const otherItemOptionId = findOtherItemOptionId(item);
      if (!otherItemOptionId) return <></>;
      const [detail, index] = getSelectedOptionAndIndex(otherItemOptionId);
      return (
        <TextFieldWrapper>
          <TextFieldV2
            maxLength={5000}
            readonly={index === NOT_FOUND_INDEX || readOnly}
            value={detail?.textAnswer || EMPTY}
            onChange={value => handleTextChanged(index, value, item)}
          />
        </TextFieldWrapper>
      );
    },
    [getSelectedOptionAndIndex, handleTextChanged, readOnly]
  );

  const getQuestionnaireComponent = useSafeCallback(
    (item: QuestionnaireItem): JSX.Element => {
      const { questionnaireItemId, type } = item;
      const selectedDetails = filterSelectedDetails(questionnaireAnswerDetails, questionnaireItemId);

      switch (type) {
        case QuestionnaireItemType.RADIO: {
          const [labels, options] = toRadioProps(item);
          const optionId =
            hasLength(selectedDetails) && selectedDetails[0].questionnaireItemOption
              ? selectedDetails[0].questionnaireItemOption.questionnaireItemOptionId
              : UNSELECTED;
          const [, prevIndex] = getSelectedOptionAndIndex(optionId);

          return (
            <FormGroupRadio
              direction='column'
              addUnselected={!item.isAnswerRequired}
              disabled={readOnly}
              labels={labels}
              options={options}
              value={optionId}
              onChange={value => handleOptionChanged(prevIndex, findSelectedOption(item, value), item)}
            />
          );
        }
        case QuestionnaireItemType.TEXT: {
          const index = questionnaireAnswerDetails.findIndex(
            detail =>
              hasLength(selectedDetails) &&
              detail.questionnaireAnswerDetailId === selectedDetails[0].questionnaireAnswerDetailId
          );
          return (
            <TextFieldV2
              multiline
              readonly={readOnly}
              maxLength={5000}
              value={hasLength(selectedDetails) ? selectedDetails[0].textAnswer : EMPTY}
              onChange={value => handleTextChanged(index, value, item)}
            />
          );
        }
        case QuestionnaireItemType.CHECKBOX: {
          const selectedOptionIds = selectedDetails.map(
            detail => detail.questionnaireItemOption?.questionnaireItemOptionId
          );
          return (
            <CheckBoxWrapper>
              {item.QuestionnaireItemOptions?.map((option, index) => (
                <CheckBoxV2
                  key={`checkbox-${index}`}
                  disabled={readOnly}
                  checked={selectedOptionIds.includes(option.questionnaireItemOptionId)}
                  onChange={value =>
                    value
                      ? handleOptionDeleted(getSelectedOptionAndIndex(option.questionnaireItemOptionId)[1])
                      : handleOptionChanged(-1, option, item)
                  }
                >
                  {option.name}
                </CheckBoxV2>
              ))}
            </CheckBoxWrapper>
          );
        }
        default:
          return <></>;
      }
    },
    [
      filterSelectedDetails,
      handleOptionChanged,
      handleOptionDeleted,
      handleTextChanged,
      questionnaireAnswerDetails,
      readOnly
    ]
  );

  useEffect(() => {
    if (!thumbnail) {
      setThumbnailURL(EMPTY);
      return;
    }
    showThumbnail(thumbnail);
  }, [setThumbnailURL, showThumbnail, thumbnail]);

  useEffect(() => {
    saveCachedAnswer(partialBuilder(cachedAnswer, { questionnaire }));
  }, [cachedAnswer, questionnaire, saveCachedAnswer]);

  useEffect(() => {
    if (!isAnswered || !questionnaire.questionnaireAnswers) return;
    saveCachedAnswer(questionnaire.questionnaireAnswers[0]);
  }, [isAnswered, questionnaire, saveCachedAnswer]);

  useEffect(() => {
    if (!hasLength(invalidItemIds)) return;
    const InvalidItemId = invalidItemIds[FIRST_INDEX];
    const invalidIndex =
      questionnaire.questionnaireItems?.findIndex(item => item.questionnaireItemId === InvalidItemId) ??
      NOT_FOUND_INDEX;
    refs.current[invalidIndex].current?.scrollIntoView({ behavior: 'smooth' });
  }, [invalidItemIds, questionnaire]);

  return (
    <Container>
      <Content>
        <ThumbnailWrapper>
          {thumbnail ? <Thumbnail src={thumbnailURL} /> : <StyledSkeleton variant='rect' height={200} />}
        </ThumbnailWrapper>
        <TextWrapper>
          <Heading>{questionnaire.name}</Heading>
          {questionnaire.description && <Description>{questionnaire.description}</Description>}
          {!isAnswered && questionnaire.answerDueAt && (
            <AnswerDueAt>回答期限: {format(questionnaire.answerDueAt, 'yyyy.MM.dd (EE)', { locale: ja })}</AnswerDueAt>
          )}
        </TextWrapper>
      </Content>
      {readOnly && (
        <InformationPanel
          title={isAnswered ? 'すでに回答済みです' : '入力内容をご確認ください'}
          status={isAnswered ? 'success' : 'default'}
          isBold={isAnswered || undefined}
        >
          {isAnswered && 'ご回答ありがとうございました'}
        </InformationPanel>
      )}
      {questionnaire.questionnaireItems?.map((item, index) => (
        <Content key={`questionnaire-${index}`} ref={refs.current[index]}>
          <TextWrapper>
            <TitleWrapper>
              <Title>{item.name}</Title>
              {item.isAnswerRequired && <RequiredLabel>[必須]</RequiredLabel>}
            </TitleWrapper>

            <ComponentWrapper>
              {getQuestionnaireComponent(item)}
              {getTextFieldComponent(item)}
            </ComponentWrapper>

            {invalidItemIds.includes(item.questionnaireItemId) && (
              <ErrorMessageWrapper>
                <StyledWarningRoundedIcon />
                <ErrorMessage>この質問は回答必須です。</ErrorMessage>
              </ErrorMessageWrapper>
            )}
          </TextWrapper>
        </Content>
      ))}
    </Container>
  );
});

QuestionnaireForm.displayName = 'QuestionnaireForm';
export default QuestionnaireForm;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  border-radius: 12px;
  background: ${themeV2.mixins.v2.color.background.white};
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 12px 12px 0 0;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
  object-fit: cover;
  aspect-ratio: 16 / 9;
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 12px 12px 0 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Heading = styled.div`
  ${themeV2.mixins.v2.typography.headLine.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const Description = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const AnswerDueAt = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
  font-weight: 700;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const RequiredLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.medium};
  color: ${themeV2.mixins.v2.color.status.required};
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${themeV2.mixins.v2.spacing * 1.5}px;
  border-radius: 8px;
  background: ${themeV2.mixins.v2.color.background.pinkPale};
`;

const ErrorMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.status.error};
`;

const StyledWarningRoundedIcon = styled(WarningRoundedIcon)`
  color: ${themeV2.mixins.v2.color.status.error};
`;

const TextFieldWrapper = styled.div`
  margin-left: 28px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
