import { ButtonV2, Component, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  AccessV2,
  BaseDto,
  CREATE_ACCESS_TO_ENTRANCE_FOR_ADMIN,
  CreateAccessV2ToEntranceForAdminRequest,
  CreateAccessV2ToEntranceForAdminResponse,
  DELETE_ACCESS_TO_ENTRANCE_FOR_ADMIN,
  DeleteAccessV2ToEntranceForAdminRequest,
  DeleteAccessV2ToEntranceForAdminResponse,
  Direction,
  FETCH_ACCESSES_V2_FOR_ADMIN,
  FetchAccessesV2ForAdminRequest,
  FetchAccessesV2ForAdminResponse,
  UPDATE_ACCESS_TO_ENTRANCE_FOR_ADMIN,
  UpdateAccessV2ToEntranceForAdminRequest,
  UpdateAccessV2ToEntranceForAdminResponse
} from '@atomica.co/irori';
import { DateStr, Id } from '@atomica.co/types';
import { builder, Optional } from '@atomica.co/utils';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { differenceInMinutes, format, startOfDay } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES,
  UserDetailRoomEntryExitHistoryFeatures
} from '../../../enums/users-enum';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { EntryExitErrors, useEntryExit } from '../../../redux/hooks/useRoomEntryExit';
import RoomEntryExitHistoryModal from './model/RoomEntryExitHistoryModal';
import UserRoomEntryExitHistoryTable from './table/UserRoomEntryExitHistoryTable';

/** 一覧定義 */
interface RoomEntryExitHistoryRow {
  id: { entryId: Id; exitId: Id };
  entryDate: DateStr;
  exitDate: DateStr;
  stayingTime: DateStr;
  control: JSX.Element;
}

interface ControlProps {
  entryId: Id;
  exitId: Id;
  entryTime: Optional<Date>;
  exitTime: Optional<Date>;
}

interface P {
  base: BaseDto;
  selectedUserId: Id;
}

const snackbarMessage = (method: string, result: 'success' | 'failure') =>
  `入退室履歴の${method}に${result === 'success' ? '成功' : '失敗'}しました`;

const formatDate = (date: Date): DateStr => format(date, 'yyyy/MM/dd HH:mm');

const calculateStayingTime = (entryDate: Date, exitDate: Date): DateStr => {
  const minutes = differenceInMinutes(exitDate, entryDate);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
};

const createHistoryRow = (
  entry: Optional<AccessV2>,
  exit: Optional<AccessV2>,
  onControl: (props: ControlProps, control: UserDetailRoomEntryExitHistoryFeatures) => void
): RoomEntryExitHistoryRow => {
  const entryId = entry.map(e => e.accessId).getOrElse('--');
  const exitId = exit.map(e => e.accessId).getOrElse('--');
  const entryTime = entry.map(e => e.operationDatetime);
  const exitTime = exit.map(e => e.operationDatetime);
  return {
    id: { entryId, exitId },
    entryDate: entry.map(e => formatDate(e.operationDatetime)).getOrElse('--:--'),
    exitDate: exit.map(e => formatDate(e.operationDatetime)).getOrElse('--:--'),
    stayingTime: entry
      .flatMap(e => exit.map(ex => calculateStayingTime(e.operationDatetime, ex.operationDatetime)))
      .getOrElse('--:--'),
    control: (
      <Buttons>
        <ButtonV2
          startIcon={<EditOutlinedIcon data-testid='edit-button' />}
          label={'編集'}
          size='small'
          type='tertiary'
          onClick={() =>
            onControl({ entryId, exitId, entryTime, exitTime }, USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.UPDATE)
          }
        />
        <ButtonV2
          startIcon={<DeleteOutlineRoundedIcon data-testid='delete-button' />}
          label={'削除'}
          size='small'
          type='tertiary'
          onClick={() =>
            onControl({ entryId, exitId, entryTime, exitTime }, USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.DELETE)
          }
        />
      </Buttons>
    )
  };
};

const processDailyAccesses = (
  dailyAccesses: AccessV2[],
  onControl: (props: ControlProps, control: UserDetailRoomEntryExitHistoryFeatures) => void
): RoomEntryExitHistoryRow[] => {
  const rows: RoomEntryExitHistoryRow[] = [];
  let currentEntry: Optional<AccessV2> = Optional.empty();

  dailyAccesses.forEach((access, index) => {
    if (access.direction === Direction.ENTER) {
      if (currentEntry.isPresent()) {
        rows.push(createHistoryRow(currentEntry, Optional.empty(), onControl));
      }
      currentEntry = Optional.of(access);
    } else if (access.direction === Direction.EXIT) {
      rows.push(createHistoryRow(currentEntry, Optional.of(access), onControl));
      currentEntry = Optional.empty();
    }

    if (index === dailyAccesses.length - 1 && currentEntry.isPresent()) {
      rows.push(createHistoryRow(currentEntry, Optional.empty(), onControl));
    }
  });

  return rows;
};

const setRoomEntryExitHistoryRows = (
  accesses: Optional<AccessV2[]>,
  onControl: (props: ControlProps, control: UserDetailRoomEntryExitHistoryFeatures) => void
): RoomEntryExitHistoryRow[] => {
  return accesses
    .map(accessList => {
      const accessesByDate = accessList.reduce(
        (acc, access) => {
          const date = startOfDay(access.operationDatetime).toISOString();
          if (!acc[date]) acc[date] = [];
          acc[date].push(access);
          return acc;
        },
        {} as Record<string, AccessV2[]>
      );

      return Object.values(accessesByDate).flatMap(dailyAccesses => {
        const sortedDailyAccesses = dailyAccesses.sort(
          (a, b) => a.operationDatetime.getTime() - b.operationDatetime.getTime()
        );
        return processDailyAccesses(sortedDailyAccesses, onControl);
      });
    })
    .getOrElse([]);
};

const getUTCDatesFromUsagePeriod = (usagePeriod: DateStr): { startDate: Date; endDate: Date } => {
  const [year, month] = usagePeriod.split('-').map(Number);
  const startDate = new Date(Date.UTC(year, month - 1, 1, -9, 0, 0));
  const endDate = new Date(Date.UTC(year, month, 1, -9, 0, 0));
  endDate.setUTCMilliseconds(-1);
  return { startDate, endDate };
};

const UserRoomEntryExitHistory: React.FC<P> = React.memo(props => {
  const { base, selectedUserId: userId } = props;

  const unmountRef = useUnmountRef();
  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();
  const entryExitHook = useEntryExit();

  const [isOpen, setIsOpen] = useSafeState<boolean>(unmountRef, false);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [actionKey, setActionKey] = useSafeState<UserDetailRoomEntryExitHistoryFeatures>(unmountRef);
  const [isDisabledAction, setIsDisabledAction] = useSafeState<boolean>(unmountRef, false);
  const [rows, setRows] = useSafeState<RoomEntryExitHistoryRow[]>(unmountRef, []);
  const [usagePeriod, setUsagePeriod] = useSafeState<DateStr>(unmountRef, () => {
    const currentDate = new Date();
    return format(currentDate, 'yyyy-M');
  });
  const [errors, setErrors] = useSafeState<EntryExitErrors>(unmountRef);
  const handleOpenModal = useSafeCallback(
    (actionKey: UserDetailRoomEntryExitHistoryFeatures): void => {
      setIsOpen(true);
      setErrors({});
      setActionKey(actionKey);
    },
    [isOpen, setActionKey, setIsOpen]
  );
  const onControl = useSafeCallback(
    (props: ControlProps, control: UserDetailRoomEntryExitHistoryFeatures) => {
      const { entryId, exitId, entryTime, exitTime } = props;
      entryExitHook.resetAllFields();
      entryExitHook.changeHandlers.onEntryIdChange(entryId);
      entryExitHook.changeHandlers.onExitIdChange(exitId);
      entryTime.map(e => entryExitHook.setDateTimeFields('entry', e));
      exitTime.map(e => entryExitHook.setDateTimeFields('exit', e));
      handleOpenModal(control);
    },
    [entryExitHook, handleOpenModal]
  );
  const onCreate = useSafeCallback(
    (actionKey: UserDetailRoomEntryExitHistoryFeatures) => {
      entryExitHook.resetAllFields();
      handleOpenModal(actionKey);
    },
    [entryExitHook, handleOpenModal]
  );
  const fetchAccessHistories = useSafeCallback(async (): Promise<Optional<AccessV2[]>> => {
    setIsLoaderShown(true);
    const { startDate, endDate } = getUTCDatesFromUsagePeriod(usagePeriod);
    const request = builder<FetchAccessesV2ForAdminRequest>()
      .baseId(base.baseId)
      .userIds([userId])
      .start(startDate)
      .end(endDate)
      .build();
    const response = await commonRequest<FetchAccessesV2ForAdminRequest, FetchAccessesV2ForAdminResponse>(
      FETCH_ACCESSES_V2_FOR_ADMIN,
      request
    );
    setIsLoaderShown(false);
    return Optional.of(response?.accesses || []);
  }, [setIsLoaderShown, usagePeriod, base.baseId, userId, commonRequest]);

  const initialize = useSafeCallback(async () => {
    const accesses = await fetchAccessHistories();
    const historyRows = setRoomEntryExitHistoryRows(accesses, onControl);
    setRows(historyRows);
  }, [fetchAccessHistories, onControl, setRows]);

  const handleCreate = useSafeCallback(async (): Promise<void> => {
    setIsDisabledAction(true);
    const { isValid, errors } = entryExitHook.validate();
    if (!isValid) {
      setErrors(errors);
      setIsDisabledAction(false);
    } else {
      setIsLoaderShown(true);
      const entryDate = entryExitHook.getDateTime('entry');
      const exitDate = entryExitHook.getDateTime('exit');
      const request = builder<CreateAccessV2ToEntranceForAdminRequest>()
        .baseId(base.baseId)
        .userId(userId)
        .entryDate(entryDate)
        .exitDate(exitDate)
        .build();
      await commonRequest<CreateAccessV2ToEntranceForAdminRequest, CreateAccessV2ToEntranceForAdminResponse>(
        CREATE_ACCESS_TO_ENTRANCE_FOR_ADMIN,
        request
      )
        .then(async e => {
          if (e) {
            setIsOpen(false);
            await initialize();
            openSnackbar(snackbarMessage('新規作成', 'success'), 'success', 6000);
          } else {
            openSnackbar(snackbarMessage('新規作成', 'failure'), 'error', 6000);
          }
        })
        .catch(() => openSnackbar(snackbarMessage('新規作成', 'failure'), 'error', 6000))
        .finally(() => {
          setIsLoaderShown(false);
          setIsDisabledAction(false);
        });
    }
  }, [
    setIsLoaderShown,
    entryExitHook,
    setErrors,
    base.baseId,
    userId,
    commonRequest,
    setIsOpen,
    openSnackbar,
    initialize,
    setIsDisabledAction
  ]);

  const handleUpdate = useSafeCallback(async (): Promise<void> => {
    setIsDisabledAction(true);
    const { isValid, errors } = entryExitHook.validate();
    if (!isValid) {
      setErrors(errors);
      setIsDisabledAction(false);
    } else {
      setIsLoaderShown(true);
      const entryDate = entryExitHook.getDateTime('entry');
      const exitDate = entryExitHook.getDateTime('exit');
      const request = builder<UpdateAccessV2ToEntranceForAdminRequest>()
        .baseId(base.baseId)
        .userId(userId)
        .entry({
          accessId: entryExitHook.data.entryId,
          operationDatetime: entryDate
        })
        .exit({
          accessId: entryExitHook.data.exitId,
          operationDatetime: exitDate
        })
        .build();
      await commonRequest<UpdateAccessV2ToEntranceForAdminRequest, UpdateAccessV2ToEntranceForAdminResponse>(
        UPDATE_ACCESS_TO_ENTRANCE_FOR_ADMIN,
        request
      )
        .then(async e => {
          if (e) {
            setIsOpen(false);
            openSnackbar(snackbarMessage('更新', 'success'), 'success', 6000);
            await initialize();
          } else {
            openSnackbar(snackbarMessage('更新', 'failure'), 'error', 6000);
          }
        })
        .catch(() => openSnackbar(snackbarMessage('更新', 'failure'), 'error', 6000))
        .finally(() => {
          setIsLoaderShown(false);
          setIsDisabledAction(false);
        });
    }
  }, [
    setIsLoaderShown,
    setIsDisabledAction,
    entryExitHook,
    setErrors,
    base.baseId,
    userId,
    commonRequest,
    setIsOpen,
    openSnackbar,
    initialize
  ]);

  const handleDelete = useSafeCallback(async (): Promise<void> => {
    const accessIds = [entryExitHook.data.entryId, entryExitHook.data.exitId].filter(e => e !== undefined);
    setIsLoaderShown(true);
    setIsDisabledAction(true);
    const request = builder<DeleteAccessV2ToEntranceForAdminRequest>()
      .baseId(base.baseId)
      .userId(userId)
      .accessIds(accessIds)
      .build();
    await commonRequest<DeleteAccessV2ToEntranceForAdminRequest, DeleteAccessV2ToEntranceForAdminResponse>(
      DELETE_ACCESS_TO_ENTRANCE_FOR_ADMIN,
      request
    )
      .then(async e => {
        if (e.result) {
          setIsOpen(false);
          openSnackbar(snackbarMessage('削除', 'success'), 'success', 3000);
          await initialize();
        } else {
          openSnackbar(snackbarMessage('削除', 'failure'), 'error', 3000);
        }
      })
      .catch(() => openSnackbar(snackbarMessage('削除', 'failure'), 'error', 3000))
      .finally(() => {
        setIsLoaderShown(false);
        setIsDisabledAction(false);
      });
  }, [
    base.baseId,
    commonRequest,
    entryExitHook.data.entryId,
    entryExitHook.data.exitId,
    setIsLoaderShown,
    setIsDisabledAction,
    userId,
    setIsOpen,
    initialize,
    openSnackbar
  ]);

  const onAction = useSafeCallback(async () => {
    switch (actionKey) {
      case USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.REGISTER:
        return await handleCreate();
      case USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.UPDATE:
        return await handleUpdate();
      case USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.DELETE:
        return await handleDelete();
    }
  }, [actionKey, handleCreate, handleUpdate, handleDelete]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usagePeriod]);

  return (
    <Component className='user-room-entry-exit-history'>
      <Container>
        <UserRoomEntryExitHistoryTable
          rows={rows}
          isLoaderShown={isLoaderShown}
          usagePeriod={usagePeriod}
          setUsagePeriod={setUsagePeriod}
          headerRightComponent={
            <ButtonV2
              onClick={() => onCreate(USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES.REGISTER)}
              size='medium'
              type='tertiary'
              label={'入退室履歴を追加'}
              startIcon={<AddIcon />}
            />
          }
        />
      </Container>
      <RoomEntryExitHistoryModal
        isOpen={isOpen}
        onAction={onAction}
        onClose={() => setIsOpen(false)}
        actionKey={actionKey}
        entryExitHook={entryExitHook}
        errors={errors}
        isDisabledAction={isDisabledAction}
      />
    </Component>
  );
});

UserRoomEntryExitHistory.displayName = 'UserRoomEntryExitHistory';
export default UserRoomEntryExitHistory;

const Container = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const Buttons = styled.div`
  display: flex;
  padding: ${themeV2.mixins.v2.spacing}px;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
