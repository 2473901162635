import { MasterSearchOption } from '@atomica.co/components';
import {
  ContractDetailV2,
  ContractorType,
  ContractPlanV2,
  ContractQuantityInputType,
  ContractV2,
  InflowSource,
  ItemId,
  ItemV2,
  PaymentMethod,
  RecurrencePattern,
  TaxDiv,
  TaxId,
  UnitId
} from '@atomica.co/irori';
import { Customer } from '@atomica.co/irori/build/dtos/customer-dto';
import {
  Address,
  City,
  Code,
  Count,
  Email,
  Hour,
  Id,
  Key,
  Month,
  Name,
  NoStr,
  Phone,
  Post,
  Price,
  Quantity,
  Rate,
  Remarks,
  Text
} from '@atomica.co/types';
import {
  builder,
  EMPTY,
  hasLength,
  isArray,
  isLessThanZero,
  ONE,
  parseFloat,
  Prefecture,
  toTaxExcluded,
  uuid,
  ZERO
} from '@atomica.co/utils';
import { UsedContractItemCodeEnum, UsedContractOptionEnum } from '../enums/contract-v2-enum';
import ItemService from '../services/item-service';
import { CONTRACTOR_TYPE_LABELS } from '../texts/contract-v2-text';
import { calcTaxIncludedAmountPrice, getTaxKey } from '../utils/tax-util';

/*
 * 契約詳細画面用
 */
interface OptionForDetailScreen {
  name: Name;
  unitPrice: Price;
  recurrenceCount: Month;
}
export interface DetailForContractDetailScreen {
  startDate: Date;
  endDate?: Date;
  contractNo: NoStr;
  contractor: Text;
  contractorName: Name;
  contractorEmail: Email;
  contractorPhone: Phone;
  contractorPost: Post;
  contractorPrefecture: Prefecture;
  contractorCity: City;
  contractorAddress: Address;
  remarks: Remarks;
  inflowSource: InflowSource;
}
export interface DiscountForContractDetailScreen {
  contractDetailId?: Id;
  itemId: ItemId;
  itemName: Name;
  itemCode: Code;
  unitPrice: Price;
  recurrenceCount: Month;
  taxDiv?: TaxDiv;
  taxId?: TaxId;
  unitId?: UnitId;
}
export interface PlanForContractDetailScreen {
  planName: Name;
  basicCharge: Price;
  freeTier: Hour | undefined;
  discountRate: Rate | undefined;
  usersCount: Count;
  useAddress: boolean;
  lockerCount: Count;
  options: OptionForDetailScreen[];
  discounts: DiscountForContractDetailScreen[];
}
export interface PaymentForContractDetailScreen {
  method: PaymentMethod;
  count: Count;
}
export interface DetailsForContractDetailScreen {
  detail: DetailForContractDetailScreen;
  plan: PlanForContractDetailScreen;
  payment: PaymentForContractDetailScreen;
}
export const toBasicCharge = (plan: ContractPlanV2 | undefined): Price => {
  if (!plan || !isArray(plan.contractOptions)) return ZERO;
  const option = plan.contractOptions.find(option => option.item?.itemCode === UsedContractOptionEnum.BASIC_CHARGE);
  return option?.item?.unitPrice || ZERO;
};
export const toDisplayContractDetails = (
  contract: ContractV2 | undefined
): DetailsForContractDetailScreen | undefined => {
  if (!contract || !isArray(contract.contractDetails)) return;

  const contractor =
    contract.contractorType === ContractorType.ENTITY
      ? `${CONTRACTOR_TYPE_LABELS[ContractorType.ENTITY]}\r\n${contract.entityName}`
      : CONTRACTOR_TYPE_LABELS[contract.contractorType];

  const detail = builder<DetailForContractDetailScreen>()
    .startDate(contract.startDate)
    .endDate(contract.endDate)
    .contractNo(contract.contractNo)
    .contractor(contractor)
    .contractorName(contract.contractorName)
    .contractorEmail(contract.contractorEmail)
    .contractorPhone(contract.contractorPhone ?? EMPTY)
    .contractorPost(contract.contractorPost)
    .contractorPrefecture(contract.contractorPrefecture)
    .contractorCity(contract.contractorCity)
    .contractorAddress(contract.contractorAddress)
    .remarks(contract.remarks ?? EMPTY)
    .inflowSource(contract.inflowSource)
    .build();

  const plan = builder<PlanForContractDetailScreen>()
    .planName(contract.contractPlan!.contractPlanName)
    .basicCharge(contract.contractPlan?.item ? ItemService.calcTaxIncludedPrice(contract.contractPlan.item) : ZERO)
    .freeTier(contract.contractPlan?.freeTier || ZERO)
    .discountRate(contract.contractPlan?.discountRate || ZERO)
    .usersCount(contract.contractUserCount)
    .useAddress(false)
    .lockerCount(ZERO)
    .options([])
    .discounts([])
    .build();

  const payment = builder<PaymentForContractDetailScreen>()
    .method(contract.paymentMethod)
    .count(contract.paymentCount)
    .build();

  const displayDetails = contract.contractDetails.reduce<DetailsForContractDetailScreen>(
    (prev: DetailsForContractDetailScreen, contractDetail: ContractDetailV2) => {
      if (isLessThanZero(contractDetail.unitPrice)) {
        const discount = builder<DiscountForContractDetailScreen>()
          .itemId(contractDetail.item ? contractDetail.item.itemId : uuid())
          .itemName(contractDetail.itemName)
          .itemCode(contractDetail.item ? contractDetail.item.itemCode || EMPTY : EMPTY)
          .unitPrice(contractDetail.unitPrice)
          .recurrenceCount(contractDetail.recurrenceCount)
          .build();

        prev.plan.discounts.push(discount);
        return prev;
      }
      if (!contractDetail.isDisabled && contractDetail.item?.itemCode === UsedContractItemCodeEnum.ADDRESS_USE) {
        prev.plan.useAddress = true;
        return prev;
      }
      if (!contractDetail.isDisabled && contractDetail.item?.itemCode === UsedContractItemCodeEnum.LOCKER_USE) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        prev.plan.lockerCount = parseInt(contractDetail.unitQuantity as any);
        return prev;
      }

      const option = builder<OptionForDetailScreen>()
        .name(contractDetail.item ? contractDetail.item.itemName : EMPTY)
        .unitPrice(contractDetail.unitPrice)
        .recurrenceCount(contractDetail.unitQuantity)
        .build();

      prev.plan.options.push(option);
      return prev;
    },
    { detail, plan, payment }
  );
  return displayDetails;
};

/*
 * 登録画面用
 */
export interface DetailForContractRegisterScreen {
  contractDetailId: Id;
  disabled: boolean;
  isDeletable: boolean;
  item?: ItemV2;
  itemName: Name;
  itemCode?: Code;
  quantity: Quantity;
  quantityInputType?: ContractQuantityInputType;
  recurrencePattern?: RecurrencePattern;
  recurrenceCount?: Count;
  taxCode?: Code;
  taxDiv?: TaxDiv;
  taxId?: TaxId;
  taxRate?: Rate;
  unitId?: UnitId;
  unitName?: Name;
  unitPrice: Price;
}

export interface DetailsForContractRegisterScreen {
  [RecurrencePattern.MONTHLY]: DetailForContractRegisterScreen[];
  [RecurrencePattern.AT_ONCE]: DetailForContractRegisterScreen[];
}

export const toMasterSearchOptionFromPlan = (plans: ContractPlanV2[]): MasterSearchOption[] =>
  plans.map((plan: ContractPlanV2) => ({
    label: plan.contractPlanName,
    value: plan.contractPlanId
  }));

export const toMasterSearchOptionFromCustomer = (customers: Customer[]): MasterSearchOption[] =>
  customers.map((customer: Customer) => ({
    label: customer.customerName,
    value: customer.customerId
  }));

export interface ContractDetail {
  contractDetailId: Id;
  disabled: boolean;
  isDeletable: boolean;
  itemId: ItemId;
  itemName: Name;
  itemCode?: Code;
  quantity: Quantity;
  quantityInputType?: ContractQuantityInputType;
  recurrencePattern?: RecurrencePattern;
  taxDiv?: TaxDiv;
  taxId?: TaxId;
  unitId?: UnitId;
  unitPrice: Price;
}
export interface ContractDetails {
  [RecurrencePattern.MONTHLY]: ContractDetail[];
  [RecurrencePattern.AT_ONCE]: ContractDetail[];
}

export const toInitialDisplayContractDetails = (plan: ContractPlanV2 | undefined): DetailsForContractRegisterScreen => {
  const contractDetails: DetailsForContractRegisterScreen = {
    [RecurrencePattern.MONTHLY]: [],
    [RecurrencePattern.AT_ONCE]: []
  };
  if (!plan) return contractDetails;

  const item = plan.item;
  const defaultOption = builder<DetailForContractRegisterScreen>()
    .contractDetailId(uuid())
    .disabled(false)
    .isDeletable(false)
    .item(item)
    .itemName(item!.itemName)
    .itemCode(item?.itemCode || EMPTY)
    .quantity(ONE)
    .quantityInputType(ContractQuantityInputType.MANUAL)
    .recurrencePattern(RecurrencePattern.MONTHLY)
    .taxDiv(item?.taxDiv)
    .taxId(item?.tax?.taxId)
    .taxRate(item?.tax?.taxRate)
    .taxCode(item?.tax?.taxCode)
    .unitId(item?.unit?.unitId)
    .unitName(item?.unit?.unitName)
    .unitPrice(item!.unitPrice)
    .build();
  contractDetails[RecurrencePattern.MONTHLY].push(defaultOption);

  if (plan.contractOptions) {
    const sortedContractOptions = plan.contractOptions?.sort((a, b) => (a.displayOrder > b.displayOrder ? ONE : -ONE));

    for (const option of sortedContractOptions) {
      const item = option.item;
      const defaultOption = builder<DetailForContractRegisterScreen>()
        .contractDetailId(uuid())
        .disabled(false)
        .isDeletable(false)
        .item(item)
        .itemName(item!.itemName)
        .itemCode(item?.itemCode || EMPTY)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .quantity(parseFloat(option.defaultQuantity as any))
        .quantityInputType(option.quantityInputType)
        .recurrencePattern(option.recurrencePattern)
        .taxDiv(item?.taxDiv)
        .taxId(item?.tax?.taxId)
        .taxRate(item?.tax?.taxRate)
        .taxCode(item?.tax?.taxCode)
        .unitId(item?.unit?.unitId)
        .unitName(item?.unit?.unitName)
        .unitPrice(item!.unitPrice)
        .build();
      contractDetails[option.recurrencePattern].push(defaultOption);
    }
  }
  return contractDetails;
};

export const toDefaultDiscountDetailsFromPlan = (
  plan: ContractPlanV2 | undefined
): DetailForContractRegisterScreen[] => {
  if (!plan || !plan.contractDiscounts || !hasLength(plan.contractDiscounts)) return [];

  const sortedContractDiscounts = plan.contractDiscounts?.sort((a, b) =>
    a.displayOrder > b.displayOrder ? ONE : -ONE
  );

  return sortedContractDiscounts.map(discount => {
    const item = discount.item;
    return (
      builder<DetailForContractRegisterScreen>()
        .contractDetailId(uuid())
        .disabled(false)
        .isDeletable(false)
        .item(item)
        .itemName(item!.itemName)
        .itemCode(item?.itemCode || EMPTY)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .quantity(parseFloat(discount.defaultQuantity as any))
        .recurrencePattern(RecurrencePattern.MONTHLY)
        .recurrenceCount(discount.defaultQuantity)
        .taxDiv(item?.taxDiv)
        .taxId(item?.tax?.taxId)
        .taxRate(item?.tax?.taxRate)
        .taxCode(item?.tax?.taxCode)
        .unitId(item?.unit?.unitId)
        .unitPrice(item!.unitPrice)
        .build()
    );
  });
};

export const toContractDetailsFromExistingContractDetails = (
  details: ContractDetailV2[]
): DetailsForContractRegisterScreen => {
  const contractDetails: DetailsForContractRegisterScreen = {
    [RecurrencePattern.MONTHLY]: [],
    [RecurrencePattern.AT_ONCE]: []
  };
  if (!hasLength(details)) return contractDetails;

  for (const detail of details) {
    const item = detail.item;
    const detailToSave = builder<DetailForContractRegisterScreen>()
      .contractDetailId(detail.contractDetailId)
      .disabled(detail.isDisabled)
      .isDeletable(detail.isDeletable)
      .itemName(detail.itemName)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .quantity(parseFloat(detail.unitQuantity as any))
      .recurrencePattern(detail.recurrencePattern)
      .recurrenceCount(detail.recurrenceCount)
      .unitPrice(detail.unitPrice);
    if (item) detailToSave.item(item);
    if (item) detailToSave.itemCode(item.itemCode || EMPTY);
    if (detail.taxDiv) detailToSave.taxDiv(detail.taxDiv);
    if (detail.tax) {
      detailToSave.taxId(detail.tax.taxId);
      detailToSave.taxCode(detail.tax.taxCode);
      detailToSave.taxRate(detail.tax.taxRate);
    }
    if (detail.unit) detailToSave.unitId(detail.unit.unitId);

    contractDetails[detail.recurrencePattern].push(detailToSave.build());
  }
  return contractDetails;
};

export const toDiscountDetailsFromExistingContractDetails = (
  details: ContractDetailV2[]
): DetailForContractRegisterScreen[] => {
  if (!hasLength(details)) return [];

  return details
    .filter(detail => isLessThanZero(detail.unitPrice))
    .map(discount => {
      const item = discount.item;
      const discountToSave = builder<DetailForContractRegisterScreen>()
        .contractDetailId(discount.contractDetailId)
        .disabled(discount.isDisabled)
        .isDeletable(discount.isDeletable)
        .itemName(discount.itemName)
        .unitPrice(discount.unitPrice)
        .recurrenceCount(discount.recurrenceCount)
        .recurrencePattern(RecurrencePattern.MONTHLY);
      if (item) discountToSave.item(item);
      if (item) discountToSave.itemCode(item.itemCode || EMPTY);
      if (discount.taxDiv) discountToSave.taxDiv(discount.taxDiv);
      if (discount.tax) {
        discountToSave.taxId(discount.tax.taxId);
        discountToSave.taxCode(discount.tax.taxCode);
        discountToSave.taxRate(discount.tax.taxRate);
      }
      if (discount.unit) discountToSave.unitId(discount.unit.unitId);

      return discountToSave.build();
    });
};

/*
 * 請求予定金額計算
 */
export interface PlanedBillingAmountDetail {
  itemName: Name;
  quantity?: Quantity;
  unitName?: Name;
  recurrenceCount?: Count;
  taxExcludedSubtotalPrice?: Price;
  taxIncludedSubtotalPrice?: Price;
  taxDiv?: TaxDiv;
  taxRate?: Rate;
  unitPrice: Price;
}

export interface PlanedBillingDetailByTax {
  taxDiv: TaxDiv;
  taxIncludedTotalPrice: Price;
  taxExcludedTotalPrice: Price;
  taxRate: Rate;
}

export interface PlanedBillingSubtotalPricesByTax {
  [key: Key]: PlanedBillingDetailByTax;
}

export interface PlanedBillingAmounts {
  taxIncludedTotalPrice: Price;
  taxExcludedTotalPrice: Price;
  subtotalPricesByTax: PlanedBillingSubtotalPricesByTax;
  details: PlanedBillingAmountDetail[];
}

const createBillingSubtotalPricesByTax = (
  planedBillingSubtotalPricesByTax: PlanedBillingSubtotalPricesByTax,
  taxId: TaxId,
  taxDiv: TaxDiv,
  taxRate: Rate,
  includedPrice: Price,
  excludedPrice: Price
): PlanedBillingSubtotalPricesByTax => {
  const taxKey = getTaxKey(taxId, taxDiv, taxRate);
  if (!planedBillingSubtotalPricesByTax[taxKey]) {
    planedBillingSubtotalPricesByTax[taxKey] = {
      taxDiv: taxDiv,
      taxIncludedTotalPrice: ZERO,
      taxExcludedTotalPrice: ZERO,
      taxRate: taxRate
    };
  }
  planedBillingSubtotalPricesByTax[taxKey].taxIncludedTotalPrice += includedPrice;
  planedBillingSubtotalPricesByTax[taxKey].taxExcludedTotalPrice += excludedPrice;
  return planedBillingSubtotalPricesByTax;
};

export const toPlanedBillingAmounts = (
  details: DetailForContractRegisterScreen[],
  discounts: DetailForContractRegisterScreen[]
): PlanedBillingAmounts => {
  let taxIncludedTotalPrice = 0;
  let taxExcludedTotalPrice = 0;
  const planedBillingSubtotalPricesByTax: PlanedBillingSubtotalPricesByTax = {};
  const planedBillingAmountDetails: PlanedBillingAmountDetail[] = [];

  for (const detail of details) {
    const { disabled, itemName, quantity, taxId, taxDiv, taxRate, unitPrice, unitName } = detail;
    if (disabled || !itemName || !quantity || !taxId || !taxDiv || taxRate == null || !unitPrice || isNaN(unitPrice))
      continue;
    const includedPrice = calcTaxIncludedAmountPrice(unitPrice, quantity, taxDiv, taxRate);
    const excludedPrice = toTaxExcluded(includedPrice, taxRate);
    createBillingSubtotalPricesByTax(
      planedBillingSubtotalPricesByTax,
      taxId,
      taxDiv,
      taxRate,
      includedPrice,
      excludedPrice
    );

    taxIncludedTotalPrice += includedPrice;
    taxExcludedTotalPrice += excludedPrice;

    const displayPlanedBillingAmountDetail = builder<PlanedBillingAmountDetail>()
      .itemName(itemName)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .quantity(parseFloat(quantity as any))
      .unitName(unitName as Name)
      .taxDiv(taxDiv)
      .taxExcludedSubtotalPrice(excludedPrice)
      .taxIncludedSubtotalPrice(includedPrice)
      .taxRate(taxRate)
      .unitPrice(unitPrice)
      .build();
    planedBillingAmountDetails.push(displayPlanedBillingAmountDetail);
  }

  for (const discount of discounts) {
    const { disabled, itemName, recurrenceCount, taxDiv, taxId, taxRate, unitPrice } = discount;
    if (
      disabled ||
      !itemName ||
      !recurrenceCount ||
      !taxId ||
      !taxDiv ||
      taxRate == null ||
      !unitPrice ||
      isNaN(unitPrice)
    )
      continue;
    const includedPrice = calcTaxIncludedAmountPrice(unitPrice, ONE, taxDiv, taxRate);
    const excludedPrice = toTaxExcluded(includedPrice, taxRate);

    createBillingSubtotalPricesByTax(
      planedBillingSubtotalPricesByTax,
      taxId,
      taxDiv,
      taxRate,
      includedPrice,
      excludedPrice
    );

    taxIncludedTotalPrice += includedPrice;
    taxExcludedTotalPrice += excludedPrice;

    const displayPlanedBillingAmountDiscount = builder<PlanedBillingAmountDetail>()
      .itemName(itemName)
      .recurrenceCount(recurrenceCount)
      .taxDiv(taxDiv)
      .taxExcludedSubtotalPrice(excludedPrice)
      .taxIncludedSubtotalPrice(includedPrice)
      .taxRate(taxRate)
      .unitPrice(unitPrice)
      .quantity(ONE)
      .build();

    planedBillingAmountDetails.push(displayPlanedBillingAmountDiscount);
  }

  return {
    taxIncludedTotalPrice,
    taxExcludedTotalPrice,
    subtotalPricesByTax: planedBillingSubtotalPricesByTax,
    details: planedBillingAmountDetails
  };
};
