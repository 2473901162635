import { ScreenV2, themeV2, themeV3 } from '@atomica.co/components';
import { Index, Message, Name, Text, Title, URL } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import React, { CSSProperties } from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import ErrorMessage from '../errors/ErrorMessage';
import atomica from './../../assets/atomica.gif';
import mojaco from './../../assets/mojaco/mojaco_thanks.png';

type LoadingType = 'circular' | 'image';

interface CompletionScreenProps {
  style?: CSSProperties;
  loading?: boolean;
  loadingType?: LoadingType;
  errorMsg?: Message;
  title?: Title;
  className?: Name;
  text?: Text;
  children?: React.ReactNode;
}

const CompletionScreen: React.FC<CompletionScreenProps> = React.memo(props => {
  const {
    style,
    loading = false,
    loadingType = 'image',
    errorMsg = EMPTY,
    title = '登録が完了しました！',
    className = 'CompletionScreen-screen',
    text,
    children
  } = props;

  if (errorMsg) {
    return <ErrorMessage message={errorMsg} />;
  }

  return (
    <ScreenV2
      style={style}
      loading={loading}
      src={loadingType === 'image' ? atomica : undefined}
      className={className}
      title={title}
    >
      <Container>
        <TitleText>{title}</TitleText>
        <Mojaco src={mojaco} />
        <Linkify
          componentDecorator={(decoratedHref: URL, decoratedText: Text, key: Index) => (
            <a href={decoratedHref} key={key} target='_blank' rel='noreferrer'>
              {decoratedText}
            </a>
          )}
        >
          {text && <Content data-testid='questionnaire-thanks-message'>{text}</Content>}
        </Linkify>
        {children}
      </Container>
    </ScreenV2>
  );
});

CompletionScreen.displayName = 'CompletionScreen';
export default CompletionScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2.25}px;
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px;

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const TitleText = styled.div`
  ${themeV2.mixins.v2.typography.headLine.small};
  ${themeV3.mixins.v3.color.object.black};
`;

const Content = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  ${themeV3.mixins.v3.color.object.black};
  white-space: pre-wrap;
`;

const Mojaco = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  user-select: none;
`;
