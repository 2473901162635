import React, { useEffect, useMemo } from 'react';
import { isAuthGuarded as guarded } from '../../converters/path-converter';
import usePath from '../../redux/hooks/usePath';
import GuardedScreens from './guarded-screens/GuardedScreens';
import NoGuardedScreens from './no-guarded-screens/NoGuardedScreens';
import { datadogRum } from '@datadog/browser-rum';
import { EnvDef } from '@atomica.co/utils';
import env from '../../env/env';

if (String(env) === EnvDef.PRODUCTION) {
  datadogRum.init({
    applicationId: '5d44145b-72f6-4d8c-a3f8-7c0c4118342f',
    clientToken: 'pubfa91d03fdb8253291e6b213f8b9b61db',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'ap1.datadoghq.com',
    service: 'knotplace',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process?.env?.CI_COMMIT_SHORT_SHA ?? 'unknown',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input'
  });
}

const Root: React.FC = React.memo(() => {
  const { path, addBrowserBackEventListener, removeBrowserBackEventListener } = usePath();
  const isAuthGuard = useMemo<boolean>(() => guarded(path), [path]);

  useEffect(() => {
    addBrowserBackEventListener();
    return () => removeBrowserBackEventListener();
  }, [addBrowserBackEventListener, removeBrowserBackEventListener]);

  return <>{isAuthGuard ? <GuardedScreens /> : <NoGuardedScreens />}</>;
});

Root.displayName = 'Root';
export default Root;
