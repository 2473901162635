import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { AccommodationPlan, BaseResourceCategory, SpaceReservationId } from '@atomica.co/irori';
import { Count } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { toDataForSaveSpaceReservationAutomatically } from '../actions/data-for-save-sapece-reservation-automatically-action';
import store from '../store';

export interface CachedDataForSaveSpaceReservationAutomatically {
  baseResourceCategory: BaseResourceCategory;
  spacePlan: AccommodationPlan;
  startAt: Date | null;
  endAt: Date | null;
  roomCount: Count;
  selectedParentSpaceReservationId: SpaceReservationId;
}

interface P {
  cachedDataForSaveSpaceReservationAutomatically: CachedDataForSaveSpaceReservationAutomatically;
  saveCachedDataForSaveSpaceReservationAutomatically: (
    spaceReservation: CachedDataForSaveSpaceReservationAutomatically
  ) => void;
  clearCachedDataForSaveSpaceReservationAutomatically: () => void;
}

export const initialCachedDataForSaveSpaceReservationAutomatically: CachedDataForSaveSpaceReservationAutomatically = {
  baseResourceCategory: new BaseResourceCategory(),
  spacePlan: new AccommodationPlan(),
  startAt: new Date(),
  endAt: new Date(),
  roomCount: 0,
  selectedParentSpaceReservationId: EMPTY
};

function useCachedDataForSaveSpaceReservationAutomatically(): P {
  const unmountRef = useUnmountRef();
  const [cachedDataForSaveSpaceReservationAutomatically, setCachedDataForSaveSpaceReservationAutomatically] =
    useSafeState<CachedDataForSaveSpaceReservationAutomatically>(
      unmountRef,
      store.getState().cachedDataForSaveSpaceReservationAutomatically
    );

  const saveCachedDataForSaveSpaceReservationAutomatically = useSafeCallback(
    (spaceReservation: CachedDataForSaveSpaceReservationAutomatically): void => {
      setCachedDataForSaveSpaceReservationAutomatically(spaceReservation);
      const action = toDataForSaveSpaceReservationAutomatically(spaceReservation);
      store.dispatch(action);
    },
    [setCachedDataForSaveSpaceReservationAutomatically]
  );

  const clearCachedDataForSaveSpaceReservationAutomatically = useSafeCallback((): void => {
    setCachedDataForSaveSpaceReservationAutomatically(initialCachedDataForSaveSpaceReservationAutomatically);
    const action = toDataForSaveSpaceReservationAutomatically(initialCachedDataForSaveSpaceReservationAutomatically);
    store.dispatch(action);
  }, [setCachedDataForSaveSpaceReservationAutomatically]);

  return {
    cachedDataForSaveSpaceReservationAutomatically,
    saveCachedDataForSaveSpaceReservationAutomatically,
    clearCachedDataForSaveSpaceReservationAutomatically
  } as P;
}

export default useCachedDataForSaveSpaceReservationAutomatically;
