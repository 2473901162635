import { CheckBoxV2, customMedia, themeV2, themeV3, useSafeCallback } from '@atomica.co/components';
import { Count, Id, Rate, Time } from '@atomica.co/types';
import React, { useMemo } from 'react';
import styled from 'styled-components';

interface P {
  id: Id;
  selectedIds: Id[];
  handleClicked: (spacesId: Id) => void;
  children: React.ReactNode;
  canLimitation?: boolean /** 予約選択に制限を設けるか */;
  availableTimes?: { startAt: Time; endAt: Time; dayOfWeek: Count }[];
  crowdingRatio?: Rate;
}

const parseUTCTimeToJTCMinutes = (timeStr: string): number => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  const jstHour = (hours + 9) % 24;
  return jstHour * 60 + minutes;
};

const SpaceCard: React.FC<P> = React.memo(props => {
  const { id, selectedIds, handleClicked, children, availableTimes, canLimitation, crowdingRatio } = props;

  const withinAvailableTime = useMemo(() => {
    if (!availableTimes?.length) return false;

    const now = new Date();
    const currentDay = now.getDay();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    return availableTimes.some(timeSlot => {
      const isDayAvailable = timeSlot.dayOfWeek === currentDay;

      if (!isDayAvailable) return false;

      const startTimeInMinutes = parseUTCTimeToJTCMinutes(timeSlot.startAt);
      const endTimeInMinutes = parseUTCTimeToJTCMinutes(timeSlot.endAt);

      return currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes <= endTimeInMinutes;
    });
  }, [availableTimes]);

  const isCapcityLimit = useMemo(() => (crowdingRatio ?? 0) >= 100, [crowdingRatio]);

  const isDisabled = useMemo(() => {
    if (!canLimitation) return false;
    return !withinAvailableTime || isCapcityLimit;
  }, [canLimitation, withinAvailableTime, isCapcityLimit]);

  const handleCardClick = useSafeCallback(() => {
    if (!isDisabled) {
      handleClicked(id);
    }
  }, [handleClicked, id, isDisabled]);

  return (
    <Container $isDisabled={isDisabled}>
      <CustomCard onClick={handleCardClick} isBorderShown={selectedIds.includes(id)}>
        <CardContent data-testid='drop-in-space'>
          <CheckBoxV2 shrink checked={selectedIds.includes(id)} onChange={handleCardClick} disabled={isDisabled} />
          {children}
        </CardContent>
      </CustomCard>
    </Container>
  );
});

SpaceCard.displayName = 'SpaceCard';
export default SpaceCard;

const Container = styled.div<{ $isDisabled: boolean }>`
  max-width: 330px;
  width: 100%;
  position: relative;
  opacity: ${props => (props.$isDisabled ? 0.5 : 1)};
  color: ${themeV2.mixins.v2.color.font.white};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    `
    &:hover {
    cursor: pointer;
  }
  `}

  ${customMedia.lessThan('small')`
    max-width: 100%;
    width: 100%;
  `};
`;

const CustomCard = styled.div<{ isBorderShown: boolean }>`
  position: relative;
  visibility: visible;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  ${({ isBorderShown }) =>
    `border: 2px solid ${isBorderShown ? themeV3.mixins.v3.color.border.primary : 'transparent'};`}
  border-radius: 16px;
`;

const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing * 1.5}px;
  min-height: 160px;
`;
