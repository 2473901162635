import {
  ACCESS_DIRECTION,
  ACCESS_ID_V2,
  ACCESS_OPERATION_DATETIME,
  BASE,
  BASE_BASE_NAME,
  BASE_CODE,
  CONTRACT_CONTRACT_NAME,
  CONTRACT_CONTRACT_STATUS,
  CONTRACT_CONTRACTOR_EMAIL,
  CONTRACT_DISCOUNT_AMOUNT,
  CONTRACT_END_DATE,
  CONTRACT_ID,
  CONTRACT_INFLOW_SOURCE,
  CONTRACT_INIT_DISCOUNT_AMOUNT,
  CONTRACT_NUMBER_OF_LOCKERS,
  CONTRACT_PAYMENT_COUNT,
  CONTRACT_PAYMENT_METHOD,
  CONTRACT_PLAN_CONTRACT_PLAN_NAME,
  CONTRACT_REMARKS,
  CONTRACT_REPRESENTATIVE_NAME,
  CONTRACT_SERVICE_RETAINER,
  CONTRACT_START_DATE,
  CONTRACT_TAX_EXCLUDED_ADDRESS_PRICE,
  CONTRACT_TAX_EXCLUDED_KEY_ISSUE_PRICE,
  CONTRACT_TAX_EXCLUDED_LOCKER_PRICE,
  CONTRACT_TAX_EXCLUDED_PLAN_PRICE,
  CONTRACT_TAX_INCLUDED_ADDRESS_PRICE,
  CONTRACT_TAX_INCLUDED_KEY_ISSUE_PRICE,
  CONTRACT_TAX_INCLUDED_LOCKER_PRICE,
  CONTRACT_TAX_INCLUDED_PLAN_PRICE,
  CONTRACT_TAX_INCLUDED_TOTAL_PRICE,
  CONTRACT_USAGE_CONTRACT_MSEC,
  CONTRACT_USAGE_ID,
  CONTRACT_USAGE_REMAINING_MSEC,
  CONTRACT_USAGE_USAGE_MSEC,
  CONTRACT_USAGE_YEAR_AND_MONTH,
  CONTRACT_USER_COUNT,
  END_DATE,
  IS_CONTRACT_USER,
  LINE_ID,
  SHOT_CARD_NO,
  SHOT_CONFERENCE_NUMBER_OF_PEOPLE,
  SHOT_CONFERENCE_ONE_DAY_NUMBER_OF_PEOPLE,
  SHOT_CONFERENCE_ONE_DAY_USAGE,
  SHOT_CONFERENCE_USAGE_HOURS,
  SHOT_CONFERENCE_USAGE_ITEM,
  SHOT_ID,
  SHOT_OPTION_QUANTITY,
  SHOT_PAYMENT_METHOD,
  SHOT_PERSON_IN_CHARGE,
  SHOT_RECEIPT_NAME,
  SHOT_REMARKS,
  SHOT_TAX_EXCLUDED_OPTION_PRICE,
  SHOT_TAX_EXCLUDED_TOTAL_PRICE,
  SHOT_TAX_EXCLUDED_UNIT_PRICE,
  SHOT_TAX_INCLUDED_OPTION_PRICE,
  SHOT_TAX_INCLUDED_TOTAL_PRICE,
  SHOT_TAX_INCLUDED_UNIT_PRICE,
  SHOT_TOTAL_PRICE,
  SHOT_UNIT_QUANTITY,
  SHOT_USAGE_ITEM,
  SHOT_USAGE_OPTION_ITEM,
  SPACE_RESERVATION_ID,
  SPACE_RESERVATIONS_CREATED_AT,
  SPACE_RESERVATIONS_END_AT,
  SPACE_RESERVATIONS_PRICE_PER_HOUR,
  SPACE_RESERVATIONS_REMARKS,
  SPACE_RESERVATIONS_START_AT,
  SPACE_RESERVATIONS_TAX_RATE,
  SPACE_SPACE_CODE,
  SPACE_SPACE_NAME,
  START_DATE,
  STRIPE_OBJECT_ID,
  STRIPE_OBJECT_ID_TYPE,
  USER_AUTHORITY,
  USER_CITY,
  USER_COMPANY_NAME,
  USER_DATE_OF_BIRTH_V2,
  USER_DIV,
  USER_DIV_OTHER,
  USER_EMAIL,
  USER_FAMILY_NAME,
  USER_FIRST_NAME,
  USER_GENDER,
  USER_IDENTIFICATION,
  USER_INFLOW_SOURCE,
  USER_INFLOW_SOURCE_OTHER,
  USER_PHONE,
  USER_PHOTO_URL,
  USER_PREFECTURE,
  USER_RANK,
  USER_SCHOOL_NAME,
  USER_STUDENT_DIV,
  USER_STUDENT_DIV_OTHER
} from '@atomica.co/irori';
import { USER_ID } from '@atomica.co/types';
import { CREATED_AT } from '@atomica.co/utils';
import { Headers } from 'react-csv/lib/core';
import { ExportTarget } from '../enums/export-enum';
import { CSVTemplate, Labels } from '../models/common-model';

export const EXPORT_LABELS: Labels = {
  [ExportTarget.DROP_IN]: 'ドロップイン履歴',
  [ExportTarget.SHOT_USAGE]: '入館受付利用履歴',
  [ExportTarget.CONTRACT]: '契約情報',
  [ExportTarget.CONTRACT_USAGE]: '契約利用状況',
  [ExportTarget.ACCESS]: '入退室履歴',
  // [ExportTarget.MEETAT_PARTICIPANT]: 'MEET@参加者'
  [ExportTarget.SPACE_RESERVATION]: '予約情報',
  [ExportTarget.USER]: 'ユーザー',
  [ExportTarget.SPACE_RESERVATION_PAYMENT]: '予約請求'
};

export const USERS_HEADERS: CSVTemplate[] = [
  {
    key: USER_ID,
    label: 'ユーザーID'
  },
  {
    key: START_DATE,
    label: '利用開始日'
  },
  {
    key: END_DATE,
    label: '利用終了日'
  },
  {
    key: USER_FAMILY_NAME,
    label: '姓'
  },
  {
    key: USER_FIRST_NAME,
    label: '名'
  },
  {
    key: USER_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: USER_GENDER,
    label: '性別'
  },
  {
    key: USER_DATE_OF_BIRTH_V2,
    label: '生年月日'
  },
  {
    key: USER_PREFECTURE,
    label: '都道府県'
  },
  {
    key: USER_CITY,
    label: '市区町村'
  },
  {
    key: USER_DIV,
    label: '職業'
  },
  {
    key: USER_DIV_OTHER,
    label: '職業(その他)'
  },
  {
    key: USER_COMPANY_NAME,
    label: 'お勤め先'
  },
  {
    key: USER_STUDENT_DIV,
    label: '学年'
  },
  {
    key: USER_STUDENT_DIV_OTHER,
    label: '学年(その他)'
  },
  {
    key: USER_SCHOOL_NAME,
    label: '学校'
  },
  {
    key: USER_INFLOW_SOURCE,
    label: 'ご利用のきっかけ'
  },
  {
    key: USER_INFLOW_SOURCE_OTHER,
    label: 'ご利用のきっかけ(その他)'
  },
  // {
  //   key: USER_USAGE_PURPOSE,
  //   label: 'ご利用の目的'
  // },
  // {
  //   key: USER_USAGE_PURPOSE_OTHER,
  //   label: 'ご利用の目的(その他)'
  // },
  // {
  //   key: USER_FAMILY_MEMBER_MAPPINGS,
  //   label: '同居家族'
  // },
  {
    key: USER_PHOTO_URL,
    label: '画像URL'
  },
  {
    key: USER_AUTHORITY,
    label: '権限'
  },
  {
    key: USER_RANK,
    label: '会員ランク'
  },
  {
    key: USER_IDENTIFICATION,
    label: '本人確認'
  },
  {
    key: USER_PHONE,
    label: '電話番号'
  },
  {
    key: LINE_ID,
    label: 'LINE ID'
  }
];

export const SHOT_HEADERS: CSVTemplate[] = [
  {
    key: SHOT_ID,
    label: '利用実績ID'
  },
  {
    key: BASE,
    label: '利用拠点'
  },
  {
    key: CREATED_AT,
    label: '利用日時'
  },
  {
    key: SHOT_USAGE_ITEM,
    label: 'ご利用内容'
  },
  {
    key: SHOT_USAGE_OPTION_ITEM,
    label: 'オプション'
  },
  {
    key: SHOT_CONFERENCE_USAGE_ITEM,
    label: '利用設備'
  },
  {
    key: SHOT_CONFERENCE_USAGE_HOURS,
    label: '利用時間'
  },
  {
    key: SHOT_CONFERENCE_NUMBER_OF_PEOPLE,
    label: '利用人数'
  },
  {
    key: SHOT_CONFERENCE_ONE_DAY_USAGE,
    label: '1DAY併用の有無'
  },
  {
    key: SHOT_CONFERENCE_ONE_DAY_NUMBER_OF_PEOPLE,
    label: '1DAY併用人数'
  },
  {
    key: SHOT_RECEIPT_NAME,
    label: '領収書の宛名'
  },
  {
    key: SHOT_CARD_NO,
    label: '貸出カードNo.'
  },
  {
    key: SHOT_TAX_EXCLUDED_UNIT_PRICE,
    label: '単価(税抜)'
  },
  {
    key: SHOT_TAX_INCLUDED_UNIT_PRICE,
    label: '単価(税込)'
  },
  {
    key: SHOT_UNIT_QUANTITY,
    label: '単価数量'
  },
  {
    key: SHOT_TAX_EXCLUDED_OPTION_PRICE,
    label: 'オプション(税抜)'
  },
  {
    key: SHOT_TAX_INCLUDED_OPTION_PRICE,
    label: 'オプション(税込)'
  },
  {
    key: SHOT_OPTION_QUANTITY,
    label: 'オプション数量'
  },
  {
    key: SHOT_TAX_EXCLUDED_TOTAL_PRICE,
    label: '合計金額(税抜)'
  },
  {
    key: SHOT_TAX_INCLUDED_TOTAL_PRICE,
    label: '合計金額(税込)'
  },
  {
    key: SHOT_TOTAL_PRICE,
    label: '請求金額(税込)'
  },
  {
    key: SHOT_PAYMENT_METHOD,
    label: '支払い方法'
  },
  {
    key: SHOT_PERSON_IN_CHARGE,
    label: '担当者'
  },
  {
    key: SHOT_REMARKS,
    label: '備考'
  },
  ...USERS_HEADERS
];

export const CONTRACT_HEADERS: CSVTemplate[] = [
  {
    key: CONTRACT_ID,
    label: '契約ID'
  },
  {
    key: BASE,
    label: '契約拠点'
  },
  {
    key: CONTRACT_CONTRACT_NAME,
    label: '契約名称'
  },
  {
    key: CONTRACT_CONTRACT_STATUS,
    label: '契約状況'
  },
  {
    key: CONTRACT_START_DATE,
    label: '契約開始日'
  },
  {
    key: CONTRACT_END_DATE,
    label: '契約終了日'
  },
  {
    key: CONTRACT_PLAN_CONTRACT_PLAN_NAME,
    label: '契約プラン'
  },
  {
    key: CONTRACT_REPRESENTATIVE_NAME,
    label: '代表者名'
  },
  {
    key: CONTRACT_CONTRACTOR_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: CONTRACT_PAYMENT_METHOD,
    label: '支払い方法'
  },
  {
    key: CONTRACT_PAYMENT_COUNT,
    label: '支払い回数'
  },
  {
    key: CONTRACT_USER_COUNT,
    label: '鍵発行数'
  },
  {
    key: CONTRACT_NUMBER_OF_LOCKERS,
    label: 'ロッカー契約数'
  },
  {
    key: CONTRACT_TAX_EXCLUDED_PLAN_PRICE,
    label: 'プラン料金（税抜）'
  },
  {
    key: CONTRACT_TAX_INCLUDED_PLAN_PRICE,
    label: 'プラン料金（税込）'
  },
  {
    key: CONTRACT_TAX_EXCLUDED_KEY_ISSUE_PRICE,
    label: '鍵発行料金（税抜）'
  },
  {
    key: CONTRACT_TAX_INCLUDED_KEY_ISSUE_PRICE,
    label: '鍵発行料金（税込）'
  },
  {
    key: CONTRACT_TAX_EXCLUDED_LOCKER_PRICE,
    label: 'ロッカー料金（税抜）'
  },
  {
    key: CONTRACT_TAX_INCLUDED_LOCKER_PRICE,
    label: 'ロッカー料金（税込）'
  },
  {
    key: CONTRACT_TAX_EXCLUDED_ADDRESS_PRICE,
    label: '住所利用料金（税抜）'
  },
  {
    key: CONTRACT_TAX_INCLUDED_ADDRESS_PRICE,
    label: '住所利用料金（税込）'
  },
  {
    key: CONTRACT_SERVICE_RETAINER,
    label: 'サービスリテイナー'
  },
  {
    key: CONTRACT_INIT_DISCOUNT_AMOUNT,
    label: '初月割引額'
  },
  {
    key: CONTRACT_DISCOUNT_AMOUNT,
    label: '割引額'
  },
  {
    key: CONTRACT_TAX_INCLUDED_TOTAL_PRICE,
    label: '合計金額（税込）'
  },
  {
    key: CONTRACT_INFLOW_SOURCE,
    label: '流入元'
  },
  {
    key: CREATED_AT,
    label: '登録日時'
  },
  {
    key: CONTRACT_REMARKS,
    label: '備考'
  }
];

export const CONTRACT_USAGE_HEADERS: CSVTemplate[] = [
  {
    key: CONTRACT_USAGE_ID,
    label: '契約利用ID'
  },
  {
    key: BASE,
    label: '契約拠点'
  },
  {
    key: CONTRACT_USAGE_YEAR_AND_MONTH,
    label: '対象年月'
  },
  {
    key: CONTRACT_CONTRACT_NAME,
    label: '契約名称'
  },
  {
    key: CONTRACT_PLAN_CONTRACT_PLAN_NAME,
    label: '契約プラン'
  },
  {
    key: CONTRACT_USAGE_CONTRACT_MSEC,
    label: '契約時間'
  },
  {
    key: CONTRACT_USAGE_USAGE_MSEC,
    label: '利用時間'
  },
  {
    key: CONTRACT_USAGE_REMAINING_MSEC,
    label: '残り時間'
  },
  {
    key: CONTRACT_CONTRACT_STATUS,
    label: '契約状況'
  },
  {
    key: CONTRACT_START_DATE,
    label: '契約開始日'
  },
  {
    key: CONTRACT_END_DATE,
    label: '契約終了日'
  },
  {
    key: CONTRACT_REPRESENTATIVE_NAME,
    label: '代表者名'
  },
  {
    key: CONTRACT_CONTRACTOR_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: CONTRACT_USER_COUNT,
    label: '鍵発行数'
  },
  {
    key: CONTRACT_PAYMENT_METHOD,
    label: '支払い方法'
  },
  {
    key: CONTRACT_REMARKS,
    label: '備考'
  }
];

export const ACCESS_HEADERS: CSVTemplate[] = [
  {
    key: ACCESS_ID_V2,
    label: '入退室履歴ID'
  },
  {
    key: BASE_CODE,
    label: '利用拠点'
  },
  {
    key: ACCESS_DIRECTION,
    label: '区分'
  },
  {
    key: ACCESS_OPERATION_DATETIME,
    label: '入退室時刻'
  },
  {
    key: USER_ID,
    label: 'ユーザーID'
  },
  {
    key: USER_FAMILY_NAME,
    label: '姓'
  },
  {
    key: USER_FIRST_NAME,
    label: '名'
  },
  {
    key: USER_EMAIL,
    label: 'メールアドレス'
  }
];

export const SPACE_RESERVATION_HEADERS: CSVTemplate[] = [
  {
    key: SPACE_RESERVATION_ID,
    label: '予約ID'
  },
  {
    key: BASE_CODE,
    label: '拠点コード'
  },
  {
    key: BASE_BASE_NAME,
    label: '拠点名称'
  },
  {
    key: SPACE_SPACE_CODE,
    label: '設備コード'
  },
  {
    key: SPACE_SPACE_NAME,
    label: '設備名称'
  },
  {
    key: SPACE_RESERVATIONS_START_AT,
    label: '利用開始日時'
  },
  {
    key: SPACE_RESERVATIONS_END_AT,
    label: '利用終了日時'
  },
  {
    key: SPACE_RESERVATIONS_PRICE_PER_HOUR,
    label: '利用単価'
  },
  {
    key: SPACE_RESERVATIONS_TAX_RATE,
    label: '税率（%）'
  },
  {
    key: SPACE_RESERVATIONS_REMARKS,
    label: '備考'
  },
  {
    key: SPACE_RESERVATIONS_CREATED_AT,
    label: '予約日時'
  },
  {
    key: USER_FAMILY_NAME,
    label: '予約者姓'
  },
  {
    key: USER_FIRST_NAME,
    label: '予約者名'
  },
  {
    key: USER_EMAIL,
    label: '予約者メールアドレス'
  }
];

export const SPACE_RESERVATION_WITH_IS_CONTRACT_USER_HEADERS: CSVTemplate[] = [
  ...SPACE_RESERVATION_HEADERS,
  {
    key: IS_CONTRACT_USER,
    label: '契約有無'
  }
];

export const SPACE_RESERVATION_PAYMENTS_HEADERS: CSVTemplate[] = [
  ...SPACE_RESERVATION_HEADERS,
  {
    key: STRIPE_OBJECT_ID_TYPE,
    label: 'stripe請求ID種別'
  },
  {
    key: STRIPE_OBJECT_ID,
    label: 'stripe請求ID'
  }
];

export const DROP_IN_HEADERS = [
  { key: 'dropInId', label: 'ドロップインID' },
  { key: 'spaceName', label: 'スペース名' },
  { key: 'planName', label: 'プラン名' },
  { key: 'startAt', label: '開始日時' },
  { key: 'endAt', label: '終了日時' },
  { key: 'paidAt', label: '決済日時' },
  { key: 'userName', label: 'ユーザー名' },
  { key: 'email', label: 'メールアドレス' },
  { key: 'stripeObjectId', label: 'stripe請求ID' },
  { key: 'billingAmount', label: '料金' }
] satisfies Headers;
