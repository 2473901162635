import { CommentBox, Component, themeV3 } from '@atomica.co/components';
import { Text } from '@atomica.co/types';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import mojaco_greeting from './../../../assets/mojaco/mojaco_greeting.png';

interface P extends RouteComponentProps {
  text: Text;
}

const MojakoBubble: React.FC<P> = React.memo(props => {
  const { text } = props;

  return (
    <Component
      style={{
        width: '100%',
        padding: `${themeV3.mixins.spacing * 4}px 0 ${themeV3.mixins.spacing * 2}px`
      }}
      className='mojako-bubble'
      data-testid='mojako-bubble'
    >
      <CommentBox photoURL={mojaco_greeting}>
        <Bubble>{text}</Bubble>
      </CommentBox>
    </Component>
  );
});

MojakoBubble.displayName = 'MojakoBubble';
export default MojakoBubble;

const Bubble = styled.div`
  width: calc(100% - ${themeV3.mixins.spacing * 3}px);
  height: auto;
  color: ${themeV3.mixins.v3.color.object.black};
  ${themeV3.mixins.v3.typography.title.medium};
  margin: ${themeV3.mixins.v3.spacing}px ${themeV3.mixins.v3.spacing}px ${themeV3.mixins.v3.spacing}px
    ${themeV3.mixins.v3.spacing * 2}px;
  text-decoration: underline;
  text-decoration-color: ${themeV3.mixins.v3.color.border.gray};
  text-underline-offset: 4px;
  line-height: 32px;
`;
