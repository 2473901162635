import { customMedia, themeV2 } from '@atomica.co/components';
import React from 'react';
import styled from 'styled-components';
import knotplace from '../../../assets/logo/logo_knot_place_v2.png';
import workhub from '../../../assets/logo/logo_workhub.png';

const RegisterAccountSummary: React.FC = React.memo(() => {
  return (
    <Container>
      <Content>
        <NoticeWrapper>
          <Text>施設の利用時には、</Text>
          <Text>・knotPLACE</Text>
          <Text>・workhub Pass</Text>
          <Text>の2種類のサービスを利用するので、workhub Passへアカウント情報を共有します。</Text>
          <AdditionalText>※お客様がworkhub Passを直接ご利用することはございません</AdditionalText>
        </NoticeWrapper>
        <LogosWrapper>
          <KnotPlaceLogo src={knotplace} />
          <Text>↔</Text>
          <WorkhubLogo src={workhub} />
        </LogosWrapper>
        <NoticeWrapper>
          <Text>連携した上で出来ること</Text>
          <Text>・入館受付</Text>
          <Text>・イベント情報の閲覧・参加申し込み</Text>
          <Text>・スペースの予約</Text>
          <Text>・利用料金の決済</Text>
        </NoticeWrapper>
      </Content>
    </Container>
  );
});

RegisterAccountSummary.displayName = 'RegisterAccountSummary';
export default RegisterAccountSummary;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const AdditionalText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;

const NoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogosWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  ${customMedia.lessThan('tiny')`
    gap: ${themeV2.mixins.v2.spacing}px;
  `}
  background-color: ${themeV2.mixins.v2.color.background.white};
`;

const KnotPlaceLogo = styled.img`
  width: auto;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  height: 56px;
  ${customMedia.lessThan('tiny')`
    height: 50px;
  `}
`;
const WorkhubLogo = styled.img`
  width: auto;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  height: 42px;
  ${customMedia.lessThan('tiny')`
    max-height: 40px;
  `}
`;
