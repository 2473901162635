import {
  BackButtonV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityDefCategory,
  BaseDto,
  EVENT_SCHEDULE_ID_V2,
  EventMembership,
  EventScheduleIdV2,
  EventScheduleParticipantV2,
  EventScheduleV2,
  EventV2,
  FETCH_EVENT_SCHEDULE_V2,
  FetchEventScheduleV2Request,
  FetchEventScheduleV2Response,
  SEARCH_SPACES,
  SearchSpacesRequest,
  SearchSpacesResponse,
  Space,
  SpaceCategory,
  User,
  UserPoint
} from '@atomica.co/irori';
import { Count, Index, Label, Point, Title, Width } from '@atomica.co/types';
import { EMPTY_HALF_WIDTH, FIRST_INDEX, ZERO, builder, embedIdInPath, hasLength, isZero } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import media from 'styled-media-query';
import Screen from '../../../components/screen/Screen';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { EventScheduleParticipantStatusEnum } from '../../../enums/event-v2-enum';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../../router/Routes';
import { getBasePICV2 } from '../../../utils/user-util';
import AccountEventDetail from './account-event-detail/AccountEventDetail';
import AccountEventEntryForm from './account-event-detail/AccountEventEntryForm';
import AccountEventParticipants from './account-event-detail/AccountEventParticipants';

export interface ParticipantsAndCountForDisplay {
  participants: EventScheduleParticipantV2[];
  filteredParticipants: EventScheduleParticipantV2[];
  numberOfHiddenParticipants: Count;
}

interface P extends RouteComponentProps {
  base: BaseDto;
  user: User;
  path: Path;
}

const SEARCH_SPACE_LIMIT = 50;

const AccountEventDetailScreen: React.FC<P> = React.memo(props => {
  const { base, user, path } = props;
  const { params, openPath, openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [event, setEvent] = useSafeState<EventV2>(unmountRef);
  const [eventSchedule, setEventSchedule] = useSafeState<EventScheduleV2>(unmountRef);
  const [iconAreaWidth, setIconAreaWidth] = useSafeState<Width>(unmountRef, ZERO);
  const [spaces, setSpaces] = useSafeState<Space[]>(unmountRef, []);
  const [isConfirmation, setIsConfirmation] = useSafeState<boolean>(unmountRef, false);
  const [appliedParticipantsAndCount, setAppliedParticipantsAndCount] =
    useSafeState<ParticipantsAndCountForDisplay>(unmountRef);
  const [interestedParticipantsAndCount, setInterestedParticipantsAndCount] =
    useSafeState<ParticipantsAndCountForDisplay>(unmountRef);

  const isValidMembership = useMemo<boolean>(() => {
    if (!event) return false;
    if (!user) return true;

    const eventMemberships = event.eventMemberships;
    if (!eventMemberships) return false;
    if (!hasLength(eventMemberships)) return true;

    const pics = getBasePICV2(user, base, AuthorityDefCategory.RANK);
    if (!hasLength(pics)) return false;

    const baseAuthorityId = pics[FIRST_INDEX].authority?.authorityId;
    return eventMemberships.some(
      (eventMembership: EventMembership) => eventMembership.authority?.authorityId === baseAuthorityId
    );
  }, [base, event, user]);

  const isWithinCapacity = useMemo<boolean>(() => {
    if (!eventSchedule || !appliedParticipantsAndCount) return true;
    if (eventSchedule.capacity === undefined || eventSchedule.capacity === null) return true;
    return appliedParticipantsAndCount.participants.length < eventSchedule.capacity;
  }, [eventSchedule, appliedParticipantsAndCount]);

  const isAppliable = useMemo<boolean>(
    () => isValidMembership && isWithinCapacity,
    [isValidMembership, isWithinCapacity]
  );

  const isAvailablePoint = useMemo<boolean>(() => {
    if (!eventSchedule) return false;

    const eventPoint = eventSchedule.points;
    if (!user || isZero(eventPoint)) return true;

    const userPoints = user.userPoints;
    if (!userPoints) return false;

    const totalUserPoint = userPoints.reduce((total: Point, userPoint: UserPoint): Point => total + userPoint.point, 0);
    if (eventPoint > totalUserPoint) return false;

    return true;
  }, [eventSchedule, user]);

  const pageTitle = useMemo<Title>(() => {
    if (!eventSchedule) return 'knotPLACE';
    switch (path) {
      case Path.ACCOUNT_EVENT_DEATIL:
        return eventSchedule.eventV2!.name + EMPTY_HALF_WIDTH + eventSchedule.name;
      case Path.ACCOUNT_EVENT_PARTICIPANTS:
        return eventSchedule.eventV2!.name + EMPTY_HALF_WIDTH + eventSchedule.name + 'の参加者';
      case Path.ACCOUNT_EVENT_ENTRY_FORM:
        return isConfirmation ? '申し込み内容の確認' : '申し込みフォーム';
      default:
        return 'knotPLACE';
    }
  }, [eventSchedule, isConfirmation, path]);

  const eventScheduleId = useMemo<EventScheduleIdV2>(() => params[EVENT_SCHEDULE_ID_V2], [params]);

  const goBack = useSafeCallback((): void => {
    switch (path) {
      case Path.ACCOUNT_EVENT_DEATIL:
        openBasePath(user ? Path.ACCOUNT_EVENTS : Path.ACCOUNT_EVENTS_NO_GUARDED);
        break;
      case Path.ACCOUNT_EVENT_PARTICIPANTS:
      case Path.ACCOUNT_EVENT_ENTRY_FORM:
        openPath(embedIdInPath(Path.ACCOUNT_EVENT_DEATIL, PATH_IDS, [base.baseCode, eventScheduleId]));
        break;
    }
  }, [base, eventScheduleId, openBasePath, openPath, path, user]);

  const component = useMemo<React.ReactElement | undefined>(() => {
    if (!event || !eventSchedule || !appliedParticipantsAndCount || !interestedParticipantsAndCount) return;
    switch (path) {
      case Path.ACCOUNT_EVENT_DEATIL:
        return (
          <AccountEventDetail
            base={base}
            user={user!}
            spaces={spaces}
            event={event}
            eventSchedule={eventSchedule}
            isValidMembership={isValidMembership}
            isWithinCapacity={isWithinCapacity}
            isAvailablePoint={isAvailablePoint}
            appliedParticipantsAndCount={appliedParticipantsAndCount}
            interestedParticipantsAndCount={interestedParticipantsAndCount}
            setWidth={setIconAreaWidth}
          />
        );

      case Path.ACCOUNT_EVENT_ENTRY_FORM:
        if (isAppliable)
          return (
            <AccountEventEntryForm
              base={base}
              user={user!}
              event={event}
              eventSchedule={eventSchedule}
              isConfirmation={isConfirmation}
              onClick={setIsConfirmation}
              onChange={goBack}
            />
          );
        break;
      case Path.ACCOUNT_EVENT_PARTICIPANTS:
        return (
          <AccountEventParticipants
            event={event}
            eventSchedule={eventSchedule}
            appliedParticipants={appliedParticipantsAndCount.participants}
            interestedParticipants={interestedParticipantsAndCount.participants}
          />
        );
    }
  }, [
    event,
    eventSchedule,
    appliedParticipantsAndCount,
    interestedParticipantsAndCount,
    path,
    base,
    user,
    spaces,
    isValidMembership,
    isWithinCapacity,
    isAvailablePoint,
    setIconAreaWidth,
    isAppliable,
    isConfirmation,
    setIsConfirmation,
    goBack
  ]);

  const createParticipantsAndCount = useSafeCallback(
    (status: EventScheduleParticipantStatusEnum): ParticipantsAndCountForDisplay => {
      if (!eventSchedule || !eventSchedule.participants)
        return { participants: [], filteredParticipants: [], numberOfHiddenParticipants: ZERO };
      const participants = eventSchedule.participants.filter((participant: EventScheduleParticipantV2) => {
        switch (status) {
          case EventScheduleParticipantStatusEnum.INTERESTED:
            return participant[status] && !participant.isApplied;
          case EventScheduleParticipantStatusEnum.APPLIED:
            return participant[status];
        }
      });

      const filteredParticipants = participants.filter(
        (participant: EventScheduleParticipantV2, index: Index) => index < Math.floor((iconAreaWidth - 8) / 32)
      );

      const numberOfHiddenParticipants = participants.length - filteredParticipants.length;

      return { participants, filteredParticipants, numberOfHiddenParticipants };
    },
    [eventSchedule, iconAreaWidth]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<FetchEventScheduleV2Request>().eventScheduleIdV2(eventScheduleId).build();
    const { eventScheduleV2 } = await commonRequest<FetchEventScheduleV2Request, FetchEventScheduleV2Response>(
      FETCH_EVENT_SCHEDULE_V2,
      request
    );

    if (!eventScheduleV2) {
      setIsLoaderShown(false);
      return;
    }

    const requestForSearchSpace = builder<SearchSpacesRequest>()
      .base(base)
      .limit(SEARCH_SPACE_LIMIT)
      .offset(ZERO)
      .word(eventScheduleV2.location)
      .categories([SpaceCategory.CONFERENCE, SpaceCategory.DEVICE])
      .build();

    const { spaces } = await commonRequest<SearchSpacesRequest, SearchSpacesResponse>(
      SEARCH_SPACES,
      requestForSearchSpace
    );

    setEvent(eventScheduleV2.eventV2!);
    setEventSchedule(eventScheduleV2);
    setSpaces(spaces);
    setIsLoaderShown(false);
  }, [base, commonRequest, eventScheduleId, setEvent, setEventSchedule, setIsLoaderShown, setSpaces]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!eventSchedule) return;
    const interestedParticipantsAndCount = createParticipantsAndCount(EventScheduleParticipantStatusEnum.INTERESTED);
    const appliedParticipantsAndCount = createParticipantsAndCount(EventScheduleParticipantStatusEnum.APPLIED);
    setInterestedParticipantsAndCount(interestedParticipantsAndCount);
    setAppliedParticipantsAndCount(appliedParticipantsAndCount);
  }, [eventSchedule, createParticipantsAndCount, setAppliedParticipantsAndCount, setInterestedParticipantsAndCount]);

  useEffect(() => {
    if (!eventSchedule) return;
    if (!isAppliable && path === Path.ACCOUNT_EVENT_ENTRY_FORM)
      openPath(embedIdInPath(Path.ACCOUNT_EVENT_DEATIL, PATH_IDS, [base.baseCode, eventScheduleId]));
  }, [base, eventSchedule, eventScheduleId, isAppliable, path, openPath]);

  const backButtonLabel = useMemo<Label>(() => {
    switch (path) {
      case Path.ACCOUNT_EVENT_DEATIL:
        return 'イベント一覧へ';
      case Path.ACCOUNT_EVENT_PARTICIPANTS:
      case Path.ACCOUNT_EVENT_ENTRY_FORM:
        return 'イベント詳細へ';
      default:
        return '戻る';
    }
  }, [path]);

  return (
    <Screen
      loadingType='circular'
      loading={isLoaderShown}
      style={styleForFullExpansion}
      className='account-event-detail-screen'
      title={pageTitle}
    >
      <BackButtonWrapper>
        <BackButtonV2 hasBackground label={backButtonLabel} onClick={goBack} />
      </BackButtonWrapper>
      <Container>{component}</Container>
    </Screen>
  );
});

AccountEventDetailScreen.displayName = 'AccountEventDetailScreen';
export default AccountEventDetailScreen;

const Container = styled.div`
  width: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: inherit;
  margin: 0 auto;
  ${media.greaterThan('small')`
    margin: ${themeV2.mixins.v2.spacing * 2}px auto;
    max-width: 672px;
  `}
`;

const BackButtonWrapper = styled.div`
  ${media.lessThan('small')`
    display: none;
  `};
`;
