import {
  Access,
  ACCESS_DIRECTION,
  ACCESS_OPERATION_DATETIME,
  AccessV2,
  ADDRESS_CODE,
  AuthorityDefCategory,
  BASE,
  BASE_BASE_NAME,
  BASE_CODE,
  BaseDto,
  BillingIdV2,
  BillingV2,
  Category,
  CONTRACT,
  CONTRACT_CONTRACT_NAME,
  CONTRACT_CONTRACT_PLAN,
  CONTRACT_CONTRACT_STATUS,
  CONTRACT_DISCOUNT_AMOUNT,
  CONTRACT_END_DATE,
  CONTRACT_INFLOW_SOURCE,
  CONTRACT_INIT_DISCOUNT_AMOUNT,
  CONTRACT_NUMBER_OF_LOCKERS,
  CONTRACT_PAYMENT_METHOD,
  CONTRACT_PAYMENT_TYPE,
  CONTRACT_PLAN_CONTRACT_PLAN_NAME,
  CONTRACT_SERVICE_RETAINER,
  CONTRACT_START_DATE,
  CONTRACT_TAX_EXCLUDED_ADDRESS_PRICE,
  CONTRACT_TAX_EXCLUDED_KEY_ISSUE_PRICE,
  CONTRACT_TAX_EXCLUDED_LOCKER_PRICE,
  CONTRACT_TAX_EXCLUDED_PLAN_PRICE,
  CONTRACT_TAX_INCLUDED_ADDRESS_PRICE,
  CONTRACT_TAX_INCLUDED_KEY_ISSUE_PRICE,
  CONTRACT_TAX_INCLUDED_LOCKER_PRICE,
  CONTRACT_TAX_INCLUDED_PLAN_PRICE,
  CONTRACT_USAGE_CONTRACT_MSEC,
  CONTRACT_USAGE_REMAINING_MSEC,
  CONTRACT_USAGE_USAGE_MSEC,
  ContractPlanV2,
  ContractUsageV2,
  ContractV2,
  EVENT_PARTICIPANT_BASE,
  EVENT_PARTICIPANT_INFLOW_SOURCE,
  EVENT_PARTICIPANT_SCHEDULE,
  EVENT_PARTICIPANT_USER,
  EVENT_SCHEDULE_DUE_DATE,
  EVENT_SCHEDULE_END_AT,
  EVENT_SCHEDULE_START_AT,
  EventIdV2,
  EventParticipant,
  EventSchedule,
  EventScheduleIdV2,
  EventScheduleParticipantId,
  EventScheduleParticipantV2,
  EventScheduleV2,
  IS_CONTRACT_USER,
  ItemV2,
  KEY_CODE,
  LINE_ID,
  LOCKER_CODE,
  SERVICE_RETAINER_CODE,
  Shot,
  SHOT_CONFERENCE_ONE_DAY_USAGE,
  SHOT_CONFERENCE_USAGE_ITEM,
  SHOT_PAYMENT_METHOD,
  SHOT_USAGE_ITEM,
  SHOT_USAGE_OPTION_ITEM,
  SPACE,
  Space,
  SPACE_RESERVATION_ID,
  SPACE_RESERVATIONS_CREATED_AT,
  SPACE_RESERVATIONS_END_AT,
  SPACE_RESERVATIONS_PARTICIPANTS,
  SPACE_RESERVATIONS_PAYMENTS,
  SPACE_RESERVATIONS_PRICE_PER_HOUR,
  SPACE_RESERVATIONS_REMARKS,
  SPACE_RESERVATIONS_START_AT,
  SPACE_RESERVATIONS_TAX_RATE,
  SPACE_SPACE_CODE,
  SPACE_SPACE_NAME,
  SpaceReservation,
  SpaceReservationForFetchSpaceReservation,
  STRIPE_OBJECT_ID,
  STRIPE_OBJECT_ID_TYPE,
  toFullName,
  USER,
  User,
  USER_DATE_OF_BIRTH_V2,
  USER_DIV,
  USER_EMAIL,
  USER_FAMILY_MEMBER_MAPPINGS,
  USER_FAMILY_NAME,
  USER_FIRST_NAME,
  USER_GENDER,
  USER_INFLOW_SOURCE,
  USER_PHONE,
  USER_PREFECTURE,
  USER_STUDENT_DIV,
  USER_USAGE_PURPOSE,
  UserDiv,
  UserFamilyMemberMapping,
  UserInflowSource,
  UserStudentDiv,
  UserUsagePurpose
} from '@atomica.co/irori';
import {
  Account,
  Address,
  Code,
  Count,
  DateStr,
  Description,
  Email,
  Id,
  InflowSource,
  Name,
  NoStr,
  Phone,
  Post,
  Price,
  Quantity,
  Remarks,
  Status,
  Text,
  URL,
  UserId
} from '@atomica.co/types';
import {
  CREATED_AT,
  EMPTY,
  FIRST_INDEX,
  formatTimeFromMsec,
  hasLength,
  isGreaterThanZero,
  Language,
  ONE,
  PREFECTURE_NAMES,
  toDateStr,
  toDatetimeFromRelativeFormat,
  toDateTimeStr,
  UPDATED_AT,
  ZERO
} from '@atomica.co/utils';
import { format } from 'date-fns';
import { FetchDropInsByDatesForAdmin200 } from '../__generated/model';
import {
  ConferenceUsageType,
  toConferenceUsageTypeLabel,
  toUsageOptionPriceLabel,
  USAGE_TYPE_LABELS,
  UsageOption,
  UsageType
} from '../constants/base-const';
import ItemService from '../services/item-service';
import { ACCESS_DIRECTION_LABELS } from '../texts/access-text';
import {
  CONTRACT_INFLOW_SOURCE_LABELS,
  CONTRACT_PAYMENT_METHOD_LABELS,
  CONTRACT_PAYMENT_METHOD_V2_LABELS,
  CONTRACT_PAYMENT_OPTION_V2_LABELS,
  CONTRACT_PAYMENT_TYPE_LABELS,
  CONTRACT_STATUS_LABELS,
  CONTRACT_STATUS_V2_LABELS
} from '../texts/contract-text';
import { PAYMENT_METHOD_LABELS } from '../texts/contract-v2-text';
import { EVENT_INFLOW_SOURCE_LABELS } from '../texts/event-text';
import { DROP_IN_HEADERS } from '../texts/export-text';
import { PAYMENT_METHOD_LABELS as SHOT_PAYMENT_METHOD_LABELS } from '../texts/shot-text';
import { USER_CATEGORY_LABELS, USER_GENDER_LABELS, USER_INFLOW_SOURCE_LABELS } from '../texts/user-text';
import { getTaxLabel } from '../utils/tax-util';
import { getBaseIdentification, getBasePICV2 } from '../utils/user-util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DUMMY: any[] = [
  {
    hoge: EMPTY
  }
];

export interface BillingCSV {
  billingId: BillingIdV2;
  billingNo: NoStr;
  billingName: Name;
  cutoffDate: DateStr;
  billingDate: DateStr;
  paymentDueDate?: DateStr;
  contractorName: Name;
  contractorInfo: Text;
  paymentMethod: Name;
  paymentCount?: Count;
  payableAccount?: Account;
  billingPost: Post;
  billingAddress?: Address;
  bankAccount?: Account;
  remarks?: Remarks;
  stripeInvoiceId?: Id;
  taxIncludedTotalPrice?: Price;
  taxExcludedTotalPrice?: Price;
  billingDetailId: Id;
  itemName: Name;
  unitPrice: Price;
  unitQuantity: Quantity;
  unitName: Name;
  tax: Name;
  price: Price;
  createdAt: DateStr;
  updatedAt: DateStr;
}

export interface ParticipantCSV {
  eventId: EventIdV2;
  eventName: Name;
  eventDescription: Description;
  eventPhotoURL: URL;
  eventCreatedAt: DateStr;
  eventUpdatedAt: DateStr;
  eventScheduleId: EventScheduleIdV2;
  eventScheduleName: Name;
  eventScheduleDescription: Description;
  startAtV2: DateStr;
  endAtV2: DateStr;
  deadlineAtV2?: DateStr;
  eventScheduleCreatedAt: DateStr;
  eventScheduleUpdatedAt: DateStr;
  eventScheduleParticipantId: EventScheduleParticipantId;
  participantName: Name;
  participantDateOfBirth: DateStr;
  participantPhone: Phone;
  participantEmail: Email;
  participantCompanyName: Name;
  participantPostalCode: Code;
  participantAddress: Address;
  participantPhotoURL: URL;
  groupName: Name;
  participantCreatedAt: DateStr;
  participantUpdatedAt: DateStr;
}

export interface UserCSV {
  userId: UserId;
  startDate?: DateStr;
  endDate?: DateStr;
  familyName?: Name;
  firstName?: Name;
  email: Email;
  gender?: Name;
  dateOfBirthV2?: DateStr;
  prefecture?: Name;
  city?: Name;
  userDiv: Name;
  userDivOther?: Name;
  companyName?: Name;
  userStudentDiv?: Name;
  userStudentDivOther?: Name;
  schoolName?: Name;
  schoolDepartmentName?: Name;
  userInflowSource: Name;
  userInflowSourceOther?: Name;
  // userUsagePurpose: Name;
  // userUsagePurposeOther?: Name;
  // userFamilyMemberMappings: Name;
  photoURL?: URL;
  userAuthority: Name;
  userRank: Name;
  userIdentification: Status;
  phone: Phone;
  lineId?: Id;
}

export type DropInData = Record<(typeof DROP_IN_HEADERS)[number]['key'], string>;

export const convertToShotCSV = (shotUsageList: Shot[], base: BaseDto): Shot[] => {
  if (!shotUsageList.length) return DUMMY;

  return shotUsageList.map(shotUsage => {
    const shotUsageToExport = {};

    Object.entries(shotUsage).forEach(([key, value]) => {
      switch (key) {
        case USER:
          setUserProps(value, shotUsageToExport);
          break;

        case BASE:
          shotUsageToExport[key] = base.baseName;
          break;

        case SHOT_USAGE_ITEM:
          shotUsageToExport[key] = (value as ItemV2)?.itemName || USAGE_TYPE_LABELS[shotUsage.usageType];
          break;

        case SHOT_USAGE_OPTION_ITEM:
          shotUsageToExport[key] =
            (value as ItemV2)?.itemName ||
            toUsageOptionPriceLabel(
              shotUsage.baseDto!,
              shotUsage.usageType as UsageType,
              shotUsage.usageOption as UsageOption
            );
          break;

        case SHOT_CONFERENCE_USAGE_ITEM:
          shotUsageToExport[key] =
            (value as ItemV2)?.itemName ||
            toConferenceUsageTypeLabel(shotUsage.baseDto!, shotUsage.conferenceUsageType as ConferenceUsageType);
          break;

        case SHOT_CONFERENCE_ONE_DAY_USAGE:
          shotUsageToExport[key] = value ? '有' : '無';
          break;

        case SHOT_PAYMENT_METHOD:
          shotUsageToExport[key] = SHOT_PAYMENT_METHOD_LABELS[value];
          break;

        case CREATED_AT:
          shotUsageToExport[key] = toDateTimeStr(new Date(value));
          break;

        default:
          shotUsageToExport[key] = value;
          break;
      }
    });

    return shotUsageToExport as Shot;
  });
};

export const convertToContractsCSV = (contracts: ContractV2[], base: BaseDto): ContractV2[] => {
  if (!contracts.length) return DUMMY;

  return contracts.map(contract => {
    const contractToExport = {};
    setContractProps(contract, base, contractToExport);
    return contractToExport as ContractV2;
  });
};

export const convertToContractUsagesCSV = (usages: ContractUsageV2[], base: BaseDto): ContractUsageV2[] => {
  if (!usages.length) return DUMMY;

  return usages.map(usage => {
    const usageToExport = {};

    Object.entries(usage).forEach(([key, value]) => {
      switch (key) {
        case CONTRACT:
          setContractProps(usage.contract!, base, usageToExport);
          break;

        case BASE:
          usageToExport[key] = base.baseName;
          break;

        case CONTRACT_USAGE_CONTRACT_MSEC:
        case CONTRACT_USAGE_USAGE_MSEC:
        case CONTRACT_USAGE_REMAINING_MSEC:
          usageToExport[key] = formatTimeFromMsec(value, Language.JAPANESE);
          break;

        default:
          usageToExport[key] = value;
          break;
      }
    });

    return usageToExport as ContractUsageV2;
  });
};

export const convertToBillingCSV = (billings: BillingV2[]): BillingCSV[] => {
  if (!hasLength(billings)) return DUMMY;
  const result: BillingCSV[] = [];

  for (const billing of billings) {
    const billingDetails = billing.billingDetails;
    if (!billingDetails || !hasLength(billingDetails)) continue;

    for (const billingDetail of billingDetails) {
      result.push({
        billingId: billing.billingId,
        billingNo: billing.billingNo,
        billingName: billing.billingName,
        cutoffDate: toDateTimeStr(new Date(billing.cutoffDate)),
        billingDate: toDateTimeStr(new Date(billing.billingDate)),
        paymentDueDate: billing.paymentDueDate ? toDateTimeStr(new Date(billing.paymentDueDate)) : undefined,
        contractorName: billing.contractorName,
        contractorInfo: billing.contractorInfo,
        paymentMethod: PAYMENT_METHOD_LABELS[billing.paymentMethod],
        paymentCount: billing.paymentCount,
        payableAccount: billing.payableAccount,
        billingPost: billing.billingPost,
        billingAddress: billing.billingAddress,
        bankAccount: billing.bankAccount,
        remarks: billing.remarks,
        stripeInvoiceId: billing.stripeInvoiceId,
        taxIncludedTotalPrice: billing.taxIncludedTotalPrice,
        taxExcludedTotalPrice: billing.taxExcludedTotalPrice,
        billingDetailId: billingDetail.billingDetailId,
        itemName: billingDetail.itemName
          ? billingDetail.itemName
          : billingDetail.item
            ? billingDetail.item.itemName
            : EMPTY,
        unitPrice: billingDetail.unitPrice,
        unitQuantity: billingDetail.unitQuantity,
        unitName: billingDetail.unit ? billingDetail.unit.unitName : EMPTY,
        tax: billingDetail.tax ? getTaxLabel(billingDetail.taxDiv, billingDetail.tax.taxRate) : EMPTY,
        price: billingDetail.unitPrice * billingDetail.unitQuantity,
        createdAt: billingDetail.createdAt ? toDateTimeStr(new Date(billingDetail.createdAt)) : EMPTY,
        updatedAt: billingDetail.updatedAt ? toDateTimeStr(new Date(billingDetail.updatedAt)) : EMPTY
      });
    }
  }
  return result;
};

export const convertToAccessesCSV = (accesses: AccessV2[], base: BaseDto): Access[] => {
  if (!accesses.length) return DUMMY;

  return accesses.map(access => {
    const accessToExport = {};

    Object.entries(access).forEach(([key, value]) => {
      switch (key) {
        case USER:
          setUserProps(value, accessToExport);
          break;

        case BASE_CODE:
          accessToExport[key] = base.baseName;
          break;

        case ACCESS_DIRECTION:
          accessToExport[key] = ACCESS_DIRECTION_LABELS[value];
          break;

        case ACCESS_OPERATION_DATETIME:
          accessToExport[key] = toDateTimeStr(new Date(value));
          break;

        default:
          accessToExport[key] = value;
          break;
      }
    });

    return accessToExport as Access;
  });
};

export const convertToParticipantsCSV = (participants: EventParticipant[], base: BaseDto): EventParticipant[] => {
  if (!participants.length) return DUMMY;

  return participants.map(participant => {
    const participantToExport = {};

    Object.entries(participant).forEach(([key, value]) => {
      switch (key) {
        case EVENT_PARTICIPANT_USER:
          setUserProps(value, participantToExport);
          break;

        case EVENT_PARTICIPANT_SCHEDULE:
          setEventScheduleProps(value, participantToExport);
          break;

        case EVENT_PARTICIPANT_INFLOW_SOURCE:
          participantToExport[key] = EVENT_INFLOW_SOURCE_LABELS[value];
          break;

        case EVENT_PARTICIPANT_BASE:
          participantToExport[key] = base.baseName;
          break;

        case CREATED_AT:
          participantToExport[key] = toDateTimeStr(new Date(value));
          break;

        default:
          participantToExport[key] = value;
          break;
      }
    });

    return participantToExport as EventParticipant;
  });
};

export const convertToParticipantV2CSV = (
  eventSchedule: EventScheduleV2,
  participants: EventScheduleParticipantV2[],
  groupName?: Name
): ParticipantCSV[] => {
  if (!hasLength(participants)) return DUMMY;
  const result: ParticipantCSV[] = [];

  const { eventV2, startAtV2, deadlineBeforeDate, deadlineDateUnit, deadlineTime, endAtV2 } = eventSchedule;

  for (const participant of participants) {
    result.push({
      eventId: eventV2!.eventId,
      eventName: eventV2!.name,
      eventDescription: eventV2!.description,
      eventPhotoURL: eventV2!.photoURL,
      eventCreatedAt: eventV2?.createdAt ? toDateTimeStr(new Date(eventV2.createdAt)) : EMPTY,
      eventUpdatedAt: eventV2?.createdAt ? toDateTimeStr(new Date(eventV2.createdAt)) : EMPTY,
      eventScheduleId: eventSchedule.eventScheduleId,
      eventScheduleName: eventSchedule.name,
      eventScheduleDescription: eventSchedule.description,
      startAtV2: toDateTimeStr(new Date(eventSchedule.startAtV2)),
      endAtV2: toDateTimeStr(new Date(eventSchedule.endAtV2)),
      deadlineAtV2: toDateTimeStr(
        new Date(
          toDatetimeFromRelativeFormat(startAtV2, deadlineBeforeDate ?? null, deadlineDateUnit ?? null, deadlineTime) ||
            endAtV2
        )
      ),
      eventScheduleCreatedAt: eventSchedule.createdAt ? toDateTimeStr(new Date(eventSchedule.createdAt)) : EMPTY,
      eventScheduleUpdatedAt: eventSchedule.createdAt ? toDateTimeStr(new Date(eventSchedule.createdAt)) : EMPTY,
      eventScheduleParticipantId: participant.eventScheduleParticipantId,
      participantName: toFullName(participant.user),
      participantDateOfBirth: participant.user?.dateOfBirthV2 ? participant.user.dateOfBirthV2 : EMPTY,
      participantPhone: participant.user?.phone || EMPTY,
      participantEmail: participant.user!.email,
      participantCompanyName: participant.user?.companyName ? participant.user.companyName : EMPTY,
      participantPostalCode: participant.user?.postalCode ? participant.user.postalCode : EMPTY,
      participantAddress: participant.user?.address ? participant.user.address : EMPTY,
      participantPhotoURL: participant.user?.photoURL ? participant.user.photoURL : EMPTY,
      groupName: participant.eventGroup ? participant.eventGroup.name : groupName ? groupName : EMPTY,
      participantUpdatedAt: participant.createdAt ? toDateTimeStr(new Date(participant.createdAt)) : EMPTY,
      participantCreatedAt: participant.updatedAt ? toDateTimeStr(new Date(participant.updatedAt)) : EMPTY
    });
  }
  return result;
};

export const convertToSpaceReservationsCSV = (
  spaceReservationList: SpaceReservationForFetchSpaceReservation[],
  isPaymentIncluded: boolean
): SpaceReservation[] => {
  if (!spaceReservationList.length) return DUMMY;

  return spaceReservationList.map(spaceReservation => {
    const spaceReservationToExport = {};

    Object.entries(spaceReservation).forEach(([key, value]) => {
      switch (key) {
        case SPACE_RESERVATION_ID:
        case SPACE_RESERVATIONS_PRICE_PER_HOUR:
        case SPACE_RESERVATIONS_TAX_RATE:
        case SPACE_RESERVATIONS_REMARKS:
          spaceReservationToExport[key] = value;
          break;

        case SPACE:
          setSpaceProps(value, spaceReservationToExport);
          break;

        case SPACE_RESERVATIONS_START_AT:
        case SPACE_RESERVATIONS_END_AT:
        case SPACE_RESERVATIONS_CREATED_AT:
          spaceReservationToExport[key] = toDateTimeStr(new Date(value));
          break;

        case SPACE_RESERVATIONS_PARTICIPANTS: {
          const user = spaceReservation.createdUser || spaceReservation.participants?.[FIRST_INDEX]?.user;
          if (user) {
            spaceReservationToExport[USER_EMAIL] = user.email;
            spaceReservationToExport[USER_FAMILY_NAME] = user.familyName;
            spaceReservationToExport[USER_FIRST_NAME] = user.firstName;
          }
          break;
        }
        case SPACE_RESERVATIONS_PAYMENTS:
          if (isPaymentIncluded) {
            spaceReservationToExport[STRIPE_OBJECT_ID] = spaceReservation.payments![FIRST_INDEX].stripeObjectId;
            spaceReservationToExport[STRIPE_OBJECT_ID_TYPE] =
              spaceReservation.payments![FIRST_INDEX].stripeObjectIdType;
          }
          break;

        case IS_CONTRACT_USER:
          if (!isPaymentIncluded)
            spaceReservationToExport[IS_CONTRACT_USER] = spaceReservation.isContractUser ? '有' : '無';
          break;

        default:
          break;
      }
    });

    return spaceReservationToExport as SpaceReservation;
  });
};
// eslint-disable-next-line @typescript-eslint/ban-types
const setUserProps = (user: User, objectToExport: Object): void => {
  Object.entries(user).forEach(([key, value]) => {
    switch (key) {
      case CREATED_AT:
      case UPDATED_AT:
        break;

      case LINE_ID:
        objectToExport[key] = value ?? user.mappings?.[ZERO]?.externalId ?? EMPTY;
        break;

      case USER_GENDER:
        objectToExport[key] = value ? USER_GENDER_LABELS[value] : EMPTY;
        break;

      case USER_DATE_OF_BIRTH_V2:
        objectToExport[key] = toDateStr(new Date(value));
        break;

      case USER_PREFECTURE:
        objectToExport[key] = value ? PREFECTURE_NAMES[value] : EMPTY;
        break;

      case USER_PHONE:
        objectToExport[key] = `'${value}`;
        break;

      case USER_DIV:
        objectToExport[key] = (value as UserDiv)?.userDivName || USER_CATEGORY_LABELS[user.category as Category];
        break;

      case USER_STUDENT_DIV:
        objectToExport[key] = (value as UserStudentDiv)?.userStudentDivName;
        break;

      case USER_INFLOW_SOURCE:
        objectToExport[key] =
          (value as UserInflowSource)?.userInflowSourceName ||
          USER_INFLOW_SOURCE_LABELS[user.inflowSource as InflowSource];
        break;

      case USER_USAGE_PURPOSE:
        objectToExport[key] = (value as UserUsagePurpose)?.userUsagePurposeName;
        break;

      case USER_FAMILY_MEMBER_MAPPINGS:
        objectToExport[key] = getFamilyMemberNamesStr(value as UserFamilyMemberMapping[]);
        break;

      default:
        objectToExport[key] = value;
        break;
    }
  });
};

const getFamilyMemberNameStr = (mapping: UserFamilyMemberMapping): Name => {
  if (mapping.userFamilyMember?.userFamilyMemberDef?.hasOther) {
    return `その他(${mapping.other})`;
  }
  return mapping.userFamilyMember?.userFamilyMemberName ?? EMPTY;
};

const getFamilyMemberNamesStr = (mappings?: UserFamilyMemberMapping[]): Name => {
  if (!hasLength(mappings)) return EMPTY;
  return mappings!
    .sort((a, b) => a.userFamilyMember!.order - b.userFamilyMember!.order)
    .reduce((prev, current) => {
      return prev ? `${prev}, ${getFamilyMemberNameStr(current)}` : getFamilyMemberNameStr(current);
    }, EMPTY);
};

const getAuthorityName = (user: User, base: BaseDto, category: AuthorityDefCategory): Name => {
  const authorities = getBasePICV2(user, base, category);
  return hasLength(authorities) && authorities[FIRST_INDEX].authority
    ? authorities[FIRST_INDEX].authority.authorityName
    : 'なし';
};

const getIdentificationStatus = (user: User, base: BaseDto): Status => {
  const baseIdentification = getBaseIdentification(user, base);
  return !baseIdentification ? '未確認' : '確認済み';
};

export const convertToUserCSV = (users: User[], base: BaseDto): UserCSV[] => {
  if (!hasLength(users)) return DUMMY;
  const result: UserCSV[] = [];

  for (const user of users) {
    result.push({
      userId: user.userId,
      startDate: user.startDate ? toDateTimeStr(user.startDate) : EMPTY,
      endDate: user.endDate ? toDateTimeStr(user.endDate) : EMPTY,
      familyName: user.familyName,
      firstName: user.firstName,
      email: user.email,
      gender: user.gender && USER_GENDER_LABELS[user.gender],
      dateOfBirthV2: user.dateOfBirthV2,
      prefecture: user.prefecture ? PREFECTURE_NAMES[user.prefecture] : EMPTY,
      city: user.city,
      userDiv: user.userDiv?.userDivName ?? EMPTY,
      userDivOther: user.userDivOther,
      companyName: user.companyName,
      userStudentDiv: user.userStudentDiv?.userStudentDivName ?? EMPTY,
      userStudentDivOther: user.userStudentDivOther,
      schoolName: user.schoolName,
      schoolDepartmentName: user.schoolDepartmentName,
      userInflowSource: user.userInflowSource?.userInflowSourceName ?? EMPTY,
      userInflowSourceOther: user.userInflowSourceOther,
      // userUsagePurpose: user.userUsagePurpose?.userUsagePurposeName ?? EMPTY,
      // userUsagePurposeOther: user.userUsagePurposeOther,
      // userFamilyMemberMappings: getFamilyMemberNamesStr(user.userFamilyMemberMappings),
      photoURL: user.photoURL,
      userAuthority: getAuthorityName(user, base, AuthorityDefCategory.USER_AUTHORITY),
      userRank: getAuthorityName(user, base, AuthorityDefCategory.RANK),
      userIdentification: getIdentificationStatus(user, base),
      phone: user.phone ?? EMPTY,
      lineId: user.lineId ?? user.mappings?.[ZERO]?.externalId ?? EMPTY
    });
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const setContractProps = (contract: ContractV2, base: BaseDto, objectToExport: Object): void => {
  Object.entries(contract).forEach(([key, value]) => {
    const plan = contract.contractPlan;
    const keyDetail = contract.contractDetails?.find(detail => detail?.item?.itemCode === KEY_CODE);
    const lockerDetail = contract.contractDetails?.find(detail => detail?.item?.itemCode === LOCKER_CODE);
    const addressDetail = contract.contractDetails?.find(detail => detail?.item?.itemCode === ADDRESS_CODE);
    const serviceRetainerDetail = contract.contractDetails?.find(
      detail => detail?.item?.itemCode === SERVICE_RETAINER_CODE
    );

    switch (key) {
      case BASE:
        objectToExport[key] = base.baseName;
        break;

      case CONTRACT_CONTRACT_PLAN:
        objectToExport[key] = value ? setContractPlanProps(value, objectToExport) : EMPTY;
        break;

      case CONTRACT_CONTRACT_STATUS:
        objectToExport[key] = {
          ...CONTRACT_STATUS_LABELS,
          ...CONTRACT_STATUS_V2_LABELS
        }[value];
        break;

      case CONTRACT_START_DATE:
      case CONTRACT_END_DATE:
        objectToExport[key] = value ? toDateTimeStr(new Date(value)) : EMPTY;
        break;

      case CONTRACT_PAYMENT_METHOD:
        objectToExport[key] = {
          ...CONTRACT_PAYMENT_METHOD_LABELS,
          ...CONTRACT_PAYMENT_METHOD_V2_LABELS
        }[value];
        break;

      case CREATED_AT:
        objectToExport[key] = toDateTimeStr(new Date(value));
        break;

      case CONTRACT_PAYMENT_TYPE:
        objectToExport[key] = {
          ...CONTRACT_PAYMENT_TYPE_LABELS,
          ...CONTRACT_PAYMENT_OPTION_V2_LABELS
        }[value];
        break;

      case CONTRACT_INFLOW_SOURCE:
        objectToExport[key] = CONTRACT_INFLOW_SOURCE_LABELS[value];
        break;

      default: {
        objectToExport[key] = value;
        objectToExport[CONTRACT_CONTRACT_NAME] = contract.entityName || contract.contractorName;
        objectToExport[CONTRACT_NUMBER_OF_LOCKERS] = lockerDetail?.unitQuantity || ZERO;
        objectToExport[CONTRACT_TAX_EXCLUDED_PLAN_PRICE] = plan ? ItemService.calcTaxExcludedPrice(plan?.item) : ZERO;
        objectToExport[CONTRACT_TAX_INCLUDED_PLAN_PRICE] = plan ? ItemService.calcTaxIncludedPrice(plan?.item) : ZERO;
        objectToExport[CONTRACT_TAX_EXCLUDED_KEY_ISSUE_PRICE] = keyDetail?.item
          ? Math.round(ItemService.calcTaxExcludedPrice(keyDetail.item) * keyDetail.unitQuantity)
          : ZERO;
        objectToExport[CONTRACT_TAX_INCLUDED_KEY_ISSUE_PRICE] = keyDetail?.item
          ? Math.round(ItemService.calcTaxIncludedPrice(keyDetail.item) * keyDetail.unitQuantity)
          : ZERO;
        objectToExport[CONTRACT_TAX_EXCLUDED_LOCKER_PRICE] = lockerDetail?.item
          ? Math.round(ItemService.calcTaxExcludedPrice(lockerDetail.item) * lockerDetail.unitQuantity)
          : ZERO;
        objectToExport[CONTRACT_TAX_INCLUDED_LOCKER_PRICE] = lockerDetail?.item
          ? Math.round(ItemService.calcTaxIncludedPrice(lockerDetail.item) * lockerDetail.unitQuantity)
          : ZERO;
        objectToExport[CONTRACT_TAX_EXCLUDED_ADDRESS_PRICE] = addressDetail?.item
          ? Math.round(ItemService.calcTaxExcludedPrice(addressDetail.item) * addressDetail.unitQuantity)
          : ZERO;
        objectToExport[CONTRACT_TAX_INCLUDED_ADDRESS_PRICE] = addressDetail?.item
          ? Math.round(ItemService.calcTaxIncludedPrice(addressDetail.item) * addressDetail.unitQuantity)
          : ZERO;
        objectToExport[CONTRACT_SERVICE_RETAINER] = serviceRetainerDetail?.item
          ? Math.round(
              ItemService.calcTaxIncludedPrice(serviceRetainerDetail.item) * serviceRetainerDetail.unitQuantity
            )
          : ZERO;
        objectToExport[CONTRACT_INIT_DISCOUNT_AMOUNT] = contract.contractDetails?.reduce((prev, current) => {
          if (isGreaterThanZero(current.unitPrice)) return prev;
          const unitpPrice = ItemService.calcTaxIncludedPrice(current.item);
          return prev + Math.round(unitpPrice * current.unitQuantity);
        }, ZERO);
        objectToExport[CONTRACT_DISCOUNT_AMOUNT] = contract.contractDetails?.reduce((prev, current) => {
          if (isGreaterThanZero(current.unitPrice) || current.recurrenceCount === ONE) return prev;
          const unitpPrice = ItemService.calcTaxIncludedPrice(current.item);
          return prev + Math.round(unitpPrice * current.unitQuantity);
        }, ZERO);
        break;
      }
    }
  });
};
// eslint-disable-next-line @typescript-eslint/ban-types
const setEventScheduleProps = (schedule: EventSchedule, objectToExport: Object): void => {
  Object.entries(schedule).forEach(([key, value]) => {
    switch (key) {
      case CREATED_AT:
      case UPDATED_AT:
        break;

      case EVENT_SCHEDULE_DUE_DATE:
        objectToExport[key] = toDateTimeStr(new Date(value));
        break;

      case EVENT_SCHEDULE_START_AT:
        objectToExport[key] = toDateTimeStr(new Date(value));
        break;

      case EVENT_SCHEDULE_END_AT:
        objectToExport[key] = toDateTimeStr(new Date(value));
        break;

      default:
        objectToExport[key] = value;
        break;
    }
  });
};
// eslint-disable-next-line @typescript-eslint/ban-types
const setSpaceProps = (space: Space, objectToExport: Object): void => {
  Object.entries(space).forEach(([key, value]) => {
    switch (key) {
      case SPACE_SPACE_CODE:
      case SPACE_SPACE_NAME:
        objectToExport[key] = value;
        break;

      case BASE:
        setBaseProps(value, objectToExport);
        break;

      default:
        break;
    }
  });
};
// eslint-disable-next-line @typescript-eslint/ban-types
const setBaseProps = (base: BaseDto, objectToExport: Object): void => {
  Object.entries(base).forEach(([key, value]) => {
    switch (key) {
      case BASE_CODE:
      case BASE_BASE_NAME:
        objectToExport[key] = value;
        break;

      default:
        break;
    }
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
const setContractPlanProps = (plan: ContractPlanV2, objectToExport: Object): void => {
  Object.entries(plan).forEach(([key, value]) => {
    switch (key) {
      case CONTRACT_PLAN_CONTRACT_PLAN_NAME:
        objectToExport[key] = value;
        break;

      default:
        break;
    }
  });
};

export const convertToDropInData = (data: FetchDropInsByDatesForAdmin200): DropInData[] => {
  return data.dropIns.flatMap(dropIn => {
    return dropIn.payments.map(({ stripeObjectId, billingAmount }) => ({
      dropInId: dropIn.dropInId,
      startAt: format(dropIn.startAt, 'yyyy/MM/dd HH:mm:ss'),
      endAt: format(dropIn.endAt, 'yyyy/MM/dd HH:mm:ss'),
      paidAt: format(dropIn.paidAt, 'yyyy/MM/dd HH:mm:ss'),
      spaceName: dropIn.spaceName,
      planName: dropIn.planName,
      userName: dropIn.userName,
      email: dropIn.email,
      stripeObjectId,
      billingAmount: billingAmount === undefined ? 'なし' : billingAmount.toLocaleString()
    }));
  });
};
